import React from 'react';

/* Utils */
import { darkWhite, darkCoral } from '../../../utils/colors';

const FacebookIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="60px"
    height="60px"
    viewBox="0 0 60 60"
    enableBackground="new 0 0 60 60"
    xmlSpace="preserve"
  >
    <circle cx="30" cy="30" r="30" fill={darkCoral} />
    <path
      fill={darkWhite}
      d="M24.739,49.452l7.789,0.022c0,0,0.033-10.752,0.061-19.475l5.778,0.017l0.735-7.788l-6.191-0.018
  l0.009-3.115c0.004-1.518,1.019-1.868,1.728-1.865c0.711,0.001,4.371,0.012,4.371,0.012l0.02-6.674l-6.018-0.042
  c-6.681-0.02-8.212,4.953-8.222,8.141l-0.01,3.521l-3.862-0.011L20.9,29.967l3.895,0.011
  C24.769,38.805,24.739,49.452,24.739,49.452z"
    />
  </svg>
);

export default FacebookIcon;
