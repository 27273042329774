import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

/* Hooks */
import useWrapperClasses from '../hooks/useWrapperClasses';

const ShapesBg = ({ shouldTriggerEntryAnimation, currentPath }) => {
  const firstLeftShapeRef = useRef(null);
  const secondLeftShapeRef = useRef(null);
  const firstRightShapeRef = useRef(null);
  const secondRightShapeRef = useRef(null);
  const entryAnimationRef = useRef(null);
  const navigationAnimationRef = useRef(null);

  const [currentPathState, setCurrentPathState] = useState(currentPath);

  const [wrapperClasses, dispatch] = useWrapperClasses(['shapesBg']);

  useEffect(() => {
    if (!shouldTriggerEntryAnimation) return;

    dispatch({ type: 'visible' });

    entryAnimationRef.current = new TimelineMax();
    entryAnimationRef.current
      .fromTo(
        firstLeftShapeRef.current,
        0.55,
        {
          autoAlpha: 0,
          x: '-200%',
          y: '125%',
          rotation: -20,
        },
        {
          autoAlpha: 1,
          x: '-90%',
          y: '52%',
          ease: Power1.easeOut,
        },
        '+=0',
      )
      .fromTo(
        secondLeftShapeRef.current,
        0.55,
        {
          autoAlpha: 0,
          x: '-200%',
          y: '240%',
          rotation: -20,
        },
        {
          autoAlpha: 1,
          x: '-91.5%',
          y: '145%',
          ease: Power2.easeOut,
        },
        '-=0.4',
      )
      .fromTo(
        firstRightShapeRef.current,
        0.55,
        {
          autoAlpha: 0,
          x: '200%',
          y: '-190%',
          rotation: -20,
        },
        {
          autoAlpha: 1,
          x: '91%',
          y: '-110%',
          ease: Power1.easeOut,
        },
        '-=0.7',
      )
      .fromTo(
        secondRightShapeRef.current,
        0.55,
        {
          autoAlpha: 0,
          x: '192%',
          y: '-120%',
          rotation: -20,
        },
        {
          autoAlpha: 1,
          x: '90%',
          y: '-7%',
          ease: Power2.easeOut,
        },
        '-=0.45',
      );
  }, [shouldTriggerEntryAnimation]);

  useEffect(() => {
    if (currentPath !== currentPathState) {
      navigationAnimationRef.current = new TimelineMax({
        onComplete: () => setCurrentPathState(currentPath),
      });
      navigationAnimationRef.current
        .staggerTo(
          [firstLeftShapeRef.current, secondLeftShapeRef.current],
          0.35,
          {
            x: -23,
            y: 12,
            ease: Power2.easeOut,
          },
          0.07,
          '+=0',
        )
        .staggerTo(
          [firstRightShapeRef.current, secondRightShapeRef.current],
          0.35,
          {
            x: 23,
            y: -15,
            ease: Power2.easeOut,
          },
          0.07,
          '-=0.4',
        )
        .staggerTo(
          [
            firstLeftShapeRef.current,
            secondLeftShapeRef.current,
            firstRightShapeRef.current,
            secondRightShapeRef.current,
          ],
          0.35,
          {
            x: 0,
            y: 0,
            ease: Power3.easeIn,
          },
          0.05,
          '-=0.2',
        );
    }
  }, [currentPath, currentPathState]);

  return (
    <div className={wrapperClasses.join(' ')}>
      <div className="shapesBg-firstLeftShape" ref={firstLeftShapeRef} />
      <div className="shapesBg-secondLeftShape" ref={secondLeftShapeRef} />
      <div className="shapesBg-firstRightShape" ref={firstRightShapeRef} />
      <div className="shapesBg-secondRightShape" ref={secondRightShapeRef} />
    </div>
  );
};

ShapesBg.propTypes = {
  shouldTriggerEntryAnimation: PropTypes.bool,
  currentPath: PropTypes.string,
};

export default ShapesBg;
