import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

/* Views */
import Layout from './layout';

/* Components */
import IntroShapes from './IntroShapesBg';
import About from './pages/about';
import Projects from './pages/projects';
import Skills from './pages/skills';
import PageError from './pages/pageError';
import Footer from './layout';

/* Hooks */
import useWindowMeasures from './hooks/useWindowMeasures';

/* Utils */
import { xlWidth } from '../utils/measures';

const App = () => {
  const { width } = useWindowMeasures();

  const [isIntroShapesDisplayed, setIsIntroShapesDisplayed] = useState(
    () => width > xlWidth,
  );

  return (
    <Router>
      <Layout shouldTriggerEntryAnimation={!isIntroShapesDisplayed}>
        {isIntroShapesDisplayed ? (
          <IntroShapes
            onEndIntroAnimation={() => setIsIntroShapesDisplayed(false)}
          />
        ) : (
          <Switch>
            <Route exact path="/" component={About} />
            <Route exact path="/skills" component={Skills} />
            <Route exact path="/projects" component={Projects} />
            <Route component={PageError} />
          </Switch>
        )}
      </Layout>
    </Router>
  );
};

export default App;
