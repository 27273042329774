import React from 'react';
import PropTypes from 'prop-types';

const getButtonClasses = ({ type, isActive, withActiveBg }) => {
  const classes = [type];
  if (isActive) classes.push('active');
  if (withActiveBg) classes.push('activeBg');

  return classes;
};

const Button = ({ type, isActive, withActiveBg, onClick, children }) => (
  <button
    className={getButtonClasses({ type, isActive, withActiveBg }).join(' ')}
    onClick={onClick}
  >
    {children}
  </button>
);

Button.propTypes = {
  type: PropTypes.string,
  isActive: PropTypes.bool,
  withActiveBg: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

Button.defaultProps = {
  type: 'primary',
  isActive: false,
  withActiveBg: false,
  onClick: () => {},
};

export default Button;
