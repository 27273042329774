import React, { useRef, useEffect } from 'react';

/* Utils */
import {
  white,
  grey,
  smoothGrey,
  orange,
  smoothBlack,
  darkGrey,
  lightGrey,
} from '../../../utils/colors';

const HighlightImage = ({ isAnimated }) => {
  const svgRef = useRef(null);
  const circleBgRef = useRef(null);
  const wheelRef = useRef(null);
  const editorRef = useRef(null);
  const pageRef = useRef(null);
  const jsRef = useRef(null);
  const fiveRef = useRef(null);
  const phoneRef = useRef(null);
  const rwdRef = useRef(null);
  const animation = useRef(null);
  const wheelAnimation = useRef(null);

  useEffect(() => {
    if (!isAnimated) return;

    animation.current = new TimelineMax({
      onComplete: () => {
        wheelAnimation.current = TweenMax.to(wheelRef.current, 16, {
          repeat: -1,
          repeatDelay: 0,
          delay: 0.5,
          rotation: 360,
          transformOrigin: '50% 50%',
          ease: Linear.easeNone,
        });
      },
    });

    animation.current
      .timeScale(1.3)
      .set(svgRef.current, { autoAlpha: 1 })
      .from(
        circleBgRef.current,
        0.5,
        {
          autoAlpha: 0,
          scale: 0.75,
          transformOrigin: '50% 50%',
          ease: Power2.easeOut,
        },
        '+=0',
      )
      .from(
        editorRef.current,
        0.6,
        { autoAlpha: 0, x: 20, ease: Power2.easeOut },
        '-=.3',
      )
      .from(
        pageRef.current,
        0.6,
        { autoAlpha: 0, x: -20, ease: Power2.easeOut },
        '-=.45',
      )
      .from(
        wheelRef.current,
        0.7,
        {
          autoAlpha: 0,
          y: 50,
          x: 40,
          rotation: 100,
          transformOrigin: '50% 50%',
          ease: Power3.easeOut,
        },
        '-=.45',
      )
      .from(
        jsRef.current,
        0.5,
        {
          autoAlpha: 0,
          scale: 0.5,
          transformOrigin: '50% 50%',
          ease: Back.easeInOut,
        },
        '-=.7',
      )
      .from(
        phoneRef.current,
        0.6,
        { autoAlpha: 0, y: 20, ease: Power3.easeOut },
        '-=.5',
      )
      .from(
        rwdRef.current,
        0.5,
        {
          autoAlpha: 0,
          scale: 0.5,
          transformOrigin: '80% 95%',
          ease: Power2.easeOut,
        },
        '-=.6',
      )
      .from(
        fiveRef.current,
        0.5,
        {
          autoAlpha: 0,
          scale: 0.5,
          transformOrigin: '5% 5%',
          ease: Power2.easeOut,
        },
        '-=.5',
      );

    return () => {
      if (animation.current) {
        animation.current.kill();
      }
      if (wheelAnimation.current) {
        wheelAnimation.current.kill();
      }
    };
  }, [isAnimated]);

  return (
    <div className="highlight-image">
      <svg
        ref={svgRef}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="420px"
        height="336px"
        viewBox="0 0 420 336"
        enableBackground="new 0 0 420 336"
        xmlSpace="preserve"
      >
        <path
          ref={circleBgRef}
          fill={grey}
          d="M377.5,168c0,92.78-75.222,168-168.001,168C116.72,336,41.5,260.778,41.5,168c0-92.78,75.22-168,167.999-168
      C302.279,0,377.5,75.22,377.5,168z"
        />
        <g ref={wheelRef}>
          <path
            fill={smoothBlack}
            d="M173.196,78.785c0.319-1.408-0.527-2.82-1.923-3.197l-8.109-2.203c-1.188-0.322-2.004-1.405-1.984-2.634
            c0.035-2.167-0.067-4.333-0.3-6.485c-0.132-1.203,0.561-2.34,1.688-2.785l7.744-3.058c1.347-0.532,2.033-2.027,1.558-3.393
            l-3.854-11.077c-0.475-1.363-1.94-2.112-3.324-1.693l-7.636,2.309c-1.208,0.365-2.499-0.159-3.125-1.255
            c-0.485-0.847-0.99-1.685-1.525-2.508c-0.656-1.008-0.584-2.325,0.2-3.238l5.25-6.115c0.942-1.097,0.852-2.742-0.208-3.727
            l-8.577-7.994c-1.059-0.985-2.706-0.96-3.734,0.057l-5.462,5.402c-0.881,0.874-2.242,1.023-3.301,0.377
            c-1.516-0.924-3.075-1.758-4.655-2.512c-1.102-0.527-1.715-1.718-1.493-2.917l1.352-7.36c0.261-1.42-0.646-2.795-2.055-3.115
            l-11.444-2.596c-1.407-0.319-2.818,0.528-3.196,1.923l-1.89,6.96c-0.323,1.197-1.422,2.013-2.66,1.986
            c-2.398-0.055-4.8,0.058-7.184,0.334c-1.211,0.141-2.365-0.547-2.813-1.685l-2.619-6.633c-0.531-1.344-2.029-2.03-3.395-1.556
            l-11.078,3.855c-1.362,0.476-2.11,1.941-1.693,3.326l2.015,6.657c0.356,1.176-0.13,2.446-1.187,3.08
            c-0.999,0.601-1.98,1.237-2.948,1.91c-1.003,0.701-2.354,0.63-3.282-0.167l-5.271-4.521c-1.097-0.941-2.739-0.85-3.728,0.208
            l-7.994,8.58c-0.984,1.058-0.96,2.706,0.056,3.732l4.88,4.938c0.862,0.873,1.024,2.22,0.394,3.274
            c-0.97,1.621-1.843,3.283-2.618,4.979c-0.516,1.121-1.725,1.753-2.938,1.529l-6.839-1.266c-1.422-0.262-2.796,0.643-3.118,2.052
            l-2.602,11.435c-0.32,1.409,0.528,2.819,1.924,3.198l6.904,1.872c1.178,0.319,1.991,1.388,1.986,2.607
            c-0.009,2.393,0.145,4.787,0.464,7.159c0.166,1.229-0.521,2.412-1.676,2.865l-6.728,2.656c-1.345,0.53-2.03,2.027-1.556,3.393
            l3.853,11.076c0.476,1.365,1.941,2.111,3.324,1.695l7.214-2.184c1.156-0.349,2.404,0.114,3.051,1.137
            c0.563,0.89,1.155,1.767,1.778,2.628c0.728,1.009,0.671,2.383-0.137,3.327l-4.933,5.75c-0.941,1.097-0.851,2.74,0.207,3.726
            l8.58,7.996c1.058,0.983,2.703,0.961,3.731-0.055l5.648-5.584c0.858-0.849,2.18-1.026,3.226-0.42
            c1.47,0.845,2.971,1.613,4.497,2.303c1.127,0.51,1.758,1.722,1.534,2.937l-1.461,7.9c-0.264,1.422,0.642,2.797,2.052,3.118
            l11.434,2.602c1.407,0.321,2.82-0.528,3.198-1.922l2.186-8.065c0.316-1.167,1.372-1.981,2.582-1.986
            c2.157-0.008,4.316-0.146,6.458-0.419c1.219-0.157,2.387,0.535,2.84,1.678l3.093,7.837c0.531,1.345,2.027,2.032,3.392,1.557
            l11.076-3.854c1.365-0.473,2.113-1.941,1.694-3.325l-2.476-8.187c-0.359-1.187,0.139-2.453,1.196-3.094
            c0.738-0.448,1.469-0.914,2.191-1.402c1.014-0.685,2.355-0.625,3.283,0.173l6.598,5.661c1.095,0.941,2.74,0.851,3.728-0.208
            l7.993-8.578c0.984-1.057,0.961-2.705-0.056-3.733l-6.106-6.173c-0.862-0.873-1.021-2.215-0.396-3.271
            c0.799-1.354,1.526-2.738,2.193-4.145c0.524-1.106,1.72-1.727,2.924-1.507l8.404,1.545c1.421,0.26,2.795-0.646,3.116-2.055
            L173.196,78.785z M131.655,83.558c-0.229,0.457-0.432,0.929-0.646,1.394c-3.43,5.105-7.909,8.861-13.776,10.918
            c-4.58,1.602-9.223,1.302-13.9,0.767c-2.042-0.873-4.155-1.604-6.103-2.64c-10.787-5.729-15.972-19.419-11.562-30.325
            c0.266-0.858,0.527-1.713,0.792-2.571c4.57-8.543,11.742-13.235,21.297-14.405c4.675,0.157,9.203,0.817,13.413,3.111
            C133.308,56.423,137.885,71.058,131.655,83.558z"
          />
          <path
            fill="none"
            stroke={white}
            strokeWidth="6"
            strokeMiterlimit="10"
            d="M76.699,55.942
            c6.481-12.117,16.655-18.772,30.209-20.434c6.628,0.226,13.054,1.161,19.023,4.415c17.213,9.383,23.704,30.14,14.87,47.87
            c-0.323,0.65-0.612,1.321-0.916,1.98c-4.867,7.239-11.215,12.568-19.54,15.484c-6.497,2.273-13.083,1.849-19.717,1.089
            c-2.896-1.239-5.889-2.276-8.656-3.747C76.676,94.475,69.32,75.057,75.576,59.587C75.952,58.373,76.326,57.159,76.699,55.942z"
          />
        </g>
        <g ref={editorRef}>
          <rect
            x="60"
            y="69.26"
            fill={smoothGrey}
            width="307.167"
            height="4.462"
          />
          <rect
            x="60"
            y="73.722"
            fill="#323232"
            width="307.167"
            height="166.795"
          />
          <rect
            x="191.029"
            y="91.637"
            fill={orange}
            width="28.302"
            height="2.173"
          />
          <rect
            x="95.234"
            y="91.003"
            fill={white}
            width="14.151"
            height="2.173"
          />
          <rect
            x="95.234"
            y="123.313"
            fill={white}
            width="14.151"
            height="2.173"
          />
          <rect
            x="105.838"
            y="131.423"
            fill={white}
            width="14.151"
            height="2.173"
          />
          <rect
            x="95.234"
            y="145.565"
            fill={white}
            width="14.151"
            height="2.173"
          />
          <rect
            x="94.984"
            y="188.2"
            fill={white}
            width="14.151"
            height="2.175"
          />
          <rect
            x="95.234"
            y="98.456"
            fill={white}
            width="14.151"
            height="2.174"
          />
          <rect
            x="95.234"
            y="105.288"
            fill={white}
            width="14.151"
            height="2.173"
          />
          <rect
            x="112.54"
            y="91.003"
            fill={white}
            width="7.075"
            height="2.173"
          />
          <rect
            x="64.673"
            y="82.27"
            fill={white}
            width="10.585"
            height="2.174"
          />
          <rect
            x="64.673"
            y="97.595"
            fill={white}
            width="10.585"
            height="2.173"
          />
          <rect
            x="64.673"
            y="113.072"
            fill={white}
            width="10.585"
            height="2.175"
          />
          <rect
            x="64.673"
            y="158.751"
            fill={white}
            width="10.585"
            height="2.172"
          />
          <rect
            x="64.673"
            y="174.074"
            fill={white}
            width="10.585"
            height="2.175"
          />
          <rect
            x="64.673"
            y="189.553"
            fill={white}
            width="10.585"
            height="2.175"
          />
          <rect
            x="64.673"
            y="198.669"
            fill={white}
            width="10.585"
            height="2.175"
          />
          <rect
            x="64.673"
            y="209.571"
            fill={white}
            width="10.585"
            height="2.172"
          />
          <rect
            x="64.673"
            y="124.399"
            fill={white}
            width="10.585"
            height="2.174"
          />
          <rect
            x="64.673"
            y="131.507"
            fill={white}
            width="10.585"
            height="2.175"
          />
          <rect
            x="64.673"
            y="138.376"
            fill={white}
            width="10.585"
            height="2.174"
          />
          <rect
            x="64.673"
            y="145.485"
            fill={white}
            width="10.585"
            height="2.173"
          />
          <rect
            x="112.54"
            y="98.456"
            fill={white}
            width="7.075"
            height="2.174"
          />
          <rect
            x="112.54"
            y="105.288"
            fill={white}
            width="7.075"
            height="2.173"
          />
          <rect
            x="112.54"
            y="123.313"
            fill={white}
            width="7.075"
            height="2.173"
          />
          <rect
            x="112.54"
            y="145.549"
            fill={white}
            width="7.075"
            height="2.174"
          />
          <rect
            x="112.54"
            y="188.2"
            fill={white}
            width="7.075"
            height="2.175"
          />
          <rect
            x="171.962"
            y="91.003"
            opacity="0.5"
            fill={white}
            width="7.075"
            height="2.173"
          />
          <rect
            x="171.962"
            y="98.456"
            opacity="0.5"
            fill={white}
            width="7.075"
            height="2.174"
          />
          <rect
            x="171.962"
            y="105.288"
            opacity="0.5"
            fill={white}
            width="7.075"
            height="2.173"
          />
          <rect
            x="128.32"
            y="91.003"
            opacity="0.5"
            fill={white}
            width="36.81"
            height="2.173"
          />
          <rect
            x="128.32"
            y="98.456"
            opacity="0.5"
            fill={white}
            width="36.81"
            height="2.174"
          />
          <rect
            x="128.32"
            y="105.288"
            opacity="0.5"
            fill={white}
            width="36.81"
            height="2.173"
          />
          <rect
            x="128.32"
            y="122.988"
            opacity="0.5"
            fill={white}
            width="36.81"
            height="2.174"
          />
          <rect
            x="128.32"
            y="131.053"
            opacity="0.5"
            fill={white}
            width="36.81"
            height="2.175"
          />
          <rect
            x="172.624"
            y="131.053"
            opacity="0.5"
            fill={white}
            width="85.916"
            height="2.175"
          />
          <rect
            x="172.624"
            y="178.631"
            opacity="0.5"
            fill={white}
            width="62.849"
            height="2.176"
          />
          <rect
            x="244.893"
            y="178.631"
            opacity="0.5"
            fill={white}
            width="62.85"
            height="2.176"
          />
          <rect
            x="172.624"
            y="158.911"
            opacity="0.5"
            fill={white}
            width="85.916"
            height="2.173"
          />
          <rect
            x="172.624"
            y="122.988"
            opacity="0.5"
            fill={white}
            width="18.402"
            height="2.174"
          />
          <rect
            x="200.531"
            y="122.988"
            opacity="0.5"
            fill={white}
            width="28.313"
            height="2.174"
          />
          <rect
            x="173.486"
            y="168.401"
            opacity="0.5"
            fill={white}
            width="18.405"
            height="2.175"
          />
          <rect
            x="201.39"
            y="168.401"
            opacity="0.5"
            fill={white}
            width="28.315"
            height="2.175"
          />
          <rect
            x="269.41"
            y="158.751"
            opacity="0.5"
            fill={white}
            width="28.314"
            height="2.172"
          />
          <rect
            x="239.754"
            y="168.781"
            opacity="0.5"
            fill={white}
            width="81.119"
            height="2.174"
          />
          <rect
            x="172.624"
            y="199.703"
            opacity="0.5"
            fill={white}
            width="85.916"
            height="2.172"
          />
          <rect
            x="173.486"
            y="209.19"
            opacity="0.5"
            fill={white}
            width="18.405"
            height="2.173"
          />
          <rect
            x="201.39"
            y="209.19"
            opacity="0.5"
            fill={white}
            width="28.315"
            height="2.173"
          />
          <rect
            x="269.41"
            y="199.539"
            opacity="0.5"
            fill={white}
            width="28.314"
            height="2.175"
          />
          <rect
            x="239.754"
            y="209.571"
            opacity="0.5"
            fill={white}
            width="81.119"
            height="2.172"
          />
          <rect
            x="225.75"
            y="92.017"
            fill={orange}
            width="56.323"
            height="2.172"
          />
          <rect
            x="225.505"
            y="97.565"
            fill={orange}
            width="77.602"
            height="2.993"
          />
          <rect
            x="225.505"
            y="105.415"
            fill={orange}
            width="92.368"
            height="2.994"
          />
          <rect
            x="191.029"
            y="98.301"
            fill={orange}
            width="30.272"
            height="2.172"
          />
          <rect
            x="191.029"
            y="105.825"
            fill={orange}
            width="30.272"
            height="2.175"
          />
          <rect
            x="225.505"
            y="144.428"
            fill={orange}
            width="92.368"
            height="2.994"
          />
          <rect
            x="191.029"
            y="144.839"
            fill={orange}
            width="30.272"
            height="2.173"
          />
          <path
            fill={grey}
            d="M122.929,116.24c-9.623,0-19.247,0.019-28.87-0.027c-1.091-0.005-2.986,0.596-3.024-0.974
        c-0.049-2.079,2.02-1.156,3.122-1.16c19.246-0.064,38.495-0.05,57.739-0.052c0.535,0,1.07-0.011,1.604,0.015
        c0.793,0.039,1.785,0.017,1.731,1.12c-0.051,1.05-1.028,1.057-1.829,1.06c-4.947,0.013-9.892,0.014-14.836,0.016
        C133.355,116.241,128.141,116.24,122.929,116.24z"
          />
          <path
            fill={grey}
            d="M122.929,162.229c-9.623,0-19.247,0.02-28.87-0.029c-1.091-0.005-2.986,0.598-3.024-0.973
        c-0.049-2.079,2.02-1.157,3.122-1.161c19.246-0.065,38.495-0.047,57.739-0.051c0.535,0,1.07-0.011,1.604,0.017
        c0.793,0.037,1.785,0.016,1.731,1.117c-0.051,1.052-1.028,1.058-1.829,1.06c-4.947,0.015-9.892,0.016-14.836,0.017
        C133.355,162.231,128.141,162.229,122.929,162.229z"
          />
          <path
            fill={grey}
            d="M122.929,171.333c-9.623,0-19.247,0.021-28.87-0.028c-1.091-0.005-2.986,0.597-3.024-0.973
        c-0.049-2.078,2.02-1.157,3.122-1.16c19.246-0.064,38.495-0.05,57.739-0.053c0.535,0,1.07-0.01,1.604,0.017
        c0.793,0.038,1.785,0.018,1.731,1.119c-0.051,1.052-1.028,1.057-1.829,1.06c-4.947,0.015-9.892,0.016-14.836,0.018
        C133.355,171.334,128.141,171.333,122.929,171.333z"
          />
          <path
            fill={grey}
            d="M122.929,179.639c-9.623,0-19.247,0.018-28.87-0.03c-1.091-0.005-2.986,0.599-3.024-0.972
        c-0.049-2.079,2.02-1.157,3.122-1.16c19.246-0.066,38.495-0.05,57.739-0.052c0.535,0,1.07-0.014,1.604,0.015
        c0.793,0.04,1.785,0.018,1.731,1.119c-0.051,1.05-1.028,1.057-1.829,1.059c-4.947,0.018-9.892,0.018-14.836,0.02
        C133.355,179.639,128.141,179.639,122.929,179.639z"
          />
          <path
            fill={grey}
            d="M122.929,211.416c-9.623,0-19.247,0.021-28.87-0.027c-1.091-0.005-2.986,0.596-3.024-0.973
        c-0.049-2.079,2.02-1.157,3.122-1.161c19.246-0.064,38.495-0.049,57.739-0.05c0.535,0,1.07-0.014,1.604,0.015
        c0.793,0.038,1.785,0.018,1.731,1.118c-0.051,1.051-1.028,1.059-1.829,1.061c-4.947,0.014-9.892,0.014-14.836,0.017
        C133.355,211.417,128.141,211.416,122.929,211.416z"
          />
          <path
            fill={orange}
            d="M122.929,84.687c-9.623,0-19.247,0.02-28.87-0.026c-1.091-0.005-2.986,0.596-3.024-0.973
        c-0.049-2.08,2.02-1.157,3.122-1.161c19.247-0.063,38.495-0.049,57.74-0.052c0.536,0,1.071-0.011,1.604,0.016
        c0.793,0.037,1.785,0.016,1.73,1.117c-0.051,1.052-1.028,1.059-1.829,1.06c-4.947,0.015-9.892,0.015-14.836,0.017
        C133.355,84.688,128.141,84.687,122.929,84.687z"
          />
          <path
            fill={orange}
            d="M122.929,201.495c-9.623,0-19.247,0.019-28.87-0.028c-1.091-0.006-2.986,0.597-3.024-0.973
        c-0.049-2.08,2.02-1.157,3.122-1.161c19.246-0.065,38.495-0.051,57.739-0.052c0.535,0,1.07-0.011,1.604,0.018
        c0.793,0.037,1.785,0.017,1.731,1.116c-0.051,1.052-1.028,1.059-1.829,1.061c-4.947,0.016-9.892,0.017-14.836,0.018
        C133.355,201.496,128.141,201.495,122.929,201.495z"
          />
          <rect
            x="63.628"
            y="70.403"
            fill={grey}
            width="7.754"
            height="2.175"
          />
          <rect
            x="77.744"
            y="70.403"
            fill={grey}
            width="7.754"
            height="2.175"
          />
          <rect
            x="91.394"
            y="70.403"
            fill={grey}
            width="7.751"
            height="2.175"
          />
          <rect
            x="105.51"
            y="70.403"
            fill={grey}
            width="7.751"
            height="2.175"
          />
          <rect
            x="119.615"
            y="70.403"
            fill={grey}
            width="7.753"
            height="2.175"
          />
          <rect
            x="133.731"
            y="70.403"
            fill={grey}
            width="7.753"
            height="2.175"
          />
          <rect
            x="148.087"
            y="70.403"
            fill={grey}
            width="7.754"
            height="2.175"
          />
          <rect
            x="162.202"
            y="70.403"
            fill={grey}
            width="7.754"
            height="2.175"
          />
        </g>
        <g ref={pageRef}>
          <rect x="125.524" y="145" fill={white} width="289.311" height="153" />
          <path
            fill={grey}
            d="M131.803,249.1c-0.005-24.877,0.039-49.751-0.083-74.623c-0.016-2.933,0.763-3.719,3.704-3.715
        c44.276,0.096,88.551,0.104,132.827-0.01c3.269-0.008,3.824,1.08,3.812,4.029c-0.102,24.748-0.05,49.5-0.044,74.248
        c-1.111,0.076-2.228,0.217-3.341,0.217c-44.633,0.012-89.266,0.008-133.898,0C133.788,249.246,132.797,249.151,131.803,249.1z
         M208.773,191.988c0.143-1.408-0.513-1.944-1.442-2.117c-1.799-0.332-1.949,1.221-2.343,2.301
        c-4.154,11.415-8.273,22.842-12.375,34.274c-0.478,1.333-1.552,2.988,0.531,3.803c2.223,0.869,2.337-1.262,2.794-2.519
        c4.118-11.294,8.177-22.61,12.248-33.925C208.436,193.11,208.642,192.397,208.773,191.988z M226.531,210.576
        c0.165-1.301-0.462-1.777-0.981-2.301c-3.246-3.258-6.497-6.509-9.754-9.753c-0.891-0.885-1.883-1.998-3.094-0.612
        c-1.127,1.286-0.063,2.263,0.834,3.16c2.372,2.372,4.67,4.828,7.145,7.091c1.539,1.408,1.667,2.4,0.054,3.867
        c-2.572,2.339-4.952,4.889-7.411,7.348c-0.946,0.948-1.724,1.963-0.396,3.141c1.253,1.116,2.031-0.022,2.791-0.772
        c3.272-3.233,6.525-6.485,9.758-9.754C225.977,211.48,226.338,210.833,226.531,210.576z M187.872,222.901
        c0.003-0.006,0.354-0.514,0.587-1.073c0.403-0.973-0.169-1.642-0.808-2.28c-2.548-2.55-5.026-5.178-7.663-7.632
        c-1.434-1.335-1.445-2.272-0.02-3.607c2.629-2.465,5.122-5.077,7.667-7.63c0.859-0.86,1.476-1.817,0.399-2.88
        c-1.139-1.127-2.043-0.248-2.849,0.551c-3.268,3.238-6.474,6.54-9.784,9.732c-1.512,1.459-1.452,2.611,0.034,4.045
        c3.312,3.193,6.521,6.487,9.782,9.734C185.744,222.378,186.236,222.98,187.872,222.901z"
          />
          <path
            fill={smoothBlack}
            d="M342.324,170.824c21.152,0,42.306,0.049,63.457-0.06c2.788-0.014,3.849,0.502,3.78,3.602
        c-0.221,9.948-0.174,19.906-0.021,29.858c0.04,2.671-0.502,3.63-3.422,3.626c-42.678-0.096-85.357-0.095-128.034,0.005
        c-2.97,0.008-3.455-1.04-3.419-3.673c0.14-9.827,0.178-19.659-0.02-29.486c-0.062-3.046,0.657-3.975,3.848-3.95
        C299.77,170.898,321.048,170.824,342.324,170.824z M342.319,185.692c19.654,0,39.309,0.01,58.961-0.03
        c0.975-0.004,2.688,0.632,2.732-0.948c0.045-1.633-1.683-0.879-2.664-1.073c-0.358-0.072-0.746-0.014-1.117-0.014
        c-38.686,0-77.369-0.002-116.057,0.001c-0.869,0-1.739,0.019-2.608,0.061c-0.704,0.033-1.169,0.369-1.108,1.145
        c0.047,0.618,0.525,0.783,1.036,0.804c0.993,0.041,1.989,0.056,2.983,0.056C303.758,185.693,323.038,185.692,342.319,185.692z
         M334.142,190.139c16.41,0,32.821,0.002,49.23,0c0.994,0,1.99,0.011,2.983-0.027c0.614-0.025,1.33-0.074,1.416-0.865
        c0.116-1.076-0.732-1.108-1.474-1.146c-0.746-0.037-1.492-0.027-2.237-0.027c-33.318-0.001-66.638-0.003-99.952,0
        c-0.871,0-1.741,0.018-2.611,0.061c-0.519,0.026-0.975,0.228-1.04,0.823c-0.089,0.791,0.396,1.103,1.098,1.127
        c0.993,0.037,1.988,0.05,2.981,0.05C301.074,190.139,317.608,190.139,334.142,190.139z M306.103,180.979
        c7.569,0,15.137,0.034,22.703-0.037c1.151-0.011,3.122,0.805,3.059-1.482c-0.059-2.059-1.88-1.158-2.888-1.164
        c-15.26-0.062-30.519-0.062-45.776,0c-1.005,0.006-2.832-0.881-2.872,1.177c-0.047,2.284,1.925,1.458,3.072,1.47
        C290.968,181.018,298.537,180.979,306.103,180.979z"
          />
          <path
            fill={grey}
            d="M275.476,161.831c-3.109-0.003-6.218,0.015-9.325-0.015c-0.987-0.01-2.148,0.016-2.07-1.465
        c0.063-1.183,1.041-1.269,1.917-1.271c6.217-0.02,12.436-0.02,18.654-0.006c0.904,0.002,1.816,0.171,1.862,1.343
        c0.061,1.52-1.095,1.406-2.082,1.412C281.446,161.846,278.463,161.835,275.476,161.831z"
          />
          <path
            fill={grey}
            d="M171.737,161.828c-2.846,0-5.689,0.024-8.534-0.014c-0.938-0.014-2.156,0.188-2.25-1.26
        c-0.091-1.458,1.084-1.474,2.061-1.478c6.184-0.028,12.369-0.021,18.553-0.006c0.901,0.002,1.793,0.16,1.855,1.33
        c0.082,1.518-1.05,1.42-2.038,1.424C178.168,161.84,174.951,161.829,171.737,161.828z"
          />
          <path
            fill={grey}
            d="M223.961,159.057c2.968,0.005,5.938-0.009,8.905,0.012c1.003,0.008,2.117,0.027,2.117,1.446
        c0,1.518-1.216,1.302-2.155,1.305c-6.063,0.021-12.124,0.021-18.184-0.003c-0.961-0.002-2.148,0.094-2.129-1.379
        c0.021-1.443,1.212-1.359,2.166-1.373C217.777,159.029,220.867,159.053,223.961,159.057z"
          />
          <path
            fill={grey}
            d="M249.687,161.829c-3.091,0-6.182,0.017-9.271-0.007c-0.957-0.007-2.165,0.163-2.144-1.332
        c0.021-1.398,1.157-1.42,2.153-1.422c6.058-0.02,12.114-0.026,18.173-0.004c0.969,0.002,2.151-0.036,2.147,1.397
        c-0.002,1.45-1.195,1.347-2.157,1.357C255.621,161.848,252.652,161.828,249.687,161.829z"
          />
          <path
            fill={grey}
            d="M197.902,161.823c-3.11,0.003-6.222,0.013-9.333-0.003c-0.89-0.004-1.837-0.046-1.843-1.291
        c-0.004-1.16,0.83-1.443,1.769-1.445c6.347-0.012,12.693-0.014,19.041,0.012c0.938,0.004,1.782,0.342,1.675,1.514
        c-0.112,1.212-1.122,1.188-1.973,1.197C204.124,161.841,201.013,161.82,197.902,161.823z"
          />
          <path
            fill={white}
            d="M208.773,191.988c-0.13,0.409-0.336,1.122-0.586,1.817c-4.07,11.314-8.127,22.634-12.247,33.925
        c-0.457,1.257-0.57,3.391-2.794,2.519c-2.083-0.812-1.009-2.47-0.531-3.803c4.102-11.433,8.222-22.86,12.375-34.274
        c0.392-1.08,0.543-2.633,2.343-2.301C208.258,190.044,208.915,190.58,208.773,191.988z"
          />
          <path
            fill={white}
            d="M226.531,210.576c-0.192,0.257-0.554,0.904-1.056,1.413c-3.232,3.27-6.485,6.521-9.758,9.751
        c-0.76,0.75-1.538,1.893-2.791,0.776c-1.327-1.178-0.55-2.192,0.396-3.141c2.459-2.462,4.839-5.011,7.411-7.349
        c1.613-1.469,1.485-2.46-0.054-3.867c-2.475-2.262-4.771-4.718-7.145-7.092c-0.896-0.896-1.961-1.875-0.834-3.16
        c1.213-1.385,2.203-0.272,3.094,0.613c3.259,3.244,6.508,6.496,9.754,9.753C226.067,208.799,226.696,209.275,226.531,210.576z"
          />
          <path
            fill={white}
            d="M187.872,222.901c-1.638,0.079-2.129-0.523-2.651-1.04c-3.261-3.247-6.471-6.544-9.783-9.734
        c-1.485-1.434-1.545-2.589-0.034-4.047c3.31-3.191,6.516-6.493,9.784-9.732c0.806-0.798,1.711-1.678,2.849-0.55
        c1.077,1.063,0.458,2.019-0.399,2.88c-2.547,2.552-5.038,5.164-7.667,7.629c-1.425,1.336-1.414,2.274,0.021,3.607
        c2.637,2.456,5.115,5.082,7.662,7.631c0.638,0.639,1.213,1.311,0.808,2.283C188.225,222.388,187.875,222.896,187.872,222.901z"
          />
          <path
            fill={smoothGrey}
            d="M131.777,249.366c0.991,0.055,1.982,0.153,2.974,0.153c44.633,0.008,89.268,0.008,133.9,0
        c1.113-0.004,2.229-0.146,3.341-0.22c0.028,11.82-0.006,23.64,0.131,35.461c0.028,2.822-1.072,3.617-3.75,3.614
        c-44.297-0.056-88.595-0.062-132.891,0.011c-2.984,0.007-3.854-1.064-3.818-3.929C131.798,272.761,131.753,261.063,131.777,249.366
        z M191.297,267.338c16.417,0,32.834,0.003,49.25-0.006c1.118,0,2.237-0.048,3.351-0.115c0.545-0.033,0.985-0.391,0.986-0.919
        c0.003-0.531-0.436-0.874-0.979-0.926c-0.742-0.069-1.486-0.117-2.229-0.117c-33.458-0.006-66.913-0.014-100.371,0.02
        c-1.119,0.003-3.092-0.596-3.095,1.015c-0.001,1.622,1.966,0.871,3.092,1.028c0.733,0.102,1.489,0.021,2.237,0.021
        C159.458,267.338,175.38,267.338,191.297,267.338z"
          />
          <path
            fill={grey}
            d="M340.724,244.161c0,2.99,0,5.422,0,5.422l-65.971-0.213c-0.7,0.213-0.321-2.216-0.321-5.209v-27.379
        c0-2.997,1.806-5.426,4.033-5.426h58.229c2.226,0,4.03,2.429,4.03,5.426V244.161z"
          />
          <path
            fill={orange}
            d="M409.564,244.161c0,2.99-0.022,5.422-0.022,5.422h-66.269c0,0,0-2.432,0-5.422v-27.379
        c0-2.997,1.804-5.426,4.031-5.426h58.23c2.226,0,4.029,2.429,4.029,5.426V244.161z"
          />
          <line
            fill="none"
            x1="208.791"
            y1="249.254"
            x2="208.791"
            y2="249.254"
          />
          <path
            fill={smoothGrey}
            d="M274.753,249.366c0.953,0.055,1.908,0.153,2.859,0.153c42.907,0.008,85.812,0.008,128.719,0
        c1.069-0.004,2.14-0.146,3.21-0.22c0.028,11.82-0.007,23.64,0.123,35.461c0.032,2.822-1.028,3.617-3.604,3.614
        c-42.581-0.056-85.163-0.062-127.745,0.011c-2.87,0.007-3.703-1.064-3.672-3.929C274.776,272.761,274.731,261.063,274.753,249.366z
         M339.988,262.891c18.403,0,36.81,0.018,55.214-0.034c1.312-0.005,3.736,0.689,3.714-0.857c-0.03-2.212-2.458-1.148-3.789-1.151
        c-36.572-0.065-73.145-0.052-109.717-0.052c-0.715,0-1.434-0.019-2.147,0.028c-0.698,0.045-1.65-0.021-1.729,0.905
        c-0.113,1.344,1.052,1.054,1.805,1.13c0.83,0.085,1.672,0.031,2.508,0.031C303.894,262.891,321.941,262.891,339.988,262.891z
         M331.97,267.338c15.78,0,31.563,0.003,47.343-0.006c1.075,0,2.15-0.048,3.222-0.115c0.524-0.033,0.946-0.391,0.948-0.919
        c0.001-0.531-0.42-0.874-0.943-0.926c-0.711-0.069-1.429-0.117-2.144-0.117c-32.16-0.006-64.319-0.014-96.481,0.02
        c-1.075,0.003-2.974-0.596-2.977,1.015c-0.001,1.622,1.889,0.871,2.972,1.028c0.706,0.102,1.433,0.021,2.15,0.021
        C301.363,267.338,316.669,267.338,331.97,267.338z M305.554,258.335c7.169,0,14.338,0.021,21.505-0.026
        c1.085-0.005,2.744,0.402,2.71-1.484c-0.034-1.892-1.701-1.389-2.771-1.392c-14.337-0.036-28.673-0.036-43.01,0
        c-1.066,0.003-2.743-0.483-2.704,1.445c0.035,1.853,1.678,1.426,2.767,1.434C291.217,258.356,298.387,258.337,305.554,258.335z
         M292.579,272.637c-3.229,0-6.461,0.09-9.687-0.028c-2.429-0.09-2.721,1.36-2.657,3.331c0.058,1.771-0.183,3.607,2.401,3.59
        c6.336-0.037,12.678-0.048,19.013,0.022c2.576,0.027,2.426-1.735,2.461-3.53c0.037-1.897-0.057-3.506-2.562-3.41
        C298.561,272.724,295.568,272.637,292.579,272.637z"
          />
          <rect
            x="284.29"
            y="274.86"
            fill={white}
            width="16.272"
            height="2.239"
          />
          <path
            fill={orange}
            d="M305.007,199.748c0,0.979-0.917,1.769-2.049,1.769h-20.027c-1.131,0-2.047-0.792-2.047-1.769v-1.076
        c0-0.977,0.916-1.769,2.047-1.769h20.027c1.132,0,2.049,0.793,2.049,1.769V199.748z"
          />
          <rect
            x="138.05"
            y="265.221"
            fill={grey}
            width="106.833"
            height="2.488"
          />
          <rect
            x="138.05"
            y="261.167"
            fill={grey}
            width="122.143"
            height="2.488"
          />
          <rect
            x="138.05"
            y="255.675"
            fill={grey}
            width="50.21"
            height="3.27"
          />
          <path
            fill={grey}
            d="M162.902,276.586c0,1.237-1.003,2.24-2.242,2.24h-20.37c-1.235,0-2.24-1.003-2.24-2.24v-2.468
        c0-1.237,1.003-2.24,2.24-2.24h20.37c1.237,0,2.242,1.003,2.242,2.24V276.586z"
          />
          <path
            fill={grey}
            d="M305.007,277.334c0,1.233-1.002,2.24-2.241,2.24h-20.368c-1.236,0-2.241-1.003-2.241-2.24v-2.468
        c0-1.237,1.004-2.241,2.241-2.241h20.368c1.236,0,2.241,1.004,2.241,2.241V277.334z"
          />
          <rect
            x="281.082"
            y="265.221"
            fill={grey}
            width="106.834"
            height="2.488"
          />
          <rect
            x="281.082"
            y="261.167"
            fill={grey}
            width="122.143"
            height="2.488"
          />
          <rect
            x="281.082"
            y="255.675"
            fill={grey}
            width="50.21"
            height="3.27"
          />
          <path
            fill={orange}
            d="M153.192,162.425c0,0.809-0.656,1.465-1.466,1.465H133.23c-0.811,0-1.466-0.656-1.466-1.465v-3.943
        c0-0.808,0.655-1.464,1.466-1.464h18.497c0.81,0,1.466,0.656,1.466,1.464V162.425z"
          />
        </g>
        <g ref={jsRef}>
          <path
            fill={darkGrey}
            d="M315.719,64.607c4.008-16.122,13.966-26.934,29.36-32.889c7.836-1.7,15.647-2.505,23.604-0.458
            c22.959,5.907,36.706,28.316,31.597,51.711c-0.188,0.86-0.328,1.729-0.486,2.594c-3.564,9.924-9.429,18.05-18.322,23.929
            c-6.941,4.587-14.791,6.038-22.792,7.11c-3.755-0.594-7.576-0.923-11.256-1.829c-20.337-4.998-34.701-25.583-31.945-45.566
            C315.557,67.675,315.638,66.141,315.719,64.607z"
          />
          <path
            fill={smoothGrey}
            d="M341.61,90.931v-4.954c1.702,0.028,2.837-0.227,3.403-0.765c0.566-0.534,0.85-1.651,0.85-3.355V55.455h7.04
            v26.462c0,1.276-0.061,2.363-0.185,3.259c-0.123,0.898-0.35,1.729-0.688,2.497c-0.333,0.767-0.792,1.376-1.37,1.828
            c-0.583,0.451-1.333,0.803-2.255,1.054c-0.922,0.252-2.034,0.377-3.336,0.377L341.61,90.931L341.61,90.931L341.61,90.931z"
          />
          <path
            fill={smoothGrey}
            d="M355.843,80.312l7.141-1.373c0,0.709,0.013,1.333,0.036,1.875c0.025,0.541,0.097,1.132,0.208,1.771
            c0.104,0.638,0.255,1.16,0.443,1.567c0.188,0.408,0.45,0.747,0.79,1.025c0.345,0.278,0.745,0.416,1.214,0.416
            c1.393,0,2.088-0.891,2.088-2.668c0-2.438-1.374-4.861-4.121-7.273l-2.187-1.857c-0.694-0.581-1.25-1.064-1.665-1.451
            c-0.411-0.386-0.896-0.91-1.459-1.565c-0.562-0.658-1-1.294-1.315-1.907c-0.314-0.612-0.588-1.349-0.813-2.213
            c-0.226-0.864-0.338-1.78-0.338-2.746c0-2.826,0.896-5.059,2.688-6.703c1.791-1.644,4.173-2.464,7.139-2.464
            c2.464,0,4.559,0.876,6.286,2.63c1.729,1.752,2.591,3.869,2.591,6.345l-0.02,0.251c0,0.166,0,0.36,0,0.58l-7.271,1.007
            c0-0.143,0.009-0.364,0.029-0.668c0.018-0.301,0.029-0.549,0.029-0.746c0-0.463-0.035-0.908-0.101-1.334
            c-0.063-0.425-0.169-0.852-0.318-1.277c-0.146-0.426-0.377-0.765-0.687-1.016c-0.308-0.251-0.682-0.377-1.12-0.377
            c-0.774,0-1.354,0.303-1.742,0.91c-0.388,0.605-0.58,1.321-0.58,2.148c0,0.722,0.112,1.4,0.341,2.031
            c0.225,0.631,0.566,1.221,1.025,1.769c0.459,0.549,0.881,1.003,1.27,1.365c0.387,0.36,0.899,0.802,1.547,1.324
            c0.645,0.523,1.1,0.906,1.375,1.152c0.991,0.877,1.83,1.698,2.516,2.464c0.681,0.77,1.326,1.635,1.933,2.602
            c0.604,0.966,1.062,2.011,1.361,3.134c0.305,1.122,0.456,2.334,0.456,3.638c0,2.437-0.871,4.434-2.609,5.986
            c-1.742,1.554-3.856,2.332-6.347,2.332c-1.61,0-3.044-0.295-4.296-0.89c-1.248-0.593-2.26-1.4-3.026-2.417
            c-0.766-1.02-1.355-2.148-1.768-3.388C356.152,83.059,355.906,81.73,355.843,80.312z"
          />
        </g>
        <g ref={fiveRef}>
          <path
            fill={smoothBlack}
            d="M312.256,267.692c4.447-17.871,15.483-29.855,32.552-36.458c8.688-1.886,17.342-2.773,26.163-0.505
        c25.447,6.547,40.686,31.387,35.024,57.322c-0.209,0.952-0.362,1.916-0.542,2.875c-3.953,11.001-10.449,20.009-20.311,26.526
        c-7.695,5.085-16.396,6.694-25.265,7.882c-4.162-0.66-8.397-1.026-12.474-2.029c-22.541-5.54-38.47-28.359-35.41-50.513
        C312.082,271.096,312.171,269.393,312.256,267.692z"
          />
          <path
            fill={grey}
            d="M359.88,229.384c35.25,1.384,51.78,32.732,46.115,58.668c-0.209,0.952-0.362,1.916-0.54,2.875
        c-3.953,11.001-10.451,20.009-20.313,26.526c-7.695,5.085-16.393,6.694-25.265,7.882"
          />
          <polyline
            fill={smoothBlack}
            points="345.105,265.449 322.649,259.639 302.896,224.831 341.233,246.082 	"
          />
          <polygon
            fill={smoothGrey}
            points="359.759,272.497 345.511,272.497 344.528,261.47 359.759,261.47 359.759,250.704 359.722,250.704 
        332.76,250.704 333.018,253.592 335.663,283.265 359.759,283.265 	"
          />
          <polygon
            fill={smoothGrey}
            points="359.759,300.462 359.713,300.475 347.721,297.235 346.954,288.647 341.128,288.647 
        336.147,288.647 337.656,305.555 359.71,311.68 359.759,311.664 	"
          />
          <polygon
            fill={white}
            points="359.722,272.497 359.722,283.265 372.981,283.265 371.732,297.228 359.722,300.47 359.722,311.672 
        381.797,305.555 381.957,303.735 384.488,275.388 384.749,272.497 381.848,272.497 	"
          />
          <polygon
            fill={white}
            points="359.722,250.704 359.722,257.39 359.722,261.443 359.722,261.47 385.695,261.47 385.695,261.47 
        385.731,261.47 385.946,259.05 386.439,253.592 386.696,250.704 	"
          />
        </g>
        <g ref={phoneRef}>
          <path
            fill={smoothBlack}
            d="M55.78,327.614c0,4.745,3.878,8.622,8.622,8.622h38.756c4.745,0,8.622-3.877,8.622-8.622v-91.937
        c0-4.744-3.876-8.622-8.622-8.622H64.402c-4.744,0-8.622,3.878-8.622,8.622V327.614z"
          />
          <path
            fill={white}
            d="M108.641,249.477v69.249c0,1.223-0.675,2.212-1.506,2.212H59.961c-0.833,0-1.507-0.988-1.507-2.212v-69.249
        "
          />
          <rect
            x="58.455"
            y="240.57"
            fill={orange}
            width="50.186"
            height="8.906"
          />
          <path
            fill={smoothGrey}
            d="M74.989,285.763c-3.344-3.433-3.806-7.351-1.379-11.723c2.25-4.06,7.317-6.543,11.652-5.71
        c5.036,0.969,8.699,4.428,9.121,9.054c0.314,3.443,0.622,7.074-3.197,9.165c-0.599-2.166-1.783-3.589-4.266-3.359
        c-0.544-0.229-1.082-0.57-1.047-1.178c0.292-0.216-0.41-0.853-0.229-1.107c0.279-0.4,1.561-0.51,1.308-1.116l-0.026,0.071
        c1.56-2.695,1.552-5.367-0.776-7.524c-1.708-1.582-4.048-1.509-5.849-0.228c-2.375,1.689-3.315,4.084-1.693,6.927l-0.05-0.078
        c0.255,0.287,0.507,0.575,0.763,0.866c1.444,1.443,2.89,2.891-0.656,3.368C76.885,283.254,75.431,283.787,74.989,285.763z"
          />
          <path
            fill={grey}
            d="M86.92,283.186c2.484-0.228,3.668,1.196,4.266,3.361c-3.748,5.855-12.789,5.419-16.196-0.785
        c0.442-1.976,1.896-2.511,3.674-2.572c-0.361,3.121,1.375,5.001,4.156,5.002C85.609,288.195,87.354,286.336,86.92,283.186z"
          />
          <path
            fill={smoothBlack}
            d="M92.135,300.746c-5.749,0-11.499,0-17.248,0c0-0.171,0-0.34-0.001-0.511c5.802,0,11.604,0,17.407,0
        C92.241,300.406,92.19,300.575,92.135,300.746z"
          />
          <path
            fill={smoothBlack}
            d="M88.144,303.122c-3.296,0-6.594,0-9.892,0c0.004-0.128,0.005-0.255,0.007-0.381c3.297,0,6.595,0,9.891,0
        C88.148,302.868,88.145,302.994,88.144,303.122z"
          />
          <path
            fill={white}
            d="M86.92,283.186c0.436,3.15-1.311,5.009-4.101,5.006c-2.78-0.001-4.517-1.881-4.156-5.002
        c3.546-0.477,2.1-1.925,0.656-3.368c-0.252-0.29-0.506-0.578-0.763-0.866l0.05,0.078c0.914-4.76,2.989-0.717,4.328-1.671
        c3.157-2.251,4.224-0.841,3.992,2.496l0.026-0.071c-0.295,1.116-0.968,1.179-0.847,2.177
        C86.07,282.573,86.376,282.958,86.92,283.186z"
          />
          <path
            fill={smoothBlack}
            d="M86.926,279.858c0.232-3.337-1.097-3.788-3.992-2.496c-1.338,0.954-3.093-2.72-4.328,1.671
        c-1.622-2.843-0.681-5.236,1.693-6.927c1.803-1.28,4.141-1.354,5.849,0.228C88.476,274.488,88.485,277.16,86.926,279.858z"
          />
          <path
            fill={white}
            d="M88.15,329.479c0,0.884-0.716,1.598-1.598,1.598h-7.159c-0.882,0-1.596-0.714-1.596-1.598l0,0
        c0-0.883,0.714-1.594,1.596-1.594h7.159C87.434,327.885,88.15,328.596,88.15,329.479L88.15,329.479z"
          />
        </g>
        <g ref={rwdRef}>
          <path
            fill={lightGrey}
            d="M88.785,255.866c0,2.915-2.362,5.28-5.278,5.28H10.279c-2.916,0-5.278-2.365-5.278-5.28v-37.615
        c0-2.913,2.362-5.277,5.278-5.277h73.229c2.916,0,5.278,2.364,5.278,5.277V255.866z"
          />
          <polygon
            fill={lightGrey}
            points="77.902,275.845 56.79,258.215 66.388,248.616 77.902,254.229 	"
          />
          <path
            fill={smoothBlack}
            d="M20.742,251.236v-27.037h5.949c1.351,0,2.542,0.129,3.568,0.384c1.026,0.253,1.925,0.655,2.695,1.201
        c0.773,0.544,1.357,1.282,1.758,2.208c0.4,0.927,0.601,2.036,0.601,3.326c0,0.563-0.02,1.062-0.06,1.502
        c-0.041,0.442-0.134,0.945-0.278,1.511c-0.145,0.566-0.343,1.065-0.594,1.502c-0.249,0.436-0.61,0.863-1.08,1.285
        c-0.47,0.421-1.026,0.771-1.668,1.051c1.622,5.147,3.034,9.503,4.236,13.069h-5.664l-3.095-12.453H26.21v12.453h-5.467V251.236z
         M26.211,235.45h1.563c0.42,0,0.781-0.141,1.08-0.421c0.302-0.279,0.519-0.636,0.654-1.067c0.135-0.429,0.23-0.827,0.286-1.186
        c0.055-0.36,0.083-0.7,0.083-1.022c0-0.319-0.023-0.649-0.067-0.99c-0.045-0.341-0.127-0.718-0.242-1.132
        c-0.116-0.416-0.305-0.757-0.572-1.021c-0.266-0.266-0.587-0.399-0.968-0.399h-1.817V235.45z"
          />
          <path
            fill={smoothBlack}
            d="M40.045,251.236l-3.741-27.037h4.656l1.683,17.906l2.314-17.906h4.01l2.267,17.8l1.758-17.8h4.673
        l-3.783,27.037h-4.673L46.94,233.54l-2.223,17.696H40.045z"
          />
          <path
            fill={smoothBlack}
            d="M59.59,251.236v-27.037h5.647c1.801,0,3.286,0.162,4.454,0.488c1.168,0.324,2.084,0.86,2.751,1.606
        c0.664,0.746,1.124,1.64,1.382,2.682c0.254,1.042,0.381,2.367,0.381,3.98v8.863c0,0.961-0.029,1.8-0.091,2.517
        c-0.059,0.716-0.172,1.417-0.336,2.104s-0.391,1.266-0.677,1.743c-0.287,0.475-0.654,0.917-1.104,1.328
        c-0.452,0.412-0.995,0.736-1.629,0.978c-0.635,0.238-1.382,0.424-2.239,0.556c-0.854,0.13-1.84,0.194-2.952,0.194H59.59V251.236z
         M65.264,247.406c0.522,0,0.931-0.009,1.234-0.021c0.302-0.016,0.599-0.054,0.902-0.113c0.301-0.063,0.52-0.144,0.661-0.249
        c0.138-0.104,0.27-0.256,0.391-0.458c0.119-0.199,0.198-0.438,0.232-0.713c0.036-0.275,0.054-0.619,0.054-1.029v-14.555
        c0-0.892-0.268-1.483-0.798-1.773c-0.529-0.29-1.42-0.44-2.672-0.45v19.362H65.264z"
          />
        </g>
      </svg>
    </div>
  );
};

export default HighlightImage;
