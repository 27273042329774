import React from 'react';

/* Components */
import SideProject from './SideProject';

/* Assets */
import justiceSrc from '../../../../../public/img/projects_justiceLeague.png';
import louperSrc from '../../../../../public/img/projects_louper.png';
import foodublinSrc from '../../../../../public/img/projects_foodublin.png';

/* Utils */
import { buildCombinedList } from '../../../../utils';
import { projects } from '../../../../utils/literals';

const { projectsList } = projects;

const projectsWithSrc = buildCombinedList({
  initialList: projectsList,
  addedList: [justiceSrc, louperSrc, foodublinSrc],
  addedItemName: 'src',
});

const SideProjects = ({ isAnimated, isDeviceLessWiderThanLgWidth }) => (
  <>
    {projectsWithSrc.map((project, index) => (
      <SideProject
        key={project.id}
        src={project.src}
        altImg={project.name}
        title={project.name}
        url={project.url}
        repoUrl={project.repoUrl}
        firstDescription={project.firstDescription}
        secondDescription={project.secondDescription}
        isAnimated={isAnimated}
        isDeviceLessWiderThanLgWidth={isDeviceLessWiderThanLgWidth}
        isEven={index % 2}
      />
    ))}
  </>
);

export default SideProjects;
