import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import Item from './item';

/* UI */
import AboutIcon from '../../../ui/icons/AboutIcon';
import SkillsIcon from '../../../ui/icons/SkillsIcon';
import ProjectsIcon from '../../../ui/icons/ProjectsIcon';

/* Utils */
import { buildCombinedList } from '../../../../utils';
import { menuItems } from '../../../../utils/literals';

const menuItemsWithIcon = buildCombinedList({
  initialList: menuItems,
  addedList: [AboutIcon, SkillsIcon, ProjectsIcon],
  addedItemName: 'iconComponent',
});

const Menu = ({ onClick, currentPath }) => (
  <nav className="pageHeader-navigation">
    <ul className="pageHeader-menu">
      {menuItemsWithIcon.map((item) => (
        <Item
          key={item.txt}
          txt={item.txt}
          iconComponent={item.iconComponent}
          link={item.url}
          onClick={onClick}
          currentPath={currentPath}
        />
      ))}
    </ul>
  </nav>
);

Menu.propTypes = {
  onClick: PropTypes.func,
  currentPath: PropTypes.string,
};

export default Menu;
