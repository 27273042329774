import React, { useState, useRef, useEffect } from 'react';
import { InView } from 'react-intersection-observer';

/* Hooks */
import useWrapperClasses from '../../hooks/useWrapperClasses';

/* Utils */
import { OBSERVER_OPTIONS } from '../../../utils';
import { projects } from '../../../utils/literals';
import {
  initialPosFirstItem,
  initialPosSecondItem,
  initialPosThirdItem,
} from '../../../utils/animations/positions';

/* Assets */
import pathImg from '../../../../public/img/portfolio.png';

const {
  portfolio: { title, firstDescription, secondDescription, altImg },
} = projects;

const Portfolio = ({ isAnimated, isDeviceLessWiderThanLgWidth }) => {
  const initialWrapperClasses = isAnimated
    ? ['portfolio']
    : ['portfolio', 'visible'];
  const [wrapperClasses, dispatch] = useWrapperClasses(initialWrapperClasses);

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const imageRef = useRef(null);
  const animationRef = useRef(null);

  const [launchAnimation, setLaunchAnimation] = useState(false);

  useEffect(() => {
    if (isAnimated) {
      animationRef.current = new TimelineMax({
        paused: true,
      });

      animationRef.current
        .from(
          titleRef.current,
          0.4,
          {
            autoAlpha: 0,
            x: -initialPosFirstItem,
            ease: Power2.easeOut,
          },
          '+=0',
        )
        .from(
          descriptionRef.current,
          0.4,
          {
            autoAlpha: 0,
            x: -initialPosSecondItem,
            ease: Power2.easeOut,
          },
          '-=0.2',
        )
        .from(
          imageRef.current,
          0.4,
          {
            autoAlpha: 0,
            x: initialPosThirdItem,
            ease: Power2.easeOut,
          },
          '-=0.4',
        );
    }

    return () => {
      if (animationRef.current) {
        animationRef.current.kill();
      }
    };
  }, [isAnimated, dispatch]);

  useEffect(() => {
    if (launchAnimation && isAnimated) {
      dispatch({ type: 'visible' });
      animationRef.current.restart();
    }
  }, [isAnimated, launchAnimation, dispatch]);

  const portfolioContent = (
    <>
      <div className="portfolio-content">
        <p ref={titleRef} className="section-title">
          {title}
        </p>
        <div ref={descriptionRef} className="portfolio-description">
          <p className="section-text">{firstDescription}</p>
          <p className="section-text">{secondDescription}</p>
        </div>
      </div>
      <div ref={imageRef} className="portfolio-image">
        <img src={pathImg} alt={altImg} />
      </div>
    </>
  );

  if (isDeviceLessWiderThanLgWidth) {
    return (
      <section className={wrapperClasses.join(' ')}>{portfolioContent}</section>
    );
  }

  return (
    <InView
      {...OBSERVER_OPTIONS}
      as="section"
      onChange={(inView) => {
        if (inView) {
          setLaunchAnimation(true);
        }
      }}
      className={wrapperClasses.join(' ')}
    >
      {portfolioContent}
    </InView>
  );
};

export default Portfolio;
