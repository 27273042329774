import { useReducer } from 'react';

const classesReducer = (state, action) => {
  switch (action.type) {
    case 'visible':
      return [...state, 'visible'];
    case 'invisible':
      return [...state.filter((state) => state !== 'visible')];
    case 'withModal':
      return [...state, 'with-modal'];
    case 'withoutModal':
      return [...state.filter((state) => state !== 'with-modal')];
    default:
      return state;
  }
};

const useWrapperClasses = (classes = ['']) => {
  const [classesState, dispatch] = useReducer(classesReducer, classes);

  return [classesState, dispatch];
};

export default useWrapperClasses;
