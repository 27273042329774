import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

/* Components */
import Header from './header';
import Footer from './Footer';
import ShapesBg from './ShapesBg';

/* Hooks */
import useWindowMeasures from '../hooks/useWindowMeasures';

/* Utils */
import { xlWidth, xxlWidth } from '../../utils/measures';

const Layout = ({
  shouldTriggerEntryAnimation,
  children,
  location: { pathname },
}) => {
  const { width } = useWindowMeasures();
  const isShapesBgDisplayed = width >= xxlWidth;

  return (
    <Fragment>
      <div className="layout">
        <Header
          shouldTriggerEntryAnimation={shouldTriggerEntryAnimation}
          currentPath={pathname}
        />
        {children}
        <Footer isFooterShapeDisplayed={isShapesBgDisplayed} />
      </div>
      {isShapesBgDisplayed && (
        <ShapesBg
          shouldTriggerEntryAnimation={shouldTriggerEntryAnimation}
          currentPath={pathname}
        />
      )}
    </Fragment>
  );
};

Layout.propTypes = {
  shouldTriggerEntryAnimation: PropTypes.bool,
  children: PropTypes.element,
  location: PropTypes.object,
};

export default withRouter(Layout);
