import 'latest-createjs/lib/easeljs/easeljs';

class CanvasBase {
  constructor(canvas, canvasw, canvash) {
    this.canvas = canvas;
    this.canvasw = canvasw;
    this.canvash = canvash;

    this.setCanvasAndContext();
    this.setStageAndContainer();
  }

  setCanvasAndContext() {
    this.canvas.width = this.canvasw;
    this.canvas.height = this.canvash;

    this.context = this.canvas.getContext('2d');
  }

  setStageAndContainer() {
    this.stage = new createjs.Stage(this.canvas);

    createjs.Ticker.interval = 30;
    createjs.Ticker.framerate = 60;
    createjs.Ticker.addEventListener('tick', this.stage);

    this.container = new createjs.Container();
    this.stage.addChild(this.container);
  }
}

export default CanvasBase;
