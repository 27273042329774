import React, { useRef, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

/* Components */
import Logo from './Logo';
import Menu from './menu';

/* Hooks */
import useWindowMeasures from '../../hooks/useWindowMeasures';
import useLockedBody from '../../hooks/useLockedBody';
import useWrapperClasses from '../../hooks/useWrapperClasses';

/* Utils */
import { mdWidth, xxlWidth } from '../../../utils/measures';

const Header = ({ shouldTriggerEntryAnimation, currentPath }) => {
  const headerRef = useRef(null);
  const entryAnimation = useRef(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { width } = useWindowMeasures();
  const hasMenuMobile = width < mdWidth;
  const hasHeaderShape = width >= xxlWidth;

  const { updateLocked } = useLockedBody();

  const [wrapperClasses, dispatch] = useWrapperClasses(['pageHeader']);

  useEffect(() => {
    if (!shouldTriggerEntryAnimation) return;

    dispatch({ type: 'visible' });

    entryAnimation.current = TweenMax.from(
      headerRef.current,
      0.5,
      {
        y: -120,
        ease: Power2.easeOut,
      },
      '+=0',
    );

    return () => {
      if (entryAnimation.current) {
        entryAnimation.current.kill();
      }
    };
  }, [shouldTriggerEntryAnimation, dispatch]);

  useEffect(() => {
    updateLocked(hasMenuMobile && isMenuOpen);
  }, [hasMenuMobile, isMenuOpen]);

  const shouldDisplayMenu = !hasMenuMobile || (hasMenuMobile && isMenuOpen);
  const shouldDisplayLogo = !hasMenuMobile || (hasMenuMobile && !isMenuOpen);

  const menuHandler = () => {
    if (hasMenuMobile) {
      setIsMenuOpen((isOpen) => !isOpen);
    }
  };

  return (
    <header ref={headerRef} className={wrapperClasses.join(' ')}>
      <div className="pageHeader-content">
        {shouldDisplayLogo && <Logo hasHeaderShape={hasHeaderShape} />}
        {shouldDisplayMenu && (
          <Menu onClick={menuHandler} currentPath={currentPath} />
        )}
        <button
          className={`pageHeader-mobileMenuButton ${isMenuOpen ? 'open' : ''}`}
          onClick={menuHandler}
        >
          <span />
          <span />
          <span />
          <span />
        </button>
      </div>
    </header>
  );
};

Header.propTypes = {
  shouldTriggerEntryAnimation: PropTypes.bool,
  currentPath: PropTypes.string,
};

export default Header;
