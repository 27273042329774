import CanvasBase from '../canvasBase';
import { getRandom, getRandomInt } from '../../random';
import { grey, orange, darkCoral, smoothGrey, darkWhite } from '../../colors';
import {
  heightBubblesCanvas,
  offsetHeightBubbles,
  totalBubbles,
} from '../../measures';
import Bubble from './bubble';

class BubblesAnim extends CanvasBase {
  constructor(canvas, canvasw, canvash, direction) {
    super(canvas, canvasw, canvash, direction);
    this.direction = direction;
    this.objPosY = {
      begin: 0,
      end: 0,
    };
    this.tl = null;

    this.setDirection();
    this.setBubbles();
    this.setTimeline();
  }

  setDirection() {
    if (this.direction === 'up') {
      this.objPosY.begin = heightBubblesCanvas;
      this.objPosY.end = -(heightBubblesCanvas + offsetHeightBubbles);
    } else {
      this.objPosY.begin = -offsetHeightBubbles;
      this.objPosY.end = heightBubblesCanvas + offsetHeightBubbles;
    }
  }

  setBubbles() {
    this.arrayColors = [grey, orange, darkCoral, smoothGrey, darkWhite];
    this.arrayBubbles = [];

    for (let i = 1; i < totalBubbles; i += 1) {
      const bubble = new Bubble(
        this.arrayColors[getRandomInt(0, 4)],
        getRandom(2, 10),
        getRandom(30, 80),
        this.objPosY.begin,
        0,
      );
      this.container.addChild(bubble.getBubble());
      this.arrayBubbles.push(bubble.getBubble());
    }
  }

  setTimeline() {
    this.tl = new TimelineMax({
      repeat: -1,
      yoyo: true,
    });

    this.tl.staggerTo(
      this.arrayBubbles,
      5.5,
      {
        bezier: {
          type: 'Cubic',
          values: [
            { x: getRandom(-10, 0), alpha: 0 },
            { x: getRandom(0, 5), alpha: getRandom(0, 0.1) },
            { x: getRandom(5, 10), alpha: getRandom(0.2, 0.3) },
            { x: getRandom(10, 15), alpha: getRandom(0.4, 0.6) },
            { x: getRandom(5, 10), alpha: getRandom(0.6, 0.8) },
            { x: getRandom(0, 5), alpha: getRandom(0.7, 0.9) },
            { x: getRandom(0, -5), alpha: getRandom(0.6, 0.8) },
            { x: getRandom(-5, -10), alpha: getRandom(0.7, 0.9) },
            { x: getRandom(-10, -15), alpha: getRandom(0.6, 0.8) },
            { x: getRandom(-5, -10), alpha: getRandom(0.4, 0.6) },
            { x: getRandom(-5, 0), alpha: getRandom(0.1, 0.2) },
            { x: getRandom(0, 5), alpha: 0 },
            { x: getRandom(0, 10), alpha: 0 },
          ],
        },
        y: this.objPosY.end,
        ease: Power1.easeInOut,
      },
      0.09,
    );
  }

  destroy() {
    this.tl.kill();
    this.stage.removeChild(this.container);
  }
}

export default BubblesAnim;
