export const setZeroScrollTop = () => {
  window.scrollTo(0, 0);
};

export const setMaskedBody = (isMasked) => {
  const classesListMethod = isMasked ? 'add' : 'remove';
  document.body.classList[classesListMethod]('masked');
};

export const getClassesList = (classesList, isDevice = false) => {
  const classesListUpdated = isDevice
    ? [...classesList, 'visible']
    : [...classesList];

  return classesListUpdated;
};

export const buildCombinedList = ({ initialList, addedList, addedItemName }) =>
  initialList.map((item, index) => ({
    ...item,
    [addedItemName]: addedList[index],
  }));

export const copyToClipboard = (text) =>
  new Promise((resolve, reject) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      resolve();
    } else {
      reject((err) => {
        console.error('Failed to copy text: ', err);
      });
    }
  });

export const generateDateToDisplay = (dateStr) => {
  const date = new Date(dateStr);
  const options = { year: 'numeric', month: 'long' };
  return date.toLocaleDateString('en-US', options);
};

export const getPalindrome = (colors) => [
  ...colors,
  ...colors.slice(1, colors.length - 1).reverse(),
];

export const OBSERVER_OPTIONS = {
  root: null,
  rootMargin: '0%',
  threshold: 0.99,
};
