export const darkCoral = '#d35d4e';
export const orange = '#ef5000';
export const lightWhite = '#FFFFFF';
export const white = '#F3F3F3';
export const darkWhite = '#e5e4e9';
export const smoothGrey = '#E5E4E9';
export const lightGrey = '#abaaaa';
export const mediumGrey = '#707070';
export const grey = '#5E5E5E';
export const darkGrey = '#545353';
export const smoothBlack = '#32302f';
export const black = '#221f1e';
