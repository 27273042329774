import React from 'react';
import PropTypes from 'prop-types';

/* UI */
import RouterLink from '../../../ui/RouterLink';

const Item = ({ txt, iconComponent, link, onClick, currentPath }) => (
  <li className="pageHeader-menu-item">
    <RouterLink
      link={link}
      iconComponent={iconComponent}
      txt={txt}
      onClick={onClick}
      checkActive={() => currentPath === link}
    />
  </li>
);

Item.propTypes = {
  txt: PropTypes.string,
  iconComponent: PropTypes.any,
  onClick: PropTypes.func,
  url: PropTypes.string,
  currentPath: PropTypes.string,
};

export default Item;
