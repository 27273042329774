import React, { useRef, useEffect } from 'react';

/* Utils */
import {
  white,
  grey,
  lightGrey,
  smoothGrey,
  orange,
  smoothBlack,
  darkGrey,
} from '../../../utils/colors';

const HighlightImage = ({ isAnimated }) => {
  const svgRef = useRef(null);
  const circleBgRef = useRef(null);
  const laptopRef = useRef(null);
  const tabletRef = useRef(null);
  const mobileRef = useRef(null);
  const wifyRef = useRef(null);
  const wifyTopRef = useRef(null);
  const wifyBottomRef = useRef(null);
  const animation = useRef(null);
  const wifyAnimation = useRef(null);

  useEffect(() => {
    if (!isAnimated) return;

    animation.current = new TimelineMax({
      onComplete: () => {
        wifyAnimation.current = new TimelineMax({
          repeat: -1,
          repeatDelay: 0.2,
          yoyo: true,
        });

        wifyAnimation.current
          .to(wifyTopRef.current, 0.5, { autoAlpha: 0 }, '+=0')
          .to(wifyBottomRef.current, 0.5, { autoAlpha: 0 }, '+=0');
      },
    });

    animation.current
      .timeScale(0.8)
      .set(svgRef.current, { autoAlpha: 1 })
      .from(
        circleBgRef.current,
        0.4,
        {
          autoAlpha: 0,
          scale: 0.75,
          transformOrigin: '50% 50%',
          ease: Power2.easeOut,
        },
        '+=0',
      )
      .from(
        laptopRef.current,
        0.5,
        {
          autoAlpha: 0,
          y: -30,
          ease: Power3.easeInOut,
        },
        '-=0.4',
      )
      .from(
        tabletRef.current,
        0.5,
        {
          autoAlpha: 0,
          y: -20,
          ease: Power3.easeInOut,
        },
        '-=0.4',
      )
      .from(
        mobileRef.current,
        0.5,
        {
          autoAlpha: 0,
          y: -10,
          ease: Power3.easeInOut,
        },
        '-=0.4',
      )
      .from(
        wifyRef.current,
        0.5,
        {
          autoAlpha: 0,
          ease: Power2.easeOut,
        },
        '-=.4',
      );

    return () => {
      if (animation.current) {
        animation.current.kill();
      }
      if (wifyAnimation.current) {
        wifyAnimation.current.kill();
      }
    };
  }, [isAnimated]);

  return (
    <div className="highlight-image">
      <svg
        ref={svgRef}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="-269 113 420 336"
        enableBackground="new -269 113 420 336"
        xmlSpace="preserve"
      >
        <path
          ref={circleBgRef}
          fill={grey}
          d="M109,281c0,92.8-75.2,168-168,168s-168-75.2-168-168s75.2-168,168-168S109,188.2,109,281z"
        />
        <g ref={laptopRef}>
          <path
            fill={smoothBlack}
            d="M-233.4,164.4H78.1c10.2,0,18.6,8.4,18.6,18.6v191.9h-348.8V183C-252.1,172.8-243.7,164.4-233.4,164.4z"
          />
          <path fill={white} d="M-239.4,202.8v159.5h0l0,0H84.1V202.8" />
          <path fill={lightGrey} d="M-232.7,208.6H77.4v71.8h-310.1V208.6z" />
          <path
            fill={lightGrey}
            d="M-269,374.9h382.7v10.7c0,5.9-4.8,10.7-10.7,10.7h-361.2c-5.9,0-10.7-4.8-10.7-10.7L-269,374.9L-269,374.9z"
          />
          <path
            fill={smoothBlack}
            d="M-115.8,378.9h76.4l-8.2,5.4h-60L-115.8,378.9L-115.8,378.9z"
          />
          <path
            fill={orange}
            d="M84.1,202.6h-323.5v-17.9c0-4.2,2.7-7.6,6-7.6H78.1c3.3,0,6,3.5,6,7.6L84.1,202.6L84.1,202.6L84.1,202.6z"
          />
          <g ref={wifyRef}>
            <circle fill={white} cx="70.6" cy="192.9" r="1.5" />
            <path
              ref={wifyTopRef}
              fill={white}
              d="M76.8,187.4l-0.3-0.3l0,0l-0.2-0.2l-0.1-0.1c-3.2-2.8-8-2.7-11,0.3l0,0l-0.6,0.6
			c-0.3,0.3-0.3,0.9,0,1.2c0.3,0.3,0.9,0.3,1.2,0l0.6-0.6l0,0c2.5-2.4,6.4-2.4,8.9,0l0.4,0.4c0.3,0.3,0.9,0.3,1.2,0
			C77.1,188.3,77.1,187.7,76.8,187.4z"
            />
            <path
              ref={wifyBottomRef}
              fill={white}
              d="M74.2,189.2L74.2,189.2l-0.1-0.1l0,0c-1.9-1.8-4.9-1.7-6.8,0.2l-0.2,0.2l-0.3,0.3
			c-0.4,0.4-0.4,1,0,1.3c0.4,0.3,1,0.4,1.3,0l0.5-0.5c1.2-1.2,3.1-1.2,4.3,0l0.4,0.4c0.4,0.4,1,0.4,1.3,0c0.2-0.2,0.3-0.4,0.3-0.7
			c0-0.2-0.1-0.5-0.3-0.7L74.2,189.2z"
            />
          </g>
          <path
            fill={white}
            d="M-113.3,185.8H52.4c1.2,0,2.2,1,2.2,2.2v4.1c0,1.2-1,2.2-2.2,2.2h-165.7c-1.2,0-2.2-1-2.2-2.2V188
		C-115.6,186.8-114.6,185.8-113.3,185.8z"
          />
          <g>
            <path
              fill={white}
              d="M-225.7,184.6c2.8,0,5,2.2,5,5s-2.2,5-5,5c-2.8,0-5-2.2-5-5S-228.4,184.6-225.7,184.6z"
            />
            <path
              fill={white}
              d="M-205.9,184.6c2.8,0,5,2.2,5,5s-2.2,5-5,5c-2.8,0-5-2.2-5-5S-208.6,184.6-205.9,184.6z"
            />
            <path
              fill={white}
              d="M-186.1,184.6c2.8,0,5,2.2,5,5s-2.2,5-5,5c-2.8,0-5-2.2-5-5S-188.8,184.6-186.1,184.6z"
            />
          </g>
          <g>
            <path
              fill={grey}
              d="M-198.3,291.7c8,0,14.5,6.5,14.5,14.5c0,8-6.5,14.5-14.5,14.5c-8,0-14.5-6.5-14.5-14.5
		C-212.8,298.2-206.3,291.7-198.3,291.7z"
            />
            <path
              fill={grey}
              d="M-123.6,291.7c8,0,14.5,6.5,14.5,14.5c0,8-6.5,14.5-14.5,14.5s-14.5-6.5-14.5-14.5
		C-138,298.2-131.5,291.7-123.6,291.7z"
            />
            <path
              fill={grey}
              d="M-44.2,291.7c8,0,14.5,6.5,14.5,14.5c0,8-6.5,14.5-14.5,14.5s-14.5-6.5-14.5-14.5
		C-58.7,298.2-52.2,291.7-44.2,291.7z"
            />
            <path
              fill={lightGrey}
              d="M-219.1,326.9h41.5v2.3h-41.5L-219.1,326.9L-219.1,326.9z M-219.1,341h27.9v2.3h-27.9V341L-219.1,341z
			 M-219.1,333.9h41.5v2.3h-41.5L-219.1,333.9L-219.1,333.9z"
            />
            <path
              fill={lightGrey}
              d="M-144.3,326.9h41.5v2.3h-41.5V326.9z M-144.3,341h27.9v2.3h-27.9V341z M-144.3,333.9h41.5v2.3h-41.5V333.9z"
            />
          </g>
          <path
            fill={lightGrey}
            d="M-65,326.9h41.5v2.3H-65L-65,326.9L-65,326.9z M-65,341h27.9v2.3H-65L-65,341L-65,341z M-65,333.9h41.5v2.3
		H-65L-65,333.9L-65,333.9z"
          />
          <path
            fill={darkGrey}
            d="M-181.1,396.4L63,396.5c-1.4,1.6-4.5,4.6-5.9,6h-232.2C-176.6,400.9-178.6,399.2-181.1,396.4z"
          />
        </g>
        <g ref={tabletRef}>
          <path
            fill={smoothBlack}
            d="M4.9,232h114.4c3.6,0,6.6,2.9,6.6,6.6v151.3c0,3.6-2.9,6.6-6.6,6.6H4.9c-3.6,0-6.6-2.9-6.6-6.6V238.5
		C-1.7,234.9,1.3,232,4.9,232z"
          />
          <path
            fill={white}
            d="M6.3,243.6h111.6c0.4,0,0.8,0.3,0.8,0.8v135.9c0,0.4-0.3,0.8-0.8,0.8H6.3c-0.4,0-0.8-0.3-0.8-0.8V244.4
		C5.6,243.9,5.9,243.6,6.3,243.6z"
          />
          <circle fill={smoothGrey} cx="62.1" cy="388.5" r="4.3" />
          <path fill={lightGrey} d="M11.5,260.6h101.2v59.7H11.5V260.6z" />
          <path
            fill={grey}
            d="M29.7,328.9c5,0,9,4,9,9s-4,9-9,9c-5,0-9-4-9-9C20.7,332.9,24.7,328.9,29.7,328.9z"
          />
          <path
            fill={lightGrey}
            d="M16.8,350.7h25.8v1.8H16.8V350.7z M16.8,362.1h17.3v1.8H16.8V362.1z M16.8,356.4h25.8v1.8H16.8V356.4z"
          />
          <path
            fill={grey}
            d="M66.7,328.9c5,0,9,4,9,9s-4,9-9,9s-9-4-9-9C57.7,332.9,61.8,328.9,66.7,328.9z"
          />
          <path
            fill={lightGrey}
            d="M53.8,350.7h25.8v1.8H53.8V350.7z M53.8,362.1h17.3v1.8H53.8V362.1z M53.8,356.4h25.8v1.8H53.8V356.4z"
          />
          <path
            fill={orange}
            d="M118.7,256.7H5.6v-12.3c0-0.4,0.3-0.8,0.8-0.8h111.6c0.4,0,0.8,0.3,0.8,0.8L118.7,256.7L118.7,256.7z"
          />
          <g>
            <path
              fill={white}
              d="M13.9,247.3c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9
			C11,248.6,12.3,247.3,13.9,247.3z"
            />
            <path
              fill={white}
              d="M22.2,247.3c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9
			C19.3,248.6,20.6,247.3,22.2,247.3z"
            />
            <path
              fill={white}
              d="M30.5,247.3c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9
			C27.6,248.6,28.9,247.3,30.5,247.3z"
            />
          </g>
          <path
            fill={white}
            d="M47,248h67.3c0.5,0,0.9,0.5,0.9,1.2v2.2c0,0.7-0.4,1.2-0.9,1.2H47c-0.5,0-0.9-0.5-0.9-1.2v-2.2
		C46.1,248.5,46.5,248,47,248z"
          />
          <path
            fill={smoothGrey}
            d="M47.8,236.6h28.6c0.4,0,0.7,0.3,0.7,0.7v0c0,0.4-0.3,0.7-0.7,0.7H47.8c-0.4,0-0.7-0.3-0.7-0.7v0
		C47.1,237,47.4,236.6,47.8,236.6z"
          />
        </g>
        <g ref={mobileRef}>
          <path
            fill={smoothBlack}
            d="M94.7,290.9h53c1.8,0,3.3,1.5,3.3,3.3v98.9c0,1.8-1.5,3.3-3.3,3.3h-53c-1.8,0-3.3-1.5-3.3-3.3v-98.9
		C91.4,292.4,92.8,290.9,94.7,290.9z"
          />
          <path
            fill={white}
            d="M97.2,300.8h48.1c0.2,0,0.3,0.1,0.3,0.3v85.2c0,0.2-0.1,0.3-0.3,0.3H97.2c-0.2,0-0.3-0.1-0.3-0.3v-85.2
		C96.8,300.9,97,300.8,97.2,300.8z"
          />
          <g>
            <circle fill={smoothGrey} cx="121.2" cy="391.1" r="3" />
            <path
              fill={smoothGrey}
              d="M113.1,296.3h16.1c0.4,0,0.7,0.3,0.7,0.7v0c0,0.4-0.3,0.7-0.7,0.7h-16.1c-0.4,0-0.7-0.3-0.7-0.7v0
			C112.4,296.7,112.7,296.3,113.1,296.3z"
            />
          </g>
          <path
            fill={orange}
            d="M145.6,306.5H96.8v-5.4c0-0.2,0.1-0.3,0.3-0.3h48.1c0.2,0,0.3,0.1,0.3,0.3L145.6,306.5L145.6,306.5z"
          />
          <path
            fill={white}
            d="M100.3,301.8c0.9,0,1.7,0.8,1.7,1.7c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7
		C98.6,302.5,99.4,301.8,100.3,301.8z"
          />
          <g>
            <path
              fill={white}
              d="M105,301.8c0.9,0,1.7,0.8,1.7,1.7c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7
			C103.3,302.5,104.1,301.8,105,301.8z"
            />
            <path
              fill={white}
              d="M109.8,301.8c0.9,0,1.7,0.8,1.7,1.7c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7
			C108.1,302.5,108.8,301.8,109.8,301.8z"
            />
          </g>
          <path
            fill={white}
            d="M116.6,302h27.1c0.4,0,0.7,0.3,0.7,0.7v1.8c0,0.4-0.3,0.7-0.7,0.7h-27.1c-0.4,0-0.7-0.3-0.7-0.7v-1.8
		C115.9,302.3,116.2,302,116.6,302z"
          />
          <path
            fill={lightGrey}
            d="M99.2,308.3h44v25.9h-44L99.2,308.3L99.2,308.3z"
          />
          <path
            fill={grey}
            d="M121.5,340.6c4.5,0,8.2,3.7,8.2,8.2c0,4.5-3.7,8.2-8.2,8.2s-8.2-3.7-8.2-8.2
		C113.3,344.2,117,340.6,121.5,340.6z"
          />
          <path
            fill={lightGrey}
            d="M107.3,361.9h28.3v2h-28.3V361.9z M107.3,374.5h19v2h-19V374.5z M107.3,368.2h28.3v2h-28.3V368.2z"
          />
        </g>
      </svg>
    </div>
  );
};

export default HighlightImage;
