import React, { useRef, useState, useEffect } from 'react';
import { InView } from 'react-intersection-observer';

/* UI */
import Button from '../../../ui/Button';
import Link from '../../../ui/Link';

/* Icons */
import CopyIcon from '../../../ui/icons/CopyIcon';
import LinkedinIcon from '../../../ui/icons/LinkedinIcon';
import GithubIcon from '../../../ui/icons/GithubIcon';
import FacebookIcon from '../../../ui/icons/FacebookIcon';
import XIcon from '../../../ui/icons/XIcon';
import SkypeIcon from '../../../ui/icons/SkypeIcon';
import EmailIcon from '../../../ui/icons/EmailIcon';

/* Components */
import ConnectImage from './ConnectImage';

/* Hooks */
import useWrapperClasses from '../../../hooks/useWrapperClasses';

/* Utils */
import {
  buildCombinedList,
  copyToClipboard,
  OBSERVER_OPTIONS,
} from '../../../../utils';
import { about } from '../../../../utils/literals';
import {
  initialPosFirstItem,
  initialPosSecondItem,
} from '../../../../utils/animations/positions';

const {
  connect: {
    title,
    email: { firstEmailTxt, secondEmailTxt, thirdEmailTxt, emailTxtToCopy },
    linkList,
  },
} = about;

const linksListWithIcon = buildCombinedList({
  initialList: linkList,
  addedList: [
    GithubIcon,
    LinkedinIcon,
    FacebookIcon,
    XIcon,
    SkypeIcon,
    EmailIcon,
  ],
  addedItemName: 'iconComponent',
});

const Connect = ({ isAnimated, isDeviceLessWiderThanLgWidth }) => {
  const initialWrapperClasses = isAnimated
    ? ['connect']
    : ['connect', 'visible'];
  const [wrapperClasses, dispatch] = useWrapperClasses(initialWrapperClasses);

  const titleRef = useRef(null);
  const contentRef = useRef(null);
  const animationRef = useRef(null);

  const [shouldLaunchAnimation, setShouldLaunchAnimation] = useState(false);
  const [isEmailCopied, setIsEmailCopied] = useState(false);

  useEffect(() => {
    if (!isAnimated || !shouldLaunchAnimation) return;

    dispatch({ type: 'visible' });

    animationRef.current = new TimelineMax();
    animationRef.current
      .from(
        titleRef.current,
        0.4,
        {
          autoAlpha: 0,
          y: initialPosFirstItem,
          ease: Power1.easeOut,
        },
        '+=0',
      )
      .from(
        contentRef.current,
        0.4,
        {
          autoAlpha: 0,
          y: initialPosSecondItem,
          ease: Power1.easeOut,
        },
        '-=0.2',
      );

    return () => {
      if (animationRef.current) {
        animationRef.current.kill();
      }
    };
  }, [isAnimated, shouldLaunchAnimation, dispatch]);

  const copyEmail = () => {
    copyToClipboard(emailTxtToCopy)
      .then(() => setIsEmailCopied(true))
      .catch((error) => alert(error));
  };

  const connectContent = (
    <>
      <ConnectImage
        isAnimated={isAnimated}
        shouldLaunchAnimation={shouldLaunchAnimation}
      />
      <div className="connect-content">
        <p ref={titleRef} className="section-title">
          {title}
        </p>
        <div ref={contentRef} className="connect-copy">
          <p className="section-text">
            {firstEmailTxt}
            <Button type="primary" onClick={copyEmail}>
              <CopyIcon isCheckShown={isEmailCopied} />
              {secondEmailTxt}
            </Button>
            {thirdEmailTxt}
          </p>
          <div className="connect-links">
            {linksListWithIcon.map((item) => (
              <Link
                key={item.txt}
                link={item.link}
                txt={item.txt}
                iconComponent={item.iconComponent}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );

  if (isDeviceLessWiderThanLgWidth) {
    return (
      <section className={wrapperClasses.join(' ')}>{connectContent}</section>
    );
  }

  return (
    <InView
      {...OBSERVER_OPTIONS}
      threshold={0.85}
      as="section"
      onChange={(inView) => {
        if (inView) {
          setShouldLaunchAnimation(true);
        }
      }}
      className={wrapperClasses.join(' ')}
      skip={!isAnimated}
      triggerOnce
    >
      {connectContent}
    </InView>
  );
};

export default Connect;
