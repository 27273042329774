import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/* Hooks */
import useWindowMeasures from './hooks/useWindowMeasures';

const IntroShapes = ({ onEndIntroAnimation }) => {
  const firstLeftShapeRef = useRef(null);
  const secondLeftShapeRef = useRef(null);
  const firstRightShapeRef = useRef(null);
  const secondRightShapeRef = useRef(null);
  const animationRef = useRef(null);

  const { width, height } = useWindowMeasures();

  useEffect(() => {
    animationRef.current = new TimelineMax({ onComplete: onEndIntroAnimation });
    animationRef.current
      .to(
        firstLeftShapeRef.current,
        0.5,
        {
          x: -width / 2,
          y: -height / 2,
          ease: Power1.easeOut,
        },
        '+=.5',
      )
      .to(
        secondRightShapeRef.current,
        0.5,
        {
          x: -width / 2,
          y: height / 2,
          ease: Power1.easeOut,
        },
        '-=0.5',
      )
      .to(
        secondLeftShapeRef.current,
        0.5,
        {
          x: width / 2,
          ease: Power2.easeOut,
        },
        '-=0',
      )
      .to(
        firstRightShapeRef.current,
        0.5,
        {
          x: -width / 2,
          ease: Power2.easeOut,
        },
        '-=0.5',
      )
      .to(
        firstLeftShapeRef.current,
        0.5,
        {
          rotation: -15,
          ease: Power3.easeInOut,
          transformOrigin: '35.2% 100%',
        },
        '+=0',
      )
      .to(
        secondLeftShapeRef.current,
        0.5,
        {
          rotation: -15,
          ease: Power3.easeInOut,
          transformOrigin: '0 100%',
        },
        '-=0.5',
      )
      .to(
        firstRightShapeRef.current,
        0.5,
        {
          rotation: -15,
          ease: Power3.easeInOut,
          transformOrigin: '100% 0',
        },
        '-=0.5',
      )
      .to(
        secondRightShapeRef.current,
        0.5,
        {
          rotation: -15,
          ease: Power3.easeInOut,
          transformOrigin: '58% 0',
        },
        '-=0.5',
      )
      .to(
        firstLeftShapeRef.current,
        0.5,
        {
          x: '-15%',
          y: '-150%',
          ease: Power3.easeIn,
        },
        '-=0.5',
      )
      .to(
        secondRightShapeRef.current,
        0.5,
        {
          x: '15%',
          y: '150%',
          ease: Power3.easeIn,
        },
        '-=0.5',
      )
      .to(
        secondLeftShapeRef.current,
        0.5,
        {
          x: '75%',
          y: '-100%',
          ease: Power3.easeIn,
        },
        '-=0.4',
      )
      .to(
        firstRightShapeRef.current,
        0.5,
        {
          x: '-80%',
          y: '150%',
          ease: Power3.easeIn,
        },
        '-=0.5',
      );

    return () => {
      if (animationRef.current) {
        animationRef.current.kill();
      }
    };
  }, []);

  return (
    <div className="introShapes">
      <div className="introShapes-firstLeftShape" ref={firstLeftShapeRef} />
      <div className="introShapes-secondLeftShape" ref={secondLeftShapeRef} />
      <div className="introShapes-firstRightShape" ref={firstRightShapeRef} />
      <div className="introShapes-secondRightShape" ref={secondRightShapeRef} />
    </div>
  );
};

IntroShapes.propTypes = {
  onEndIntroAnimation: PropTypes.func,
};

export default IntroShapes;
