import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const RouterLink = ({ link, iconComponent, txt, checkActive, onClick }) => (
  <NavLink
    to={link}
    exact
    className="routerLink"
    activeClassName="active"
    onClick={() => onClick(txt)}
    isActive={checkActive}
  >
    {iconComponent && createElement(iconComponent)}
    {txt}
  </NavLink>
);

RouterLink.propTypes = {
  link: PropTypes.string,
  iconComponent: PropTypes.any,
  txt: PropTypes.string,
  checkActive: PropTypes.func,
  onClick: PropTypes.func,
};
export default RouterLink;
