import React, { useRef, useState, useEffect } from 'react';
import { InView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

/* Hooks */
import useWrapperClasses from '../../hooks/useWrapperClasses';

/* Utils */
import { OBSERVER_OPTIONS } from '../../../utils';
import { initialPosSecondItem } from '../../../utils/animations/positions';

const Skill = ({
  src,
  altImg,
  name,
  description,
  isAnimated,
  isDeviceLessWiderThanLgWidth,
  withDelay,
}) => {
  const initialWrapperClasses = isAnimated
    ? ['technicalSkill']
    : ['technicalSkill', 'visible'];
  const [wrapperClasses, dispatch] = useWrapperClasses(initialWrapperClasses);

  const skillRef = useRef(null);
  const animationRef = useRef(null);

  const [shouldLaunchAnimation, setShouldLaunchAnimation] = useState(false);

  useEffect(() => {
    if (!isAnimated || !shouldLaunchAnimation) return;

    dispatch({ type: 'visible' });

    animationRef.current = TweenMax.from(skillRef.current, 0.4, {
      y: initialPosSecondItem,
      autoAlpha: 0,
      delay: withDelay ? 0.2 : 0,
      ease: Power1.easeOut,
    });

    return () => {
      if (animationRef.current) {
        animationRef.current.kill();
      }
    };
  }, [isAnimated, shouldLaunchAnimation, dispatch]);

  const technicalSkillContent = (
    <div ref={skillRef} className="technicalSkill-content">
      <img className="technicalSkill-image" src={src} alt={altImg} />
      <p className="section-subtitle">{name}</p>
      <p className="section-text">{description}</p>
    </div>
  );

  if (isDeviceLessWiderThanLgWidth) {
    return (
      <div className={wrapperClasses.join(' ')}>{technicalSkillContent}</div>
    );
  }

  return (
    <InView
      {...OBSERVER_OPTIONS}
      as="article"
      onChange={(inView) => {
        if (inView) {
          setShouldLaunchAnimation(true);
        }
      }}
      className={wrapperClasses.join(' ')}
      skip={!isAnimated}
      triggerOnce
    >
      {technicalSkillContent}
    </InView>
  );
};

Skill.propTypes = {
  src: PropTypes.string,
  altImg: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
};

export default Skill;
