import React from 'react';

/* Utils */
import { white } from '../../../utils/colors';

const SideProjectIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="42px"
    height="42px"
    viewBox="0 0 42 42"
    enableBackground="new 0 0 42 42"
    xmlSpace="preserve"
  >
    <path
      fill={white}
      d="M31.046,12.518H10.954c-0.2,0-0.363,0.163-0.363,0.363v13.436c0,0.2,0.163,0.363,0.363,0.363h20.092
      c0.2,0,0.362-0.163,0.362-0.363V12.881C31.408,12.681,31.246,12.518,31.046,12.518z"
    />
    <path
      fill={white}
      d="M21,0C9.402,0,0,9.402,0,21c0,11.597,9.402,21,21,21c11.597,0,21-9.402,21-21S32.597,0,21,0z M33.951,26.316
      c0,1.604-1.301,2.904-2.905,2.904h-6.601v1.622h3.332c0.602,0,1.09,0.487,1.09,1.089c0,0.603-0.488,1.09-1.09,1.09H14.223
      c-0.603,0-1.09-0.487-1.09-1.09c0-0.602,0.487-1.089,1.09-1.089h3.332v-1.622h-6.601c-1.604,0-2.905-1.301-2.905-2.904V12.881
      c0-1.604,1.301-2.904,2.905-2.904h20.092c1.604,0,2.905,1.301,2.905,2.904V26.316z"
    />
  </svg>
);

export default SideProjectIcon;
