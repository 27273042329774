import React, { useEffect } from 'react';

/* Hooks */
import useWindowMeasures from '../../hooks/useWindowMeasures';
import useWrapperClasses from '../../hooks/useWrapperClasses';

/* Components */
import Highlight from '../shared/Highlight';
import HighlightImage from './HighlightImage';
import TalentsPercent from './TalentsPercent';
import SoftSkills from './SoftSkills';
import TechnicalSkills from './TechnicalSkills';

/* Utils */
import { setZeroScrollTop } from '../../../utils';
import { xlWidth, lgWidth } from '../../../utils/measures';
import { skills } from '../../../utils/literals';

const { highlight } = skills;

const Skills = () => {
  const { width } = useWindowMeasures();
  const isAnimated = width >= xlWidth;
  const isDeviceLessWiderThanLgWidth = width < lgWidth;

  const initialWrapperClasses = isAnimated ? ['skills', 'visible'] : ['skills'];
  const [wrapperClasses, dispatch] = useWrapperClasses(initialWrapperClasses);

  useEffect(() => {
    setZeroScrollTop();
    if (isAnimated) return;
    dispatch({ type: 'visible' });
  }, [isAnimated, dispatch]);

  return (
    <main className={wrapperClasses.join(' ')}>
      <Highlight data={highlight} isAnimated={isAnimated}>
        <HighlightImage isAnimated={isAnimated} />
      </Highlight>
      <TalentsPercent
        isAnimated={isAnimated}
        isDeviceLessWiderThanLgWidth={isDeviceLessWiderThanLgWidth}
      />
      <SoftSkills
        isAnimated={isAnimated}
        isDeviceLessWiderThanLgWidth={isDeviceLessWiderThanLgWidth}
      />
      <TechnicalSkills
        isAnimated={isAnimated}
        isDeviceLessWiderThanLgWidth={isDeviceLessWiderThanLgWidth}
      />
    </main>
  );
};

export default Skills;
