import React, { useState, useRef, useEffect } from 'react';
import { InView } from 'react-intersection-observer';

/* Hooks */
import useWrapperClasses from '../../hooks/useWrapperClasses';

/* Utils */
import { OBSERVER_OPTIONS } from '../../../utils';
import { about } from '../../../utils/literals';
import {
  initialPosFirstItem,
  initialPosSecondItem,
  initialPosThirdItem,
} from '../../../utils/animations/positions';

/* Assets */
import pathImg from '../../../../public/img/me.png';

const {
  introduction: { title, locationTxt, professionalTxt, personalTxt, altImg },
} = about;

const Introduction = ({ isAnimated, isDeviceLessWiderThanLgWidth }) => {
  const initialWrapperClasses = isAnimated
    ? ['introduction']
    : ['introduction', 'visible'];
  const [wrapperClasses, dispatch] = useWrapperClasses(initialWrapperClasses);

  const headingsRef = useRef(null);
  const descriptionRef = useRef(null);
  const imageRef = useRef(null);
  const animationRef = useRef(null);

  const [shouldLaunchAnimation, setShouldLaunchAnimation] = useState(false);

  useEffect(() => {
    if (!isAnimated || !shouldLaunchAnimation) return;

    dispatch({ type: 'visible' });

    animationRef.current = new TimelineMax();
    animationRef.current
      .from(
        headingsRef.current,
        0.4,
        {
          autoAlpha: 0,
          x: initialPosFirstItem,
          ease: Power1.easeOut,
        },
        '+=0',
      )
      .from(
        descriptionRef.current,
        0.4,
        {
          autoAlpha: 0,
          x: 60,
          ease: Power1.easeOut,
        },
        '-=0.3',
      )
      .from(
        imageRef.current,
        0.4,
        {
          autoAlpha: 0,
          x: -initialPosThirdItem,
          ease: Power1.easeOut,
        },
        '-=0.6',
      );

    return () => {
      if (animationRef.current) {
        animationRef.current.kill();
      }
    };
  }, [isAnimated, shouldLaunchAnimation, dispatch]);

  const introductionContent = (
    <>
      <div ref={imageRef} className="introduction-image">
        <img src={pathImg} alt={altImg} />
      </div>
      <div className="introduction-content">
        <header className="introduction-header" ref={headingsRef}>
          <p className="title">{title}</p>
          <h3 className="subtitle">{locationTxt}</h3>
        </header>
        <div className="introduction-description" ref={descriptionRef}>
          <p className="section-text">{professionalTxt}</p>
          <p className="section-text">{personalTxt}</p>
        </div>
      </div>
    </>
  );

  if (isDeviceLessWiderThanLgWidth) {
    return (
      <section className={wrapperClasses.join(' ')}>
        {introductionContent}
      </section>
    );
  }

  return (
    <InView
      {...OBSERVER_OPTIONS}
      rootMargin="-7%"
      as="section"
      onChange={(inView) => {
        if (inView) {
          setShouldLaunchAnimation(true);
        }
      }}
      className={wrapperClasses.join(' ')}
      skip={!isAnimated}
      triggerOnce
    >
      {introductionContent}
    </InView>
  );
};

export default Introduction;
