import React from 'react';

/* Utils */
import { darkCoral } from '../../../utils/colors';

const SkillsIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="24.807px"
    height="21px"
    viewBox="0 0 24.807 21"
    enableBackground="new 0 0 24.807 21"
    xmlSpace="preserve"
  >
    <path
      fill={darkCoral}
      d="M16.329,11.387l-1.199-0.294l-0.26-0.064l-0.073-0.257c-0.133-0.472-0.32-0.927-0.55-1.352l-0.141-0.253l0.166-0.236l0.582-0.818c0.195-0.276,0.165-0.649-0.076-0.891l-0.816-0.814c-0.131-0.129-0.303-0.202-0.487-0.202c-0.126,0-0.249,0.036-0.362,0.103l-0.916,0.555l-0.231,0.139L11.73,6.869c-0.45-0.254-0.938-0.458-1.442-0.6l-0.283-0.08l-0.05-0.289L9.755,4.718C9.699,4.384,9.409,4.142,9.072,4.142H7.919c-0.32,0-0.599,0.215-0.673,0.528L6.927,5.978L6.864,6.232L6.612,6.31C6.157,6.45,5.714,6.64,5.295,6.879L5.058,7.017L4.826,6.873L3.519,6.077C3.411,6.013,3.286,5.979,3.16,5.979c-0.185,0-0.36,0.071-0.492,0.203L1.854,6.994C1.62,7.233,1.589,7.614,1.78,7.887l0.881,1.242l0.162,0.227L2.698,9.604c-0.177,0.346-0.322,0.723-0.436,1.121l-0.075,0.258l-0.262,0.063l-1.397,0.339C0.217,11.462,0,11.737,0,12.06v1.15c0,0.341,0.242,0.628,0.576,0.686l1.34,0.228l0.281,0.048l0.082,0.271c0.128,0.424,0.298,0.83,0.504,1.209l0.127,0.235l-0.14,0.228L1.938,17.48c-0.165,0.27-0.123,0.621,0.104,0.851l0.812,0.814c0.132,0.132,0.307,0.204,0.492,0.204c0.143,0,0.282-0.045,0.401-0.128l1.234-0.874l0.232-0.164l0.25,0.131c0.361,0.192,0.744,0.35,1.139,0.471l0.255,0.075l0.061,0.257l0.331,1.355C7.32,20.782,7.599,21,7.921,21H9.07c0.339,0,0.629-0.242,0.685-0.576l0.208-1.23l0.049-0.287l0.281-0.079c0.446-0.124,0.863-0.287,1.238-0.485l0.252-0.131l0.231,0.163l0.872,0.62c0.119,0.085,0.258,0.127,0.403,0.127c0.185,0,0.359-0.072,0.489-0.202l0.813-0.813c0.227-0.228,0.27-0.577,0.102-0.854l-0.557-0.906l-0.144-0.237l0.142-0.238c0.272-0.459,0.486-0.949,0.638-1.459l0.084-0.273l0.281-0.048l1.144-0.196c0.334-0.058,0.577-0.345,0.577-0.684v-1.149C16.859,11.738,16.643,11.462,16.329,11.387 M8.496,16.492c-2.175,0-3.941-1.767-3.941-3.942c0-2.178,1.766-3.942,3.941-3.942c2.178,0,3.943,1.764,3.943,3.942C12.438,14.726,10.673,16.492,8.496,16.492"
    />
    <path
      fill={darkCoral}
      d="M24.51,4.179l-0.683-0.174L23.68,3.967l-0.041-0.148c-0.073-0.269-0.175-0.532-0.304-0.773l-0.08-0.146l0.097-0.132l0.335-0.462c0.116-0.158,0.099-0.37-0.034-0.508l-0.461-0.471c-0.072-0.074-0.171-0.116-0.275-0.116c-0.072,0-0.145,0.018-0.208,0.056l-0.525,0.311L22.05,1.655l-0.134-0.078c-0.256-0.149-0.531-0.267-0.821-0.351l-0.159-0.048l-0.023-0.165l-0.107-0.676c-0.03-0.19-0.192-0.332-0.389-0.333L19.76,0c-0.181-0.003-0.342,0.12-0.386,0.296L19.183,1.04l-0.036,0.145l-0.146,0.042c-0.26,0.077-0.513,0.183-0.754,0.319L18.006,1.62l-0.132-0.083l-0.633-0.461c-0.062-0.038-0.134-0.06-0.204-0.061c-0.106,0-0.206,0.039-0.283,0.114l-0.467,0.458c-0.136,0.131-0.157,0.35-0.048,0.508l0.496,0.712l0.089,0.13L16.751,3.08c-0.102,0.195-0.187,0.411-0.256,0.635l-0.044,0.146l-0.148,0.034l-0.798,0.183c-0.18,0.044-0.304,0.201-0.307,0.381l-0.007,0.658c-0.002,0.195,0.134,0.359,0.324,0.393l0.761,0.14l0.161,0.029l0.047,0.154c0.068,0.243,0.163,0.475,0.279,0.693l0.07,0.135l-0.082,0.128l-0.481,0.772c-0.099,0.155-0.075,0.355,0.053,0.484l0.457,0.471c0.074,0.075,0.174,0.118,0.28,0.12c0.081,0,0.161-0.025,0.229-0.07l0.708-0.49l0.133-0.094l0.144,0.079c0.203,0.111,0.422,0.202,0.646,0.273l0.144,0.046l0.033,0.146L19.277,9.3c0.039,0.176,0.198,0.302,0.379,0.304l0.656,0.008c0.192,0.001,0.36-0.136,0.394-0.327l0.126-0.698l0.029-0.163l0.161-0.041c0.254-0.072,0.494-0.161,0.707-0.271l0.146-0.075l0.131,0.097l0.495,0.357c0.067,0.05,0.144,0.075,0.227,0.077c0.104,0,0.207-0.042,0.282-0.113l0.469-0.46c0.129-0.128,0.157-0.328,0.062-0.486L23.229,6.99l-0.08-0.135l0.081-0.135c0.157-0.26,0.285-0.538,0.373-0.828l0.049-0.154l0.161-0.028l0.653-0.103c0.19-0.032,0.331-0.193,0.332-0.386l0.008-0.656C24.811,4.385,24.687,4.224,24.51,4.179 M19.999,6.763c-1.095,0-1.985-0.891-1.985-1.987c0-1.095,0.891-1.987,1.985-1.987c1.096,0,1.985,0.893,1.985,1.987C21.984,5.872,21.095,6.763,19.999,6.763"
    />
  </svg>
);

export default SkillsIcon;
