import React from 'react';

/* Utils */
import { darkWhite, darkCoral } from '../../../utils/colors';

const GithubIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="60px"
    height="60px"
    viewBox="0 0 60 60"
    enableBackground="new 0 0 60 60"
    xmlSpace="preserve"
  >
    <circle cx="30" cy="30" r="30" fill={darkCoral} />
    <path
      fill={darkWhite}
      d="M49.346,18.446c-2.019-3.46-4.76-6.201-8.221-8.22c-3.462-2.02-7.24-3.03-11.339-3.03
			c-4.099,0-7.878,1.01-11.338,3.03c-3.462,2.019-6.201,4.759-8.222,8.22c-2.02,3.463-3.03,7.242-3.03,11.339
			c0,4.923,1.436,9.348,4.31,13.281c2.872,3.932,6.583,6.651,11.133,8.162c0.53,0.097,0.921,0.028,1.176-0.207
			c0.255-0.234,0.382-0.529,0.382-0.881c0-0.059-0.005-0.588-0.015-1.589c-0.01-1-0.015-1.872-0.015-2.616l-0.676,0.116
			c-0.431,0.078-0.976,0.113-1.633,0.104c-0.656-0.011-1.337-0.079-2.044-0.207s-1.363-0.421-1.97-0.882
			c-0.608-0.461-1.04-1.064-1.294-1.809l-0.293-0.677c-0.197-0.451-0.505-0.951-0.927-1.5c-0.421-0.549-0.848-0.921-1.279-1.117
			l-0.206-0.147c-0.137-0.098-0.264-0.215-0.383-0.354c-0.117-0.136-0.206-0.273-0.264-0.411c-0.058-0.138-0.01-0.25,0.147-0.339
			c0.157-0.089,0.441-0.131,0.854-0.131l0.588,0.088c0.392,0.078,0.877,0.313,1.456,0.706c0.578,0.392,1.054,0.901,1.426,1.528
			c0.451,0.805,0.995,1.416,1.632,1.839c0.638,0.421,1.28,0.632,1.927,0.632c0.646,0,1.206-0.049,1.677-0.146
			c0.47-0.099,0.911-0.246,1.323-0.44c0.177-1.315,0.657-2.324,1.442-3.031c-1.119-0.118-2.124-0.294-3.016-0.53
			c-0.892-0.234-1.814-0.617-2.765-1.146c-0.951-0.531-1.74-1.188-2.368-1.972c-0.627-0.784-1.142-1.813-1.544-3.088
			s-0.603-2.746-0.603-4.413c0-2.373,0.774-4.393,2.324-6.059c-0.726-1.784-0.657-3.785,0.207-6
			c0.568-0.176,1.412-0.045,2.529,0.396c1.117,0.441,1.936,0.819,2.457,1.133c0.519,0.313,0.937,0.578,1.25,0.793
			c1.824-0.509,3.706-0.764,5.647-0.764c1.94,0,3.823,0.255,5.647,0.764l1.117-0.705c0.766-0.471,1.668-0.903,2.706-1.295
			c1.04-0.392,1.834-0.5,2.384-0.323c0.881,2.216,0.959,4.217,0.233,6c1.549,1.667,2.324,3.687,2.324,6.06
			c0,1.666-0.202,3.141-0.603,4.426c-0.402,1.285-0.922,2.313-1.559,3.089c-0.639,0.774-1.432,1.426-2.383,1.956
			c-0.952,0.529-1.874,0.912-2.766,1.146c-0.893,0.234-1.897,0.412-3.016,0.53c1.02,0.882,1.529,2.274,1.529,4.177v6.207
			c0,0.353,0.123,0.646,0.369,0.881c0.246,0.234,0.631,0.304,1.16,0.205c4.551-1.51,8.262-4.229,11.133-8.162
			c2.873-3.932,4.31-8.357,4.31-13.28C52.375,25.688,51.365,21.909,49.346,18.446z"
    />
  </svg>
);

export default GithubIcon;
