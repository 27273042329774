import React, { useRef, useState, useEffect } from 'react';
import { InView } from 'react-intersection-observer';

/* Recharts */
import { PieChart, Pie, Cell } from 'recharts';

/* Hooks */
import useWindowMeasures from '../../hooks/useWindowMeasures';
import useWrapperClasses from '../../hooks/useWrapperClasses';

/* Utils */
import { OBSERVER_OPTIONS } from '../../../utils';
import { skills } from '../../../utils/literals';
import {
  mediumGrey,
  smoothBlack,
  smoothGrey,
  black,
} from '../../../utils/colors';
import {
  initialPosFirstItem,
  initialPosSecondItem,
} from '../../../utils/animations/positions';

const { talentsPercent } = skills;
const {
  titleDeveloper,
  firstTxtDeveloper,
  secondTxtDeveloper,
  thirdTxtDeveloper,
  fourthTxtDeveloper,
  titleDesigner,
  firstTxtDesigner,
  secondTxtDesigner,
  thirdTxtsigner,
  fourthTxtsigner,
} = talentsPercent;

const txtGraphicColors = [smoothBlack, mediumGrey];
const radian = Math.PI / 180;

const TalentsPercent = ({ isAnimated, isDeviceLessWiderThanLgWidth }) => {
  const initialWrapperClasses = isAnimated
    ? ['talentsPercent']
    : ['talentsPercent', 'visible'];
  const [wrapperClasses, dispatch] = useWrapperClasses(initialWrapperClasses);

  const pieMeasures = {
    width: isAnimated ? 340 : 280,
    height: isAnimated ? 340 : 280,
    cx: isAnimated ? 165 : 135,
    cy: isAnimated ? 165 : 135,
    outerRadius: isAnimated ? 170 : 135,
  };

  const developerTitleRef = useRef(null);
  const developerContentRef = useRef(null);
  const designTitleRef = useRef(null);
  const designContentRef = useRef(null);
  const animationRef = useRef(null);

  const [shouldLaunchAnimation, setShouldLaunchAnimation] = useState(false);
  const [shouldDisplayGraphic, setShouldDisplayGraphic] = useState(!isAnimated);

  useEffect(() => {
    if (!isAnimated || isDeviceLessWiderThanLgWidth || !shouldLaunchAnimation)
      return;

    dispatch({ type: 'visible' });

    animationRef.current = new TimelineMax();
    animationRef.current
      .call(() => setShouldDisplayGraphic(true))
      .from(
        developerTitleRef.current,
        0.4,
        {
          autoAlpha: 0,
          x: -initialPosFirstItem,
          ease: Power1.easeOut,
        },
        '+=0',
      )
      .from(
        developerContentRef.current,
        0.4,
        {
          autoAlpha: 0,
          x: -initialPosSecondItem,
          ease: Power1.easeOut,
        },
        '-=0.3',
      )
      .from(
        designTitleRef.current,
        0.4,
        {
          autoAlpha: 0,
          x: initialPosFirstItem,
          ease: Power1.easeOut,
        },
        '-=0.5',
      )
      .from(
        designContentRef.current,
        0.4,
        {
          autoAlpha: 0,
          x: initialPosSecondItem,
          ease: Power1.easeOut,
        },
        '-=0.3',
      );

    return () => {
      if (animationRef.current) {
        animationRef.current.kill();
      }
    };
  }, [
    isAnimated,
    isDeviceLessWiderThanLgWidth,
    shouldLaunchAnimation,
    dispatch,
  ]);

  const graphicData = [
    { name: titleDeveloper, value: 600 },
    { name: titleDesigner, value: 400 },
  ];

  const getPieLabelSettings = (isCoderFragmentPie) => {
    const pieLabelDefaultSettings = {
      textContent: isCoderFragmentPie ? 'Coder' : 'Designer',
      fontColor: isCoderFragmentPie ? mediumGrey : black,
    };

    if (isAnimated) {
      return {
        ...pieLabelDefaultSettings,
        fontSize: isCoderFragmentPie ? '2.1em' : '1.75em',
        posXFactor: isCoderFragmentPie ? -5 : -22,
        posYFactor: isCoderFragmentPie ? 10 : -10,
      };
    }

    return {
      ...pieLabelDefaultSettings,
      fontSize: isCoderFragmentPie ? '1.7em' : '1.45em',
      posXFactor: isCoderFragmentPie ? 14 : -16,
      posYFactor: isCoderFragmentPie ? 8 : -12,
    };
  };

  const PieLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
    const x = cx + radius * Math.cos(-midAngle * radian);
    const y = cy + radius * Math.sin(-midAngle * radian);

    const isCoderFragmentPie = index === 0;
    const { textContent, fontColor, fontSize, posXFactor, posYFactor } =
      getPieLabelSettings(isCoderFragmentPie);

    return (
      <text
        fill={fontColor}
        fontSize={fontSize}
        x={x + posXFactor}
        y={y + posYFactor}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {textContent}
      </text>
    );
  };

  const technicalSkillsJSX = (
    <div className="talentsPercent-content">
      <p ref={developerTitleRef} className="section-title">
        {titleDeveloper}
      </p>
      <ul
        ref={developerContentRef}
        className="talentsPercent-description section-text"
      >
        <li>{firstTxtDeveloper}</li>
        <li>{secondTxtDeveloper}</li>
        <li>{thirdTxtDeveloper}</li>
        <li>{fourthTxtDeveloper}</li>
      </ul>
    </div>
  );
  const talentsPieJSX = (
    <div className="talentsPercent-graphic">
      {shouldDisplayGraphic && (
        <PieChart width={pieMeasures.width} height={pieMeasures.height}>
          <Pie
            data={graphicData}
            dataKey="value"
            cx={pieMeasures.cx}
            cy={pieMeasures.cy}
            outerRadius={pieMeasures.outerRadius}
            labelLine={false}
            label={PieLabel}
            startAngle={-270}
            endAngle={90}
            animationEasing="ease-out"
            animationDuration={400}
            animationBegin={100}
            isAnimationActive={isAnimated}
            paddingAngle={0}
          >
            {graphicData.map((_, index) => (
              <Cell
                fill={txtGraphicColors[index % txtGraphicColors.length]}
                stroke="unset"
                key={`${index + txtGraphicColors[index]}`}
              />
            ))}
          </Pie>
        </PieChart>
      )}
    </div>
  );
  const creativeTalentsJSX = (
    <div className="talentsPercent-content">
      <p ref={designTitleRef} className="section-title">
        {titleDesigner}
      </p>
      <ul
        ref={designContentRef}
        className="talentsPercent-description section-text"
      >
        <li>{firstTxtDesigner}</li>
        <li>{secondTxtDesigner}</li>
        <li>{thirdTxtsigner}</li>
        <li>{fourthTxtsigner}</li>
      </ul>
    </div>
  );

  if (isDeviceLessWiderThanLgWidth) {
    return (
      <section className={wrapperClasses.join(' ')}>
        {talentsPieJSX}
        {technicalSkillsJSX}
        {creativeTalentsJSX}
      </section>
    );
  }

  return (
    <InView
      {...OBSERVER_OPTIONS}
      rootMargin="-7%"
      as="section"
      onChange={(inView) => {
        if (inView) {
          setShouldLaunchAnimation(true);
        }
      }}
      className={wrapperClasses.join(' ')}
      skip={!isAnimated}
      triggerOnce
    >
      {technicalSkillsJSX}
      {talentsPieJSX}
      {creativeTalentsJSX}
    </InView>
  );
};

export default TalentsPercent;
