import React from 'react';
import { generateDateToDisplay } from '../../../../utils';

const Job = ({ data: { title, startDate, endDate, descriptionList } }) => (
  <article className="job">
    <header className="job-header">
      <p className="section-subtitle">{title}</p>
      <div className="job-period">
        <time className="section-text" dateTime={startDate}>
          {generateDateToDisplay(startDate)}
        </time>
        <span>-</span>
        {endDate ? (
          <time className="section-text" dateTime={endDate}>
            {generateDateToDisplay(endDate)}
          </time>
        ) : (
          <span className="section-text">Nowadays</span>
        )}
      </div>
    </header>
    <ul className="job-description">
      {descriptionList.map(({ id, txt }) => (
        <li className="section-text" key={id}>
          {txt}
        </li>
      ))}
    </ul>
  </article>
);

export default Job;
