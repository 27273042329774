import React from 'react';

/* Utils */
import { darkCoral } from '../../../utils/colors';

const AboutIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="16px"
    height="21px"
    viewBox="0 0 16 21"
    enableBackground="new 0 0 16 21"
    xmlSpace="preserve"
  >
    <path
      fill={darkCoral}
      d="M10.4024 13.1713C10.1774 12.7595 10.1413 12.3361 10.1791 11.9725C10.8121 11.4368 11.2899 10.64 11.6768 9.58847C12.2406 9.29389 12.6315 8.66131 12.6315 7.92627C12.6315 7.47505 12.4841 7.06235 12.2402 6.74231L12.2627 6.54326C12.485 5.7017 13.0967 2.75682 11.2156 1.37738C10.2346 0.151015 8.91011 -0.0451625 7.43843 0.102002C6.7063 0.175174 6.00264 0.393172 5.44068 0.31155C4.79629 0.217871 4.25988 0.0824782 4.10271 0.00385121C4.01211 -0.0415941 3.75375 0.318277 3.47977 0.918336C2.93639 2.10849 2.61507 4.15714 2.95313 6.00542L3.03659 6.74198C2.79255 7.06203 2.64502 7.47489 2.64502 7.92623C2.64502 8.64351 3.01727 9.26342 3.55913 9.56677C3.93943 10.6689 4.4518 11.4898 5.13975 12.0291C5.16781 12.3795 5.12351 12.7809 4.90998 13.1712C4.48928 13.9416 0 14.9759 0 19.2087C0 19.8614 2.18745 21 7.65622 21C13.1251 21 15.3124 19.8614 15.3124 19.2087C15.3124 14.9759 10.8232 13.9416 10.4024 13.1713Z"
    />
  </svg>
);

export default AboutIcon;
