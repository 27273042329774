class Bubble {
  constructor(color, radius, posX, posY, alpha) {
    this.color = color;
    this.radius = radius;
    this.posX = posX;
    this.posY = posY;
    this.alpha = alpha;

    this.setBubble();
  }

  setBubble() {
    this.shape = new createjs.Shape();
    this.shape.graphics
      .beginFill(this.color)
      .drawCircle(this.posX, this.posY, this.radius)
      .endFill();
    this.shape.alpha = this.alpha;
  }

  getBubble() {
    return this.shape;
  }
}

export default Bubble;
