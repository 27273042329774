import React, { useState, useRef, useEffect } from 'react';
import { InView } from 'react-intersection-observer';

/* Hooks */
import useWrapperClasses from '../../../hooks/useWrapperClasses';

/* Components */
import JobsSidebar from './JobsSidebar';
import Job from './Job';

/* Utils */
import { OBSERVER_OPTIONS } from '../../../../utils';
import { about } from '../../../../utils/literals';
import {
  initialPosFirstItem,
  initialPosSecondItem,
} from '../../../../utils/animations/positions';

// Literals
const {
  jobs: { title, sidebarList, jobsList },
} = about;

const Jobs = ({ isAnimated, isDeviceLessWiderThanLgWidth }) => {
  const initialWrapperClasses = isAnimated ? ['jobs'] : ['jobs', 'visible'];
  const [wrapperClasses, dispatch] = useWrapperClasses(initialWrapperClasses);

  const titleRef = useRef(null);
  const contentRef = useRef(null);
  const animationRef = useRef(null);

  const [shouldLaunchAnimation, setShouldLaunchAnimation] = useState(false);
  const [activeJobId, setActiveJobId] = useState(sidebarList[0].id);

  useEffect(() => {
    if (!isAnimated || !shouldLaunchAnimation) return;

    dispatch({ type: 'visible' });

    animationRef.current = new TimelineMax();
    animationRef.current
      .from(
        titleRef.current,
        0.4,
        {
          autoAlpha: 0,
          y: initialPosFirstItem,
          ease: Power1.easeOut,
        },
        '+=0',
      )
      .from(
        contentRef.current,
        0.4,
        {
          autoAlpha: 0,
          y: initialPosSecondItem,
          ease: Power1.easeOut,
        },
        '-=0.2',
      );

    return () => {
      if (animationRef.current) {
        animationRef.current.kill();
      }
    };
  }, [isAnimated, shouldLaunchAnimation, dispatch]);

  const activeJob = jobsList.find((job) => job.id === activeJobId);

  const jobsContent = (
    <>
      <p ref={titleRef} className="section-title">
        {title}
      </p>
      <div ref={contentRef} className="jobs-wrapper">
        <JobsSidebar
          jobs={sidebarList}
          activeJobId={activeJobId}
          onClickJob={(jobId) => setActiveJobId(jobId)}
        />
        <div className="jobs-content">
          <Job key={activeJob.id} data={activeJob} />
        </div>
      </div>
    </>
  );

  if (isDeviceLessWiderThanLgWidth) {
    return (
      <section className={wrapperClasses.join(' ')}>{jobsContent}</section>
    );
  }

  return (
    <InView
      {...OBSERVER_OPTIONS}
      as="section"
      onChange={(inView) => {
        if (inView) {
          setShouldLaunchAnimation(true);
        }
      }}
      className={wrapperClasses.join(' ')}
      skip={!isAnimated}
      triggerOnce
    >
      {jobsContent}
    </InView>
  );
};

export default Jobs;
