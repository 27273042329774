import React, { useEffect } from 'react';

/* Hooks */
import useWindowMeasures from '../../hooks/useWindowMeasures';
import useWrapperClasses from '../../hooks/useWrapperClasses';

/* Components */
import Highlight from './Highlight';
import Introduction from './Introduction';
import Jobs from './Jobs';
import Connect from './Connect';

/* Utils */
import { setZeroScrollTop } from '../../../utils';
import { xlWidth, lgWidth } from '../../../utils/measures';

const About = () => {
  const { width } = useWindowMeasures();
  const isAnimated = width > xlWidth;
  const isDeviceLessWiderThanLgWidth = width < lgWidth;

  const initialWrapperClasses = isAnimated ? ['about', 'visible'] : ['about'];
  const [wrapperClasses, dispatch] = useWrapperClasses(initialWrapperClasses);

  useEffect(() => {
    setZeroScrollTop();
    if (isAnimated) return;
    dispatch({ type: 'visible' });
  }, [isAnimated, dispatch]);

  return (
    <main className={wrapperClasses.join(' ')}>
      <Highlight isAnimated={isAnimated} />
      <Introduction
        isAnimated={isAnimated}
        isDeviceLessWiderThanLgWidth={isDeviceLessWiderThanLgWidth}
      />
      <Jobs
        isAnimated={isAnimated}
        isDeviceLessWiderThanLgWidth={isDeviceLessWiderThanLgWidth}
      />
      <Connect
        isAnimated={isAnimated}
        isDeviceLessWiderThanLgWidth={isDeviceLessWiderThanLgWidth}
      />
    </main>
  );
};

export default About;
