import React, { useRef, useEffect } from 'react';

/* Utils */
import {
  smoothGrey,
  lightGrey,
  grey,
  orange,
  darkGrey,
  smoothBlack,
} from '../../../../utils/colors';

const PresentationImg = ({ isAnimated, shouldLaunchAnimation }) => {
  const svgRef = useRef(null);
  const cloudRef = useRef(null);
  const phoneRef = useRef(null);
  const connectRef = useRef(null);
  const firstCicleRef = useRef(null);
  const secondCicleRef = useRef(null);
  const thirdCicleRef = useRef(null);
  const worldRef = useRef(null);
  const twitterRef = useRef(null);
  const facebookRef = useRef(null);
  const chainRef = useRef(null);
  const windowsRef = useRef(null);
  const atRef = useRef(null);
  const envelopRef = useRef(null);
  const planeRef = useRef(null);
  const githubRef = useRef(null);
  const linkedinRef = useRef(null);
  const animation = useRef(null);
  const connectAnimation = useRef(null);

  useEffect(() => {
    if (!isAnimated || !shouldLaunchAnimation) return;

    animation.current = new TimelineMax({
      onComplete: () => {
        connectAnimation.current = new TimelineMax({
          repeat: -1,
          repeatDelay: 0.2,
          yoyo: true,
        });

        connectAnimation.current
          .to(firstCicleRef.current, 0.5, { autoAlpha: 0 }, '+=0')
          .to(secondCicleRef.current, 0.5, { autoAlpha: 0 }, '+=0')
          .to(thirdCicleRef.current, 0.5, { autoAlpha: 0 }, '+=0');
      },
    });

    animation.current
      .timeScale(1.5)
      .set(svgRef.current, { autoAlpha: 1 })
      .from(
        envelopRef.current,
        0.4,
        {
          autoAlpha: 0,
          scale: 0.5,
          transformOrigin: '0% 0%',
          ease: Power3.easeOut,
        },
        '+=0.2',
      )
      .from(
        planeRef.current,
        0.4,
        {
          autoAlpha: 0,
          scale: 0.5,
          transformOrigin: '100% 10%',
          ease: Power3.easeOut,
        },
        '-=.35',
      )
      .from(
        atRef.current,
        0.4,
        {
          autoAlpha: 0,
          scale: 0.5,
          transformOrigin: '0% 90%',
          ease: Power3.easeOut,
        },
        '-=.3',
      )
      .from(
        githubRef.current,
        0.4,
        {
          autoAlpha: 0,
          scale: 0.5,
          transformOrigin: '100% 100%',
          ease: Power3.easeOut,
        },
        '-=.35',
      )
      .from(
        phoneRef.current,
        0.4,
        {
          autoAlpha: 0,
          scale: 0.5,
          transformOrigin: '0% 100%',
          ease: Power3.easeOut,
        },
        '-=.35',
      )
      .from(
        windowsRef.current,
        0.4,
        {
          autoAlpha: 0,
          scale: 0.5,
          transformOrigin: '100% 0%',
          ease: Power3.easeOut,
        },
        '-=.3',
      )
      .from(
        connectRef.current,
        0.4,
        {
          autoAlpha: 0,
          scale: 0.5,
          transformOrigin: '100% 0%',
          ease: Power3.easeOut,
        },
        '-=.35',
      )
      .from(
        twitterRef.current,
        0.4,
        {
          autoAlpha: 0,
          scale: 0.5,
          transformOrigin: '0% 0%',
          ease: Power3.easeOut,
        },
        '-=.35',
      )
      .from(
        facebookRef.current,
        0.4,
        {
          autoAlpha: 0,
          scale: 0.5,
          transformOrigin: '100% 0%',
          ease: Power3.easeOut,
        },
        '-=.3',
      )
      .from(
        chainRef.current,
        0.4,
        {
          autoAlpha: 0,
          scale: 0.5,
          transformOrigin: '0% 100%',
          ease: Power3.easeOut,
        },
        '-=.35',
      )
      .from(
        worldRef.current,
        0.4,
        {
          autoAlpha: 0,
          scale: 0.5,
          transformOrigin: '100% 10%',
          ease: Power3.easeOut,
        },
        '-=.35',
      )
      .from(
        cloudRef.current,
        0.4,
        {
          autoAlpha: 0,
          scale: 0.5,
          transformOrigin: '100% 0%',
          ease: Power3.easeOut,
        },
        '-=.3',
      )
      .from(
        linkedinRef.current,
        0.4,
        {
          autoAlpha: 0,
          scale: 0.5,
          transformOrigin: '100% 0%',
          ease: Power3.easeOut,
        },
        '-=.35',
      );

    return () => {
      if (animation.current) {
        animation.current.kill();
      }
      if (connectAnimation.current) {
        connectAnimation.current.kill();
      }
    };
  }, [isAnimated, shouldLaunchAnimation]);

  return (
    <div className="connect-image">
      <svg
        ref={svgRef}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="360px"
        height="280px"
        viewBox="0 0 420 340"
        enableBackground="new 0 0 380 336"
        xmlSpace="preserve"
      >
        <g ref={cloudRef}>
          <path
            fill={darkGrey}
            d="M256.845,13.628c-12.741,12.743-12.741,33.399,0,46.141c12.74,12.741,33.397,12.741,46.139-0.002
		c10.608-10.608,12.376-26.699,5.32-39.146L315.36,1.5l-18.92,6.847l0.503,0.524C284.376,1.165,267.727,2.746,256.845,13.628z"
          />
          <path
            fill={smoothGrey}
            d="M289.754,43.948h-20.217c-4.381,0-7.943-3.56-7.943-7.935c0-4.321,3.472-7.846,7.775-7.935
		c1.572-5.133,6.311-8.667,11.725-8.667c6.77,0,12.275,5.506,12.275,12.275c0,0.132-0.005,0.264-0.014,0.394
		c1.801,1.202,2.897,3.225,2.897,5.377C296.253,41.036,293.338,43.948,289.754,43.948L289.754,43.948z"
          />
        </g>
        <g ref={phoneRef}>
          <path
            fill={orange}
            d="M76.885,80.548h48.609c10.105,0,18.401,8.286,18.401,18.414v39.78c0,10.12-8.296,18.402-18.401,18.402
		h-47.78l-19.258,25.903v-44.305v-5.468V98.962C58.456,88.834,66.741,80.548,76.885,80.548z"
          />
          <path
            fill={smoothBlack}
            d="M124.287,131.381c0.099,0.766-0.135,1.432-0.699,1.998l-6.644,6.595c-0.299,0.329-0.691,0.613-1.174,0.849
		c-0.482,0.231-0.958,0.381-1.424,0.447c-0.032,0-0.135,0.008-0.301,0.025c-0.165,0.017-0.382,0.025-0.65,0.025
		c-0.631,0-1.655-0.105-3.07-0.323c-1.415-0.216-3.148-0.75-5.196-1.598c-2.047-0.851-4.37-2.123-6.966-3.822
		c-2.598-1.697-5.363-4.029-8.293-6.992c-2.331-2.298-4.262-4.496-5.793-6.593c-1.531-2.098-2.764-4.039-3.696-5.82
		c-0.933-1.78-1.633-3.396-2.098-4.846c-0.465-1.449-0.782-2.695-0.948-3.746c-0.167-1.047-0.234-1.872-0.202-2.471
		c0.034-0.599,0.05-0.933,0.05-1c0.066-0.465,0.216-0.94,0.45-1.422c0.234-0.484,0.518-0.875,0.85-1.174l6.643-6.642
		c0.465-0.466,0.999-0.701,1.598-0.701c0.433,0,0.816,0.126,1.149,0.376c0.333,0.25,0.616,0.559,0.848,0.923l5.347,10.141
		c0.299,0.532,0.383,1.115,0.249,1.748c-0.134,0.633-0.417,1.165-0.85,1.599l-2.447,2.448c-0.066,0.066-0.124,0.174-0.175,0.325
		c-0.049,0.149-0.075,0.273-0.075,0.375c0.133,0.698,0.434,1.499,0.9,2.396c0.399,0.799,1.016,1.773,1.849,2.921
		c0.832,1.15,2.015,2.472,3.545,3.971c1.5,1.532,2.832,2.723,3.998,3.571c1.164,0.85,2.14,1.473,2.921,1.874
		c0.784,0.4,1.381,0.641,1.8,0.725l0.623,0.125c0.067,0,0.174-0.026,0.324-0.075s0.26-0.11,0.323-0.176l2.848-2.897
		c0.601-0.533,1.3-0.797,2.098-0.797c0.566,0,1.016,0.099,1.349,0.299h0.049l9.642,5.694
		C123.737,130.166,124.152,130.714,124.287,131.381z"
          />
        </g>
        <g ref={worldRef}>
          <path
            fill={orange}
            d="M401.003,217.765L385.187,254.8v15.901c0,5.039-4.087,9.123-9.126,9.123h-60.145
        c-5.038,0-9.126-4.084-9.126-9.123v-53.547c0-5.041,4.088-9.125,9.126-9.125h60.145c5.039,0,9.126,4.084,9.126,9.125v2.515
        L401.003,217.765z"
          />
          <path
            fill={smoothBlack}
            d="M355.623,265.21c0.051-0.027,0.107-0.049,0.159-0.074c-0.02-0.033-0.038-0.058-0.058-0.087
        C355.698,265.097,355.657,265.163,355.623,265.21z"
          />
          <path
            fill={smoothBlack}
            d="M345.463,264.43c-4.114,0-7.948-1.182-11.22-3.191c2.942,0.031-2.188-4.477,1.344-5.167
        c3.714-0.733,5.277-4.976,4.086-4.909c-1.185,0.061-3.442-0.613-5.025-2.606c-1.591-2.007-2.982-1.549-4.623-1.238
        c-1.432,0.286-3.195,4.432-3.637,5.52c-1.114-2.138-1.869-4.484-2.214-6.966c1.821-0.955,4.792-2.557,5.865-3.44
        c1.583-1.314-0.526-1.844-0.787-2.636c-0.256-0.788,0-2.635,0-4.465c0-1.845,3.684-1.845,4.991-3.685
        c0.941-1.313-0.78-4.202-1.854-5.772c3.63-2.789,8.156-4.469,13.075-4.469c1.306,0,2.579,0.135,3.819,0.359
        c0.364,2.267,1.123,5.384,2.588,6.195c2.374,1.314,5.245,4.211,6.307,4.729c1.058,0.528,2.363,5.007,2.104,6.845
        c-0.188,1.299-1.798,2.732-2.458,4.109l10.961,6.829c0.77-2.383,1.201-4.915,1.201-7.547c0-13.521-11.006-24.518-24.521-24.518
        c-13.521,0-24.519,10.996-24.519,24.518c0,13.521,10.996,24.521,24.519,24.521c0.513,0,1.016-0.047,1.52-0.078l-0.571-2.988
        C346.097,264.395,345.784,264.43,345.463,264.43z M366.713,246.096c-0.467-1.841-1.366-3.616-3.272-4.989
        c-1.308-0.921-2.375-4.992-1.579-6.311c0.472-0.786,1.705-1.479,2.611-1.908c1.591,2.998,2.494,6.41,2.494,10.036
        C366.97,244.003,366.865,245.055,366.713,246.096z"
          />
          <path
            fill={smoothGrey}
            d="M360.366,256.854l10.237-2.04l-25.648-15.981l-0.605,0.421l5.712,29.669l5.575-8.818l5.402,7.875
        c1.028,1.484,2.912,1.962,4.221,1.068c1.299-0.896,1.533-2.832,0.515-4.32L360.366,256.854z"
          />
        </g>
        <g ref={windowsRef}>
          <path
            fill={grey}
            d="M327.531,136.893h-45.615c-9.48,0-17.267,7.777-17.267,17.28v37.328c0,9.495,7.786,17.269,17.267,17.269
          h44.835l18.073,24.307v-41.575v-5.134v-32.194C344.825,144.669,337.052,136.893,327.531,136.893z"
          />
          <polygon
            fill={smoothGrey}
            points="282.319,189.523 300.656,192.179 300.656,174.635 282.319,174.635 	"
          />
          <polygon
            fill={smoothGrey}
            points="303.162,192.543 327.763,196.104 327.763,174.635 303.162,174.635 	"
          />
          <polygon
            fill={smoothGrey}
            points="303.162,172.13 327.763,172.13 327.763,150.658 303.162,154.221 	"
          />
          <polygon
            fill={smoothGrey}
            points="300.656,154.585 282.319,157.241 282.319,172.13 300.656,172.13 	"
          />
        </g>
        <g ref={connectRef}>
          <path
            fill={grey}
            d="M179.963,222.85h-48.82c-10.148,0-18.479,8.319-18.479,18.494v39.949c0,10.162,8.331,18.482,18.479,18.482
          h47.984l19.341,26.014v-44.496v-5.491v-34.458C198.47,231.169,190.15,222.85,179.963,222.85z"
          />
          <path
            fill={smoothGrey}
            stroke={smoothGrey}
            strokeMiterlimit="10"
            d="M166.643,264.578c-3.669,0-6.917,1.79-8.95,4.531l-11.612-5.877
          c0.82-1.372,1.302-2.974,1.302-4.689c0-1.353-0.302-2.634-0.83-3.796l13.763-5.125c1.172,2.315,3.562,3.905,6.326,3.905
          c3.92,0,7.096-3.184,7.096-7.112s-3.176-7.115-7.096-7.115c-3.919,0-7.095,3.187-7.095,7.115c0,0.436,0.044,0.862,0.12,1.28
          l-14.177,5.286c-1.667-2.175-4.281-3.582-7.229-3.582c-5.037,0-9.122,4.093-9.122,9.146c0,5.052,4.084,9.146,9.122,9.146
          c2.584,0,4.911-1.082,6.571-2.813l11.804,5.978c-0.725,1.482-1.142,3.143-1.142,4.902c0,6.177,4.993,11.179,11.149,11.179
          c6.16,0,11.15-5.006,11.15-11.179S172.802,264.578,166.643,264.578z M166.643,241.334c2.795,0,5.067,2.28,5.067,5.081
          c0,2.8-2.274,5.082-5.067,5.082c-2.792,0-5.066-2.282-5.066-5.082C161.576,243.61,163.85,241.334,166.643,241.334z M138.261,265.66
          c-3.912,0-7.096-3.191-7.096-7.115c0-3.923,3.184-7.114,7.096-7.114c3.915,0,7.095,3.191,7.095,7.114
          C145.357,262.469,142.176,265.66,138.261,265.66z M166.643,284.901c-5.027,0-9.122-4.104-9.122-9.144
          c0-5.047,4.091-9.148,9.122-9.148c5.03,0,9.125,4.102,9.125,9.148C175.765,280.8,171.673,284.901,166.643,284.901z"
          />
          <path
            ref={firstCicleRef}
            fill={smoothGrey}
            d="M172.329,246.181c0,3.037-2.463,5.5-5.5,5.5l0,0c-3.038,0-5.5-2.463-5.5-5.5l0,0c0-3.037,2.462-5.5,5.5-5.5
	l0,0C169.866,240.681,172.329,243.144,172.329,246.181L172.329,246.181z"
          />
          <path
            ref={secondCicleRef}
            fill={smoothGrey}
            d="M146.284,258.905c0,4.542-3.683,8.226-8.225,8.226l0,0c-4.542,0-8.225-3.684-8.225-8.226l0,0
	c0-4.541,3.683-8.225,8.225-8.225l0,0C142.601,250.681,146.284,254.364,146.284,258.905L146.284,258.905z"
          />
          <path
            ref={thirdCicleRef}
            fill={smoothGrey}
            d="M175.829,275.823c0,4.97-4.03,9.001-9,9.001l0,0c-4.971,0-9-4.031-9-9.001l0,0c0-4.969,4.029-8.999,9-8.999
	l0,0C171.799,266.824,175.829,270.854,175.829,275.823L175.829,275.823z"
          />
        </g>
        <g ref={facebookRef}>
          <path
            fill={smoothBlack}
            d="M391.244,150.734c16.861-16.86,16.861-44.196,0-61.055c-16.861-16.861-44.195-16.861-61.058,0
		c-14.038,14.038-16.376,35.331-7.039,51.803l-9.337,25.302l25.036-9.062l-0.666-0.693
		C354.81,167.228,376.845,165.136,391.244,150.734z"
          />
          <path
            fill={smoothGrey}
            d="M352.992,145.711l10.264,0.029c0,0,0.043-14.17,0.078-25.664l7.615,0.022l0.969-10.263l-8.159-0.023
		l0.012-4.105c0.005-2,1.341-2.461,2.276-2.458c0.938,0.002,5.76,0.016,5.76,0.016l0.026-8.795l-7.931-0.055
		c-8.803-0.026-10.822,6.527-10.834,10.728l-0.014,4.639l-5.09-0.014l-0.031,10.265l5.133,0.015
		C353.032,131.68,352.992,145.711,352.992,145.711z"
          />
        </g>
        <g ref={twitterRef}>
          <path
            fill={grey}
            d="M138.293,123.746c20.491,20.493,53.716,20.493,74.209,0c20.492-20.493,20.492-53.717,0-74.21
		c-17.061-17.063-42.943-19.903-62.961-8.554l-30.754-11.35l11.015,30.429l0.841-0.81
		C118.249,79.462,120.791,106.244,138.293,123.746z"
          />
          <path
            fill={smoothBlack}
            d="M205.951,62.565c-2.578,1.506-5.437,2.602-8.478,3.191c-2.432-2.553-5.898-4.15-9.742-4.15
		c-7.368,0-13.343,5.882-13.343,13.138c0,1.031,0.118,2.031,0.341,2.992c-11.09-0.546-20.926-5.778-27.508-13.726
		c-1.147,1.941-1.81,4.195-1.81,6.605c0,4.557,2.363,8.577,5.942,10.934c-2.188-0.069-4.248-0.658-6.047-1.642
		c0,0.055,0,0.11,0,0.165c0,6.366,4.601,11.675,10.708,12.884c-1.121,0.301-2.299,0.462-3.519,0.462
		c-0.862,0-1.697-0.083-2.511-0.234c1.701,5.217,6.624,9.016,12.467,9.122c-4.57,3.523-10.323,5.624-16.578,5.624
		c-1.081,0-2.139-0.062-3.185-0.183c5.91,3.728,12.928,5.9,20.463,5.9c24.547,0,37.971-20.018,37.971-37.379
		c0-0.569-0.009-1.137-0.034-1.701c2.602-1.85,4.868-4.166,6.658-6.8c-2.393,1.046-4.962,1.75-7.667,2.066
		C202.841,68.209,204.954,65.635,205.951,62.565z"
          />
        </g>
        <g ref={planeRef}>
          <path
            fill={smoothBlack}
            d="M326.141,68.506l-19.122,44.768v19.218c0,6.092-4.94,11.03-11.03,11.03h-72.696
        c-6.092,0-11.034-4.938-11.034-11.03V67.771c0-6.09,4.942-11.03,11.034-11.03h72.697c6.09,0,11.03,4.939,11.03,11.03v3.04
        L326.141,68.506z"
          />
          <path
            fill={lightGrey}
            d="M243.995,96.006c-1.035,0.397-1.769,1.327-1.915,2.423c-0.146,1.1,0.321,2.188,1.217,2.838l9.804,7.135
        l34.817-27.393l-30.787,30.331l13.4,9.751c0.85,0.62,1.953,0.805,2.966,0.499c1.011-0.307,1.817-1.078,2.18-2.066l14.335-39.239
        c0.145-0.398,0.043-0.842-0.258-1.138c-0.301-0.3-0.746-0.391-1.146-0.241L243.995,96.006z"
          />
          <path
            fill={lightGrey}
            d="M250.773,110.774l0.082,0.454l1.902,10.403c0.143,0.773,0.658,1.42,1.38,1.734
        c0.718,0.31,1.547,0.246,2.204-0.181c2.649-1.708,6.11-3.976,6.019-4.108L250.773,110.774z"
          />
        </g>
        <g ref={atRef}>
          <path
            fill={smoothBlack}
            d="M69.362,218.357l17.869-41.832v-17.957c0-5.694,4.616-10.308,10.306-10.308h67.931
		c5.692,0,10.309,4.614,10.309,10.308v60.478c0,5.69-4.615,10.308-10.309,10.308H97.537c-5.691,0-10.308-4.615-10.308-10.308v-2.84
		L69.362,218.357z"
          />
          <path
            fill={lightGrey}
            d="M130.999,164.448c-4.887,0-9.288,1.07-13.125,3.232c-3.838,2.157-6.825,5.238-8.893,9.171
		c-2.069,3.923-3.095,8.392-3.095,13.346c0,6.82,1.945,12.323,5.888,16.244c3.943,3.922,9.492,5.85,16.405,5.85
		c4.633,0,8.816-0.8,12.522-2.419c0.401-0.176,0.684-0.603,0.684-1.04v-3.683c0-0.365-0.189-0.728-0.491-0.935
		c-0.301-0.205-0.708-0.253-1.051-0.125c-4.063,1.549-7.942,2.306-11.665,2.306c-5.391,0-9.32-1.426-12.042-4.202
		c-2.728-2.777-4.134-6.756-4.134-12.188c0-4.007,0.772-7.479,2.286-10.464c1.511-2.982,3.66-5.233,6.522-6.835
		c2.852-1.602,6.229-2.417,10.188-2.417c3.11,0,5.803,0.64,8.142,1.9c2.338,1.258,4.102,3.014,5.37,5.343
		c1.267,2.323,1.912,5.05,1.912,8.235c0,3.047-0.511,5.446-1.447,7.206c-0.936,1.764-1.861,2.376-3.093,2.376
		c-0.972,0-1.454-0.25-1.854-0.78c-0.398-0.529-0.695-1.516-0.695-3.016v-0.609l0.692-12.954c0.024-0.497-0.318-0.992-0.792-1.139
		c-1.343-0.43-2.833-0.761-4.473-0.997c-1.629-0.238-3.103-0.358-4.425-0.358c-3.798,0-7.033,1.314-9.42,3.896
		c-2.384,2.573-3.568,5.962-3.568,9.949c0,3.429,0.84,6.292,2.604,8.431c1.768,2.147,4.336,3.251,7.361,3.251
		c2.065,0,3.939-0.511,5.513-1.543c0.917-0.604,1.701-1.371,2.368-2.247c0.392,0.776,0.921,1.477,1.591,2.066
		c1.294,1.141,2.946,1.724,4.761,1.724c2.159,0,4.148-0.702,5.81-2.052c1.656-1.35,2.914-3.227,3.782-5.55
		c0.863-2.317,1.29-4.86,1.29-7.596c0-4.168-0.884-7.91-2.674-11.165c-1.788-3.256-4.356-5.802-7.628-7.571
		C138.859,165.32,135.131,164.448,130.999,164.448L130.999,164.448z M130.336,181.17c1.133,0,2.188,0.105,3.163,0.303l-0.338,6.275
		v0.004c-0.156,2.768-0.748,4.728-1.591,5.902c-0.848,1.18-1.882,1.693-3.509,1.693c-1.552,0-2.469-0.43-3.146-1.315
		c-0.676-0.879-1.117-2.387-1.117-4.584c0-2.781,0.631-4.8,1.78-6.205C126.727,181.839,128.197,181.17,130.336,181.17
		L130.336,181.17z"
          />
        </g>
        <g ref={envelopRef}>
          <path
            fill={orange}
            d="M247.298,200.998c19.469-19.475,19.469-51.042,0-70.513c-19.472-19.47-51.04-19.47-70.511,0
		c-16.211,16.212-18.914,40.805-8.131,59.823l-10.783,29.223l28.914-10.467l-0.772-0.8
		C205.221,220.039,230.667,217.624,247.298,200.998z"
          />
          <path
            fill={smoothGrey}
            d="M183.555,145.531c0.494-0.182,1.022-0.297,1.581-0.297h53.729c0.56,0,1.088,0.116,1.58,0.297
		l-24.075,22.681c-2.449,2.312-6.292,2.312-8.74,0L183.555,145.531z M242.749,185.832c0.416-0.687,0.672-1.484,0.672-2.351v-33.694
		c0-0.611-0.128-1.194-0.346-1.728l-22.529,21.227L242.749,185.832z M218.509,171.173c-1.868,1.6-4.185,2.413-6.508,2.413
		c-2.324,0-4.641-0.812-6.511-2.413l-22.137,16.499c0.55,0.235,1.149,0.364,1.784,0.364h53.731c0.631,0,1.233-0.129,1.78-0.364
		L218.509,171.173z M180.927,148.06c-0.219,0.534-0.345,1.116-0.345,1.728v33.694c0,0.866,0.254,1.664,0.671,2.351l22.202-16.55
		L180.927,148.06z"
          />
        </g>
        <g ref={chainRef}>
          <path
            fill={smoothBlack}
            d="M142.298,324.373c12.741-12.741,12.741-33.397,0-46.141c-12.742-12.743-33.399-12.743-46.141,0.002
		c-10.609,10.607-12.377,26.702-5.319,39.146L83.78,336.5l18.921-6.849l-0.505-0.522
		C114.763,336.836,131.415,335.254,142.298,324.373z"
          />
          <path
            fill={orange}
            d="M119.559,299.898c-0.432,0.433-0.432,1.123,0,1.553c1.78,1.779,1.78,4.674,0,6.454l-9.347,9.346
		c-1.78,1.781-4.674,1.781-6.456,0l-1.405-1.404c-1.781-1.779-1.781-4.672,0-6.454l9.347-9.348c0.431-0.432,0.431-1.122,0-1.553
		c-0.431-0.428-1.121-0.428-1.553,0l-9.349,9.35c-1.276,1.273-1.983,2.975-1.983,4.776c0,1.807,0.699,3.506,1.983,4.779l1.406,1.41
		c1.317,1.313,3.048,1.973,4.78,1.973c1.73,0,3.462-0.659,4.779-1.973l9.348-9.35c2.634-2.636,2.634-6.925,0-9.56
		C120.689,299.471,119.991,299.471,119.559,299.898z"
          />
          <path
            fill={orange}
            d="M136.083,287.619l-1.406-1.41c-2.634-2.634-6.926-2.634-9.559,0l-9.347,9.35
		c-2.633,2.633-2.633,6.926,0,9.56c0.431,0.431,1.121,0.431,1.553,0c0.43-0.433,0.43-1.12,0-1.552c-1.781-1.78-1.781-4.677,0-6.454
		l9.348-9.349c1.779-1.779,4.673-1.779,6.454,0l1.406,1.408c1.78,1.78,1.78,4.67,0,6.452l-9.348,9.348
		c-0.431,0.43-0.431,1.12,0,1.553c0.213,0.211,0.495,0.326,0.772,0.326c0.278,0,0.562-0.105,0.773-0.326l9.347-9.346
		c1.277-1.277,1.983-2.977,1.983-4.782C138.059,290.592,137.36,288.895,136.083,287.619z"
          />
        </g>
        <g ref={linkedinRef}>
          <path
            fill={darkGrey}
            d="M29.948,237.044c-16.839,16.842-16.839,44.146,0,60.985c16.839,16.838,44.143,16.838,60.982,0
        c14.02-14.02,16.356-35.291,7.031-51.739l9.325-25.274l-25.005,9.053l0.667,0.69C66.337,220.576,44.33,222.664,29.948,237.044z"
          />
          <path
            fill={smoothGrey}
            d="M43.806,241.558c3.096,0.004,5.651,2.562,5.644,5.673c-0.008,3.258-2.711,5.717-5.682,5.645
        c-3.013,0.061-5.66-2.459-5.658-5.668C38.109,244.096,40.673,241.556,43.806,241.558z"
          />
          <path
            fill={smoothGrey}
            d="M47.097,288.623h-6.634c-0.861,0-1.558-0.702-1.558-1.559v-28.302c0-0.862,0.697-1.561,1.558-1.561h6.634
        c0.86,0,1.558,0.698,1.558,1.561v28.302C48.656,287.925,47.957,288.623,47.097,288.623z"
          />
          <path
            fill={smoothGrey}
            d="M85.287,267.141c0-5.932-4.808-10.74-10.74-10.74h-1.703c-3.245,0-6.112,1.621-7.837,4.095
        c-0.19,0.271-0.363,0.548-0.524,0.84c-0.053-0.009-0.087-0.013-0.087-0.011v-3.475c0-0.357-0.288-0.647-0.648-0.647H55.47
        c-0.357,0-0.648,0.29-0.648,0.647v30.126c0,0.356,0.289,0.647,0.648,0.647l8.458,0.004c0.358,0,0.647-0.291,0.647-0.649v-17.406
        c0-3.008,2.399-5.506,5.409-5.539c1.534-0.018,2.928,0.599,3.929,1.604c0.989,0.988,1.603,2.356,1.603,3.868v17.476
        c0,0.358,0.29,0.649,0.648,0.649l8.477,0.002c0.359,0,0.648-0.289,0.648-0.649v-20.841H85.287z"
          />
        </g>
        <g ref={githubRef}>
          <path
            fill={smoothBlack}
            d="M285.919,210.457c-22.389-22.392-58.689-22.392-81.079-0.002s-22.39,58.689,0,81.081
		c18.643,18.642,46.923,21.745,68.791,9.345l33.601,12.402l-12.034-33.248l-0.92,0.887
		C307.817,258.839,305.041,229.579,285.919,210.457z"
          />
          <circle fill={smoothGrey} cx="246.093" cy="246.181" r="37.64" />
          <path
            fill={smoothBlack}
            d="M245.638,206.644c-21.553,0-39.029,17.472-39.029,39.03c0,17.244,11.183,31.876,26.692,37.035
		c1.948,0.363,2.665-0.847,2.665-1.875c0-0.934-0.036-4.004-0.055-7.27c-10.858,2.362-13.149-4.605-13.149-4.605
		c-1.773-4.508-4.333-5.708-4.333-5.708c-3.543-2.424,0.269-2.372,0.269-2.372c3.917,0.275,5.98,4.021,5.98,4.021
		c3.481,5.969,9.131,4.239,11.358,3.246c0.351-2.526,1.361-4.245,2.478-5.22c-8.669-0.987-17.782-4.333-17.782-19.29
		c0-4.259,1.524-7.744,4.024-10.476c-0.408-0.984-1.743-4.952,0.378-10.33c0,0,3.276-1.048,10.733,4.001
		c3.114-0.862,6.452-1.298,9.77-1.31c3.318,0.012,6.658,0.447,9.776,1.31c7.45-5.047,10.724-4.001,10.724-4.001
		c2.124,5.378,0.789,9.346,0.383,10.33c2.502,2.731,4.018,6.217,4.018,10.476c0,14.992-9.132,18.293-17.822,19.259
		c1.398,1.213,2.646,3.587,2.646,7.23c0,5.221-0.044,9.424-0.044,10.709c0,1.038,0.703,2.254,2.682,1.869
		c15.5-5.165,26.666-19.789,26.666-37.029C284.667,224.117,267.194,206.644,245.638,206.644z"
          />
        </g>
      </svg>
    </div>
  );
};

export default PresentationImg;
