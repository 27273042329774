import React from 'react';

/* Utils */
import { darkCoral } from '../../../utils/colors';

const ProjectsIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="22.956px"
    height="21px"
    viewBox="0 0 22.956 21"
    enableBackground="new 0 0 22.956 21"
    xmlSpace="preserve"
  >
    <path
      fill={darkCoral}
      d="M22.956,13.045V1.043C22.956,0.468,22.49,0,21.914,0H1.045C0.471,0,0,0.468,0,1.043v12.002H22.956zM19.03,2.071c0.15-0.153,0.396-0.153,0.554,0l1.301,1.305c0.157,0.152,0.157,0.4,0,0.554c-0.074,0.074-0.173,0.115-0.273,0.115c-0.098,0-0.2-0.041-0.278-0.115L19.03,2.625C18.875,2.472,18.875,2.224,19.03,2.071z M16.419,2.071c0.156-0.153,0.403-0.153,0.555,0l3.911,3.911c0.157,0.154,0.157,0.4,0,0.554c-0.074,0.078-0.173,0.115-0.273,0.115c-0.098,0-0.2-0.037-0.278-0.115l-3.914-3.911C16.268,2.472,16.268,2.224,16.419,2.071z"
    />
    <path
      fill={darkCoral}
      d="M0,13.829v1.563c0,0.574,0.471,1.047,1.045,1.047h7.434v1.561c0,0.285-0.142,0.747-0.296,0.982l-0.58,0.867c-0.179,0.267-0.209,0.552-0.084,0.786C7.644,20.868,7.898,21,8.219,21h6.523c0.314,0,0.572-0.132,0.7-0.365c0.125-0.234,0.089-0.52-0.085-0.786l-0.585-0.867c-0.148-0.235-0.29-0.697-0.29-0.982v-1.561h7.432c0.576,0,1.042-0.473,1.042-1.047v-1.563H0z M11.48,15.392c-0.359,0-0.652-0.289-0.652-0.654c0-0.357,0.293-0.65,0.652-0.65c0.358,0,0.651,0.293,0.651,0.65C12.131,15.103,11.838,15.392,11.48,15.392z"
    />
  </svg>
);

export default ProjectsIcon;
