import React from 'react';

/* UI */
import Button from '../ui/Button';

/* Utils */
import { footer } from '../../utils/literals';

// Literals
const { txt1, txt2 } = footer;

const Footer = ({ isFooterShapeDisplayed }) => {
  const footerShapeJSX = <div className="shape" />;
  const footerContentJSX = (
    <div className="content">
      <p>{txt1}</p>
      <p>{txt2}</p>
    </div>
  );

  return (
    <footer>
      {isFooterShapeDisplayed ? (
        <Button
          onClick={() => {
            window.scroll({
              top: 0,
              behavior: 'smooth',
            });
          }}
        >
          {footerShapeJSX}
          {footerContentJSX}
        </Button>
      ) : (
        footerContentJSX
      )}
    </footer>
  );
};

export default Footer;
