import React, { useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

/* Utils */
import { darkGrey, orange } from '../../../utils/colors';

const Logo = ({ hasHeaderShape }) => (
  <div className="pageHeader-logoWrapper">
    {hasHeaderShape && <div className="pageHeader-shape" />}
    <NavLink to="/">
      <h1>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="60px"
          height="60px"
          viewBox="0 0 60 60"
          enableBackground="new 0 0 60 60"
          xmlSpace="preserve"
        >
          <g className="logo-shapes">
            <path
              fill={darkGrey}
              d="M25.3,0c0,0.4,0,0.8,0,1.2c0,12.4,0,24.8,0,37.1c0,1.9-0.2,3.9-0.4,5.8c-0.3,2.9-1.8,4.2-4.7,4.2
	c-2,0-4-0.2-6.1-0.4c0,2.2,0,4.4,0,6.7c-9-5-16.7-17.6-13.4-32.1C4,8.6,16.3,0.7,25.3,0z"
            />
            <path
              fill={orange}
              d="M30.3,59.6c-0.6-0.6-0.1-0.7,0.3-0.8c3.1-1.3,5.4-3.5,6.5-6.6
	c0.8-2.4,1.4-5,1.4-7.4c0.2-13.2,0.2-26.4,0.3-39.6c0-1.3,0-2.6,0-4.1c0.6,0,1.3,0.1,1.8,0.3c10.8,4.5,17.6,12.4,19.1,24
	c1.7,13-3.5,23-14.6,30c-3.9,2.5-8.4,3.9-13.1,4.2H30.3z"
            />
          </g>
        </svg>
      </h1>
    </NavLink>
  </div>
);

export default Logo;
