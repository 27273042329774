import React from 'react';

/* UI */
import Button from '../../../ui/Button';

const JobsSidebar = ({ jobs, activeJobId, onClickJob }) => (
  <aside className="jobs-sidebar">
    <ul className={`jobs-sidebar-list active-${activeJobId}`}>
      {jobs.map(({ name, id }) => (
        <li key={id} className={id}>
          <Button
            type="primary"
            onClick={() => onClickJob(id)}
            isActive={activeJobId === id}
            withActiveBg
          >
            {name}
          </Button>
        </li>
      ))}
    </ul>
    <span className="jobs-sidebar-horizontalLine"></span>
  </aside>
);

export default JobsSidebar;
