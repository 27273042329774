import React, { useRef, useState, useEffect } from 'react';
import { InView } from 'react-intersection-observer';

/* Hooks */
import useWrapperClasses from '../../hooks/useWrapperClasses';

/* Components */
import TechnicalSkill from './TechnicalSkill';

/* Utils */
import { buildCombinedList, OBSERVER_OPTIONS } from '../../../utils';
import { skills } from '../../../utils/literals';
import { initialPosFirstItem } from '../../../utils/animations/positions';

/* Assets */
import frontendLanguagesSrc from '../../../../public/img/skills_frontend_languages.png';
import reactEcosystemSrc from '../../../../public/img/skills_react_ecosystem.png';
import appTestingSrc from '../../../../public/img/skills_app_testing.png';
import typescriptSrc from '../../../../public/img/skills_typescript.png';
import stylesToolsSrc from '../../../../public/img/skills_styles_tools.png';
import creativeLibrariesSrc from '../../../../public/img/skills_creative_libraries.png';
import devUtilitiesSrc from '../../../../public/img/skills_dev_utilities.png';
import frontendToolsSrc from '../../../../public/img/skills_frontend_tools.png';
import appServicesSrc from '../../../../public/img/skills_app_services.png';
import backendSkillsSrc from '../../../../public/img/skills_backend_skills.png';
import designSkillsSrc from '../../../../public/img/skills_design_skills.png';
import methodologiesSrc from '../../../../public/img/skills_methodologies.png';

const {
  technicalSkills: { title, skillsList },
} = skills;

const skillsWithSrc = buildCombinedList({
  initialList: skillsList,
  addedList: [
    frontendLanguagesSrc,
    reactEcosystemSrc,
    appTestingSrc,
    typescriptSrc,
    stylesToolsSrc,
    creativeLibrariesSrc,
    devUtilitiesSrc,
    frontendToolsSrc,
    appServicesSrc,
    backendSkillsSrc,
    designSkillsSrc,
    methodologiesSrc,
  ],
  addedItemName: 'src',
});

const TechnicalSkills = ({ isAnimated, isDeviceLessWiderThanLgWidth }) => {
  const initialWrapperClasses = isAnimated
    ? ['technicalSkills']
    : ['technicalSkills', 'visible'];
  const [wrapperClasses, dispatch] = useWrapperClasses(initialWrapperClasses);

  const titleRef = useRef(null);
  const animationRef = useRef(null);

  const [shouldLaunchAnimation, setShouldLaunchAnimation] = useState(false);

  useEffect(() => {
    if (!isAnimated || !shouldLaunchAnimation) return;

    dispatch({ type: 'visible' });

    animationRef.current = TweenMax.from(titleRef.current, 0.4, {
      y: initialPosFirstItem,
      autoAlpha: 0,
      ease: Power1.easeOut,
    });

    return () => {
      if (animationRef.current) {
        animationRef.current.kill();
      }
    };
  }, [isAnimated, shouldLaunchAnimation, dispatch]);

  const technicalSkillsContent = (
    <>
      <p ref={titleRef} className="section-title">
        {title}
      </p>
      <div className="technicalSkills-grid">
        {skillsWithSrc.map((skillItem, index) => (
          <TechnicalSkill
            key={skillItem.name}
            src={skillItem.src}
            altImg={skillItem.name}
            name={skillItem.name}
            description={skillItem.description}
            isAnimated={isAnimated}
            isDeviceLessWiderThanLgWidth={isDeviceLessWiderThanLgWidth}
            withDelay={index < 3}
          />
        ))}
      </div>
    </>
  );

  if (isDeviceLessWiderThanLgWidth) {
    return (
      <section className={wrapperClasses.join(' ')}>
        {technicalSkillsContent}
      </section>
    );
  }

  return (
    <InView
      {...OBSERVER_OPTIONS}
      threshold={0.35}
      as="section"
      onChange={(inView) => {
        if (inView) {
          setShouldLaunchAnimation(true);
        }
      }}
      className={wrapperClasses.join(' ')}
      skip={!isAnimated}
      triggerOnce
    >
      {technicalSkillsContent}
    </InView>
  );
};

export default TechnicalSkills;
