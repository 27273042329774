import { useState } from 'react';
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

const useLockedBody = () => {
  const [locked, setLocked] = useState(true);

  // Do the side effect before render
  useIsomorphicLayoutEffect(() => {
    window.scrollTo(0, 0);

    if (!locked) {
      return;
    }

    const originalOverflow = document.body.style.overflowY;

    // Lock body scroll
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = originalOverflow;
    };
  }, [locked]);

  const updateLocked = (currentLocked) => setLocked(currentLocked);

  return { locked, updateLocked };
};

export default useLockedBody;
