import React from 'react';

/* Utils */
import { darkWhite, darkCoral } from '../../../utils/colors';

const XIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="60px"
    height="60px"
    viewBox="0 0 60 60"
    enableBackground="new 0 0 60 60"
    xmlSpace="preserve"
  >
    <circle cx="30" cy="30" r="30" fill={darkCoral} />
    <path
      fill={darkWhite}
      d="M40.3521 14H45.8723L33.8123 27.6717L48 46.2759H36.8911L28.1902 34.9925L18.2345 46.2759H12.7109L25.6104 31.6524L12 14H23.3908L31.2557 24.3134L40.3521 14ZM38.4147 42.9986H41.4735L21.7289 17.1051H18.4464L38.4147 42.9986Z"
    />
  </svg>
);

export default XIcon;
