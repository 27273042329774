import React from 'react';

/* Utils */
import { darkWhite, darkCoral } from '../../../utils/colors';

const EmailIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="60px"
    height="60px"
    viewBox="0 0 60 60"
    enableBackground="new 0 0 60 60"
    xmlSpace="preserve"
  >
    <circle cx="30" cy="30" r="30" fill={darkCoral} />
    <path
      fill={darkWhite}
      d="M11.8,16.549c0.32-0.117,0.661-0.192,1.023-0.192h34.753c0.363,0,0.705,0.075,1.021,0.192
		L33.025,31.22c-1.584,1.495-4.069,1.495-5.653,0L11.8,16.549z M50.089,42.617c0.271-0.444,0.435-0.959,0.435-1.521V19.302
		c0-0.396-0.082-0.773-0.224-1.118l-14.573,13.73L50.089,42.617z M34.409,33.135c-1.208,1.035-2.707,1.561-4.209,1.561
		c-1.503,0-3.002-0.524-4.211-1.561l-14.32,10.672c0.355,0.152,0.744,0.235,1.154,0.235h34.756c0.407,0,0.797-0.083,1.151-0.235
		L34.409,33.135z M10.099,18.185c-0.141,0.345-0.223,0.722-0.223,1.118v21.795c0,0.561,0.165,1.076,0.434,1.521l14.361-10.706
		L10.099,18.185z"
    />
  </svg>
);

export default EmailIcon;
