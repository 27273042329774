// bubbles
export const heightIntroCanvas = 937;
export const totalBubbles = 220;
export const offsetHeightBubbles = 10;
export const heightBubblesCanvas = 420;
export const widthBubblesCanvas = 130;

// breakpoints
export const mdWidth = 768;
export const lgWidth = 992;
export const xlWidth = 1200;
export const xxlWidth = 1650;
