import React, { useState, useRef, useEffect } from 'react';
import { InView } from 'react-intersection-observer';

/* Hooks */
import useWrapperClasses from '../../../hooks/useWrapperClasses';

/* UI */
import Link from '../../../ui/Link';
import SideProjectIcon from '../../../ui/icons/SideProjectIcon';

/* Utils */
import { OBSERVER_OPTIONS } from '../../../../utils';
import {
  initialPosFirstItem,
  initialPosSecondItem,
  initialPosThirdItem,
} from '../../../../utils/animations/positions';

const SideProject = ({
  src,
  altImg,
  title,
  url,
  repoUrl,
  firstDescription,
  secondDescription,
  isAnimated,
  isDeviceLessWiderThanLgWidth,
  isEven,
}) => {
  const initialWrapperClasses = isAnimated
    ? ['sideProject']
    : ['sideProject', 'visible'];
  const [wrapperClasses, dispatch] = useWrapperClasses(initialWrapperClasses);

  const titleRef = useRef(null);
  const contentRef = useRef(null);
  const imageRef = useRef(null);
  const animationRef = useRef(null);

  const [shouldLaunchAnimation, setShouldLaunchAnimation] = useState(false);

  useEffect(() => {
    if (!isAnimated || !shouldLaunchAnimation) return;

    dispatch({ type: 'visible' });

    animationRef.current = new TimelineMax();
    animationRef.current
      .from(
        titleRef.current,
        0.4,
        {
          autoAlpha: 0,
          x: isEven ? initialPosFirstItem : -initialPosFirstItem,
          ease: Power1.easeOut,
        },
        '+=0',
      )
      .from(
        contentRef.current,
        0.4,
        {
          autoAlpha: 0,
          x: isEven ? initialPosSecondItem : -initialPosSecondItem,
          ease: Power1.easeOut,
        },
        '-=0.3',
      )
      .from(
        imageRef.current,
        0.4,
        {
          autoAlpha: 0,
          x: isEven ? -initialPosThirdItem : initialPosThirdItem,
          ease: Power1.easeOut,
        },
        '-=0.6',
      );

    return () => {
      if (animationRef.current) {
        animationRef.current.kill();
      }
    };
  }, [isAnimated, isEven, shouldLaunchAnimation, dispatch]);

  const sideProjectContent = (
    <>
      <div className="sideProject-contentWrapper">
        <p ref={titleRef} className="section-title">
          <SideProjectIcon />
          {title}
        </p>
        <div ref={contentRef} className="sideProject-content">
          <div className="sideProject-description">
            <p className="section-text">{firstDescription}</p>
            <p className="section-text">{secondDescription}</p>
          </div>
          <div className="sideProject-actions">
            <Link type="secondary" link={url} txt="See it live" />
            <Link type="secondary" link={repoUrl} txt="Go to repo" />
          </div>
        </div>
      </div>
      <div ref={imageRef} className="sideProject-image">
        <img src={src} alt={altImg} />
      </div>
    </>
  );

  if (isDeviceLessWiderThanLgWidth) {
    return (
      <section className={wrapperClasses.join(' ')}>
        {sideProjectContent}
      </section>
    );
  }

  return (
    <InView
      {...OBSERVER_OPTIONS}
      rootMargin="-7%"
      as="section"
      onChange={(inView) => {
        if (inView) {
          setShouldLaunchAnimation(true);
        }
      }}
      className={wrapperClasses.join(' ')}
      skip={!isAnimated}
      triggerOnce
    >
      {sideProjectContent}
    </InView>
  );
};

export default SideProject;
