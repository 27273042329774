import React from 'react';

/* Utils */
import { darkWhite, darkCoral } from '../../../utils/colors';

const LinkedinIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="60px"
    height="60px"
    viewBox="0 0 60 60"
    enableBackground="new 0 0 60 60"
    xmlSpace="preserve"
  >
    <circle cx="30" cy="30" r="30" fill={darkCoral} />
    <rect
      x="13.184"
      y="22.625"
      fill={darkWhite}
      width="7.828"
      height="23.506"
    />
    <path
      fill={darkWhite}
      d="M46.701,24.772c-1.645-1.795-3.819-2.693-6.523-2.693c-0.996,0-1.902,0.123-2.717,0.368
				c-0.814,0.245-1.502,0.589-2.063,1.032c-0.563,0.442-1.008,0.854-1.34,1.232c-0.315,0.36-0.625,0.78-0.926,1.255v-3.343h-7.805
				l0.024,1.139c0.016,0.759,0.024,3.099,0.024,7.021c0,3.921-0.016,9.037-0.048,15.347h7.804V33.014
				c0-0.807,0.086-1.447,0.261-1.922c0.334-0.807,0.834-1.482,1.506-2.027s1.506-0.819,2.502-0.819c1.361,0,2.361,0.471,3.002,1.412
				s0.961,2.24,0.961,3.902v12.57h7.803V32.658C49.167,29.195,48.346,26.566,46.701,24.772z"
    />
    <path
      fill={darkWhite}
      d="M17.145,11.31c-1.313,0-2.376,0.384-3.19,1.151s-1.222,1.735-1.222,2.905c0,1.154,0.396,2.12,1.186,2.894
				c0.791,0.774,1.834,1.163,3.131,1.163h0.047c1.328,0,2.4-0.387,3.215-1.163c0.814-0.774,1.213-1.739,1.197-2.894
				c-0.016-1.17-0.419-2.139-1.209-2.905C19.51,11.693,18.457,11.31,17.145,11.31z"
    />
  </svg>
);

export default LinkedinIcon;
