import React, { useRef, useState, useEffect } from 'react';

/* Hooks */
import useWrapperClasses from '../../hooks/useWrapperClasses';

/* Utils */
import {
  widthBubblesCanvas,
  heightBubblesCanvas,
} from '../../../utils/measures';
import { about } from '../../../utils/literals';
import { white } from '../../../utils/colors';
import BubbleAnim from '../../../utils/animations/bubbles/animation';

const { highlight } = about;
const [txt1, txt2, txt3, txt4, txt5] = highlight;

const Intro = ({ isAnimated }) => {
  const initialWrapperClasses = isAnimated
    ? ['highlight']
    : ['highlight', 'visible'];
  const [wrapperClasses, dispatch] = useWrapperClasses(initialWrapperClasses);

  const txtAnimation = useRef(null);
  const greetTxtRef = useRef(null);
  const creativeTxtRef = useRef(null);
  const creativeSplitTxtRef = useRef(null);
  const welcomeTxtRef = useRef(null);
  const developerTxtRef = useRef(null);
  const developerSplitTxtRef = useRef(null);
  const leftBubbleBlockRef = useRef(null);
  const leftBubblesCanvasRef = useRef(null);
  const rightBubbleBlockRef = useRef(null);
  const rightBubblesCanvasRef = useRef(null);

  useEffect(() => {
    if (!isAnimated) return;

    dispatch({ type: 'visible' });

    creativeSplitTxtRef.current = new SplitText(creativeTxtRef.current, {
      type: 'chars',
    });
    developerSplitTxtRef.current = new SplitText(developerTxtRef.current, {
      type: 'chars',
    });

    txtAnimation.current = new TimelineMax();
    txtAnimation.current
      .from(
        greetTxtRef.current,
        0.4,
        {
          autoAlpha: 0,
          x: 50,
          ease: Power2.easeOut,
        },
        '+=0',
      )
      .staggerFrom(
        creativeSplitTxtRef.current.chars,
        0.3,
        {
          autoAlpha: 0,
          color: white,
          rotationY: 90,
          x: 20,
          ease: Power2.easeOut,
        },
        0.025,
        '-=0.3',
      )
      .staggerFrom(
        developerSplitTxtRef.current.chars,
        0.3,
        {
          autoAlpha: 0,
          color: white,
          rotationY: -90,
          x: -20,
          ease: Power2.easeOut,
        },
        0.025,
        '-=0.3',
      )
      .from(
        welcomeTxtRef.current,
        0.4,
        {
          autoAlpha: 0,
          x: -50,
          ease: Power2.easeOut,
        },
        '-=0.4',
      );

    leftBubbleBlockRef.current = new BubbleAnim(
      leftBubblesCanvasRef.current,
      widthBubblesCanvas,
      heightBubblesCanvas,
      'up',
      0,
    );
    rightBubbleBlockRef.current = new BubbleAnim(
      rightBubblesCanvasRef.current,
      widthBubblesCanvas,
      heightBubblesCanvas,
      'down',
      0,
    );

    return () => {
      if (txtAnimation.current) {
        txtAnimation.current.kill();
      }

      if (leftBubbleBlockRef.current) {
        leftBubbleBlockRef.current.destroy();
      }

      if (rightBubbleBlockRef.current) {
        rightBubbleBlockRef.current.destroy();
      }
    };
  }, [isAnimated, dispatch]);

  return (
    <header className={wrapperClasses.join(' ')}>
      <div className="highlight-bubbles">
        <canvas ref={leftBubblesCanvasRef} />
      </div>
      <h2 className="highlight-content">
        <div ref={greetTxtRef} className="highlight-greetTxt">
          <span>{txt1}</span>
          <span>{txt2}</span>
        </div>
        <span ref={creativeTxtRef} className="highlight-creativeTxt">
          {txt3}
        </span>
        <span ref={developerTxtRef} className="highlight-developerTxt">
          {txt4}
        </span>
        <span ref={welcomeTxtRef} className="highlight-welcomeTxt">
          {txt5}
        </span>
      </h2>
      <div className="highlight-bubbles">
        <canvas ref={rightBubblesCanvasRef} />
      </div>
    </header>
  );
};

export default Intro;
