import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/* Hooks */
import useWrapperClasses from '../../hooks/useWrapperClasses';

/* Utils */
import {
  initialPosFirstItem,
  initialPosSecondItem,
} from '../../../utils/animations/positions';

const Highlight = ({ children, data, isAnimated }) => {
  const {
    title,
    description,
    firstExtendedDescription,
    secondExtendedDescription,
  } = data;

  const initialWrapperClasses = isAnimated
    ? ['highlight']
    : ['highlight', 'visible'];
  const [wrapperClasses, dispatch] = useWrapperClasses(initialWrapperClasses);

  const animation = useRef(null);
  const headerRef = useRef(null);
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (!isAnimated) return;

    dispatch({ type: 'visible' });

    animation.current = new TimelineMax();
    animation.current
      .from(
        headerRef.current,
        0.4,
        {
          autoAlpha: 0,
          x: initialPosFirstItem,
          ease: Power1.easeOut,
        },
        '+=0',
      )
      .from(
        descriptionRef.current,
        0.4,
        {
          autoAlpha: 0,
          x: initialPosSecondItem,
          ease: Power1.easeOut,
        },
        '-=0.3',
      );

    return () => {
      if (animation.current) {
        animation.current.kill();
      }
    };
  }, [isAnimated]);

  return (
    <div className={wrapperClasses.join(' ')}>
      {children}
      <div className="highlight-content">
        <header ref={headerRef} className="highlight-header">
          <h2 className="title">{title}</h2>
          <h3 className="subtitle">{description}</h3>
        </header>
        <div ref={descriptionRef} className="highlight-description">
          <p className="section-text">{firstExtendedDescription}</p>
          <p className="section-text">{secondExtendedDescription}</p>
        </div>
      </div>
    </div>
  );
};

Highlight.propTypes = {
  children: PropTypes.element,
  data: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    txt: PropTypes.string,
  }),
};

export default Highlight;
