import React, { createElement } from 'react';
import PropTypes from 'prop-types';

const Link = ({ type, link, iconComponent, txt }) => (
  <a className={`link ${type}`} href={link} rel="noopener noreferrer">
    {iconComponent && createElement(iconComponent)}
    {txt}
  </a>
);

Link.propTypes = {
  type: PropTypes.string,
  link: PropTypes.string,
  iconComponent: PropTypes.any,
  txt: PropTypes.string,
};

Link.defaultProps = {
  type: 'primary',
};

export default Link;
