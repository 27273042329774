export const menuItems = [
  {
    txt: 'About',
    url: '/',
  },
  {
    txt: 'Skills',
    url: '/skills',
  },
  {
    txt: 'Projects',
    url: '/projects',
  },
];

export const about = {
  highlight: [
    'HI THERE, ',
    'I´M JDMIGUEL',
    'CREATIVE',
    'DEVELOPER',
    'WELCOME TO MY WEBSITE',
  ],
  introduction: {
    title: 'Who am I?',
    locationTxt: "My name is Jaime De Miguel, I'm a senior frontend engineer",
    professionalTxt:
      'Specialized in building great user interfaces and meaningful interactions, I am passionate about web development with a special interest in creating stunning user experiences',
    personalTxt:
      "When I'm not coding, I love to indulge in some of my other passions such as listening to music, reading books and manga, and immersing myself in any mistery movie",
    altImg: 'jdmiguel cartoon',
  },
  jobs: {
    title: 'Work experience',
    sidebarList: [
      { name: 'Oomnitza', id: 'oomnitza' },
      { name: 'Kitman Labs', id: 'kitman' },
      { name: 'Daft', id: 'daft' },
      { name: 'Atresmedia', id: 'atresmedia' },
      { name: 'Yondbee', id: 'yondbee' },
      { name: 'Comunica + a', id: 'comunica' },
    ],
    jobsList: [
      {
        id: 'oomnitza',
        title: 'Senior software frontend engineer',
        startDate: '2023-01',
        endDate: '2023-08',
        descriptionList: [
          {
            id: 'first description',
            txt: 'At Oomnitza, I am responsible for developing and maintaining the frontend of our saas platform. Using React, Typescript, Material UI, and SCSS, I collaborate with the product and design teams to ensure a user-friendly and visually appealing Enterprise Technology Management platform',
          },
          {
            id: 'second description',
            txt: 'My work helps Oomnitza deliver a high-quality product that meets the needs of our international customers such as Intercom, Thumbtack, Legalzoom and Quizlet',
          },
        ],
      },
      {
        id: 'kitman',
        title: 'Software frontend engineer',
        startDate: '2020-09',
        endDate: '2022-12',
        descriptionList: [
          {
            id: 'first description',
            txt: 'As frontend engineer, I was responsible for developing and maintaining the frontend of their sports analytics platform using React, Redux, React Testing Library, Jest and Emotion. By leveraging my frontend expertise, I helped to build a robust and scalable codebase easy to maintain',
          },
          {
            id: 'second description',
            txt: 'In Kitman Labs, I gained more experience as senior developer by mentoring new workmates and introducing new approaches across its application',
          },
        ],
      },
      {
        id: 'daft',
        title: 'Senior frontend developer',
        startDate: '2020-02',
        endDate: '2020-07',
        descriptionList: [
          {
            id: 'first description',
            txt: 'As a Senior Frontend Developer at Daft, I played a key role in developing meaningful new features and maintaining the existing frontend code of their property web application by leveraging my expertise in React, Typescript, Next.js and Styled Components',
          },
          {
            id: 'second description',
            txt: "In addition, I implemented integration and unit testing using Cypress, React Testing Library and Jest, to ensure the platform's reliability and performance",
          },
        ],
      },
      {
        id: 'atresmedia',
        title: 'Senior frontend developer',
        startDate: '2018-03',
        endDate: '2020-01',
        descriptionList: [
          {
            id: 'first description',
            txt: 'I was in charge of developing web applications related to the intranet of the company whose purpose were showing employees data, managing parcel system, setting audiences graphs, introducing news, etc. React, Redux and Styled components were the main tehcnologies I worked with',
          },
          {
            id: 'second description',
            txt: 'Nevertheless, I worked closely with web designers to implement a customized design system by using different tools like Zeplin, Sketch and Storybook',
          },
        ],
      },
      {
        id: 'yondbee',
        title: 'Frontend developer',
        startDate: '2017-05',
        endDate: '2018-02',
        descriptionList: [
          {
            id: 'first description',
            txt: 'Development of webgl microsite for Samsung with Three.js, React, Mobx, GSAP and Create.js. Destinated to support the release of a new studio record, it included an interactive 3D musical studio with several challenging minigames, video galleries and audio integration',
          },
          {
            id: 'second description',
            txt: 'Moreover, I developed the frontend code of the corporate website belonging to the international Seagrams gyn by using Vue.js, Vuetify, Vuex, Axios, GSAP and Scss',
          },
        ],
      },
      {
        id: 'comunica',
        title: 'Frontend developer',
        startDate: '2015-10',
        endDate: '2017-04',
        descriptionList: [
          {
            id: 'first description',
            txt: 'Development of web application for Michelin intended to create customized posters by company commercials, including several profiles different permissions to validate promotions, etc. It was mainly developed with React, Redux and React router, Bootstrap and Scss',
          },
          {
            id: 'second description',
            txt: 'Also, I developed websites, microsites, landings-page, richmedias, banners and emailings with technologies such as Jquery, GSAP, CreateJs, Bootstrap and Scss',
          },
        ],
      },
    ],
  },
  connect: {
    title: 'Let´s connect',
    email: {
      firstEmailTxt: 'You can',
      secondEmailTxt: 'copy my email',
      thirdEmailTxt: 'or use the following links to reach out',
      emailTxtToCopy: 'jaimedmiguel@gmail.com',
    },
    linkList: [
      {
        txt: 'Connect on Github',
        link: 'https://github.com/jdmiguel',
      },
      {
        txt: 'Connect on Linkedin',
        link: 'https://es.linkedin.com/in/jdmiguel',
      },
      {
        txt: 'Like me on Facebook',
        link: 'https://www.facebook.com/jdmiguelfrontend/',
      },
      {
        txt: 'Follow me on X',
        link: 'https://x.com/jaimedemiguel',
      },
      {
        txt: 'Call me on Skype',
        link: 'skype:jaimedemiguel',
      },
      {
        txt: 'Send me an email',
        link: 'mailto:jaimedmiguel@gmail.com',
      },
    ],
    altImg: 'Connected world',
  },
};

export const skills = {
  highlight: {
    type: 'skills',
    title: 'My abilities',
    description: 'Tech knowledge, creativity and continuous learning',
    firstExtendedDescription:
      'The main area of my expertise is the frontend development but I also have knowledge about backend development, devops, and web design',
    secondExtendedDescription:
      'I am especially into everything related to creation of web applications as well as the languages, frameworks, libraries and tools used for this purpose',
  },
  talentsPercent: {
    titleDeveloper: 'Technical skills',
    firstTxtDeveloper: 'Frameworks & Libraries',
    secondTxtDeveloper: 'Functional programming',
    thirdTxtDeveloper: 'Clean Code',
    fourthTxtDeveloper: 'Testing',
    titleDesigner: 'Creative talents',
    firstTxtDesigner: 'User Experience',
    secondTxtDesigner: 'User Interface',
    thirdTxtsigner: 'Responsive design',
    fourthTxtsigner: 'Design systems',
  },
  softSkills: {
    title: 'Soft skills',
    skillsList: [
      { name: 'Detail attentive', percent: 86 },
      { name: 'Self learning', percent: 88 },
      { name: 'Communication', percent: 90 },
      { name: 'Problem solving', percent: 92 },
      { name: 'Team player', percent: 94 },
    ],
  },
  technicalSkills: {
    title: 'Specific knowledge',
    skillsList: [
      {
        name: 'frontend languages',
        description:
          'After a number of years of experience, I have a deep knowledge in the main frontend languages (HTML, CSS and JS)',
      },
      {
        name: 'react ecosystem',
        description:
          'React and its ecosystem, including libraries such as Redux, React Query, MUI and Storybook, have became in my main work tools',
      },
      {
        name: 'app testing',
        description:
          'For me, implementing tests is a must. I use Cypress for E2E tests and Jest plus Testing Library for integration and unit tests',
      },
      {
        name: 'typescript',
        description:
          'Catching errors on build time makes me really love Typescript. I am also fond of related libraries such as TRCP and Zod',
      },
      {
        name: 'styles tools',
        description:
          'The last few years, I have been working with different styling approaches such as Saas, Tailwind, Emotion and Styled components',
      },
      {
        name: 'creative libraries',
        description:
          'I love working with both, svg and canvas to create amazing effects with libraries such as Framer Motion, Threejs, and GSAP',
      },
      {
        name: 'dev utilities',
        description:
          'As web developer, I am used to working with several development utilities such as Nodejs, NPM, Yarn, Docker, Github, and Gitlab',
      },
      {
        name: 'frontend tools',
        description:
          'Since Frontend development has become more and more complex over time, I work with tools such as VSCode, Vite, Webpack, and Eslint',
      },
      {
        name: 'app services',
        description:
          'Working on big developments has led me to learn how to use app services such as Sentry, CircleCi, Vercel, Netlify, and LaunchDarkly',
      },
      {
        name: 'backend skills',
        description:
          'Nextjs as fullstack framework, Prisma as object relation mapping and PlanetScale as database are my favourite backend tools',
      },
      {
        name: 'design skills',
        description:
          'Even though I am mainly focused in development, I handle several design programs such as Figma, Miro, Photoshop, and Illustrator',
      },
      {
        name: 'methodologies',
        description:
          'I am used to get involved in projects by following scrum methodologies supported by tools such as Jira, Confluence and Trello',
      },
    ],
  },
};

export const projects = {
  highlight: {
    type: 'projects',
    title: 'web projects',
    description: 'Design and development of multiple digital products',
    firstExtendedDescription:
      'Over the course of my career, I have gained extensive experience in developing a wide range of projects such as web applications, websites, richmedia and games',
    secondExtendedDescription:
      'Below are some examples of side projects that helped me sharpen my design and development skills, offering me opportunities to try new technologies',
  },
  projectsList: [
    {
      name: 'Justice League',
      id: 'justice',
      url: 'https://justice-league.netlify.app',
      repoUrl: 'https://github.com/jdmiguel/justice-league',
      firstDescription:
        'Animated website based on Justice League from DC to provide a stunning visual user experience focused on cool animations',
      secondDescription:
        'Developed with React, Typescript, GSAP and Styled Components. Also, it uses Supabase as db with Netlify Functions and GraphQL',
    },
    {
      name: 'Louper',
      id: 'louper',
      url: 'https://louper.netlify.app/',
      repoUrl: 'https://github.com/jdmiguel/louper',
      firstDescription:
        'Web Application to search github users and display related data such as profile, repositories, followers, and following users',
      secondDescription:
        'Built with React, Typescript and MUI, it includes a 3D interactive globe which displays the number of github users by country',
    },
    {
      name: 'Foodublin',
      id: 'foodublin',
      url: 'https://foodublin.vercel.app',
      repoUrl: 'https://github.com/jdmiguel/foodublin',
      firstDescription:
        'Server Side Rendering Application whose purpose is searching restaurants in Dublin and filtering by cuisine, location, and price',
      secondDescription:
        'Developed with React, Next.js, Typescript, and Styled components. In addition, this project includes a UI library built with Storybook',
    },
  ],
  portfolio: {
    title: 'My portfolio',
    firstDescription:
      'This modern website provides an appealing visual experience to showcase my work experience, skills, and projects. It is built with React, GSAP and Scss',
    secondDescription:
      'Both, design and development, were lovingly created by me so I actually hope the result reflects my passion for web development and design',
    altImg: 'laptop displaying my portfolio',
  },
};

export const pageError = {
  title: 'Bad luck!',
  description: '404 Error: Page not found',
};

export const footer = {
  txt1: 'Copyright ©2024',
  txt2: 'jaimedmiguel@gmail.com',
};
