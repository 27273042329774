import React, { useRef, useState, useEffect } from 'react';
import { InView } from 'react-intersection-observer';

/* Recharts */
import { BarChart, Bar, Cell, CartesianGrid, XAxis, YAxis } from 'recharts';

/* Hooks */
import useWrapperClasses from '../../hooks/useWrapperClasses';

/* Utils */
import { getPalindrome, OBSERVER_OPTIONS } from '../../../utils';
import { skills } from '../../../utils/literals';
import {
  smoothBlack,
  mediumGrey,
  lightGrey,
  black,
} from '../../../utils/colors';
import {
  initialPosFirstItem,
  initialPosSecondItem,
} from '../../../utils/animations/positions';

const {
  softSkills: { title, skillsList },
} = skills;

const barColors = [mediumGrey, smoothBlack];
const barPalindrome = getPalindrome(barColors);
const percentTxtColors = [black, mediumGrey];
const percentTxtPalindrome = getPalindrome(percentTxtColors);
const xAxisFontSize = '1.15em';

const BarLabel = ({ x, y, value, index }) => (
  <>
    <text
      x={x}
      y={y}
      textAnchor="middle"
      fontSize="2.6em"
      fill={percentTxtPalindrome[index % percentTxtPalindrome.length]}
      dx={76}
      dy={165}
    >
      {value}
    </text>
    <text
      x={x}
      y={y}
      textAnchor="middle"
      fontSize="1.4em"
      fill={percentTxtPalindrome[index % percentTxtPalindrome.length]}
      dx={113}
      dy={159}
    >
      %
    </text>
  </>
);

const SoftSkills = ({ isAnimated, isDeviceLessWiderThanLgWidth }) => {
  const initialWrapperClasses = isAnimated
    ? ['softSkills']
    : ['softSkills', 'visible'];
  const [wrapperClasses, dispatch] = useWrapperClasses(initialWrapperClasses);

  const titleRef = useRef(null);
  const chartRef = useRef(null);
  const animationRef = useRef(null);

  const [shouldLaunchAnimation, setShouldLaunchAnimation] = useState(false);
  const [shouldDisplayGraphic, setShouldDisplayGraphic] = useState(!isAnimated);

  useEffect(() => {
    if (!isAnimated || isDeviceLessWiderThanLgWidth || !shouldLaunchAnimation)
      return;

    dispatch({ type: 'visible' });

    animationRef.current = new TimelineMax();
    animationRef.current
      .from(
        titleRef.current,
        0.4,
        {
          autoAlpha: 0,
          y: initialPosFirstItem,
          ease: Power1.easeOut,
        },
        '+=0',
      )
      .call(() => setShouldDisplayGraphic(true))
      .from(
        chartRef.current,
        0.4,
        {
          autoAlpha: 0,
          y: initialPosSecondItem,
          ease: Power1.easeOut,
        },
        '-=0.1',
      );

    return () => {
      if (animationRef.current) {
        animationRef.current.kill();
      }
    };
  }, [
    isAnimated,
    isDeviceLessWiderThanLgWidth,
    shouldLaunchAnimation,
    dispatch,
  ]);

  if (isDeviceLessWiderThanLgWidth) {
    return (
      <section className={wrapperClasses.join(' ')}>
        <ul className="softSkills-bubbles">
          {skillsList.map((item, index) => (
            <li key={item.name}>
              <div>
                <span>{item.percent}</span>
                <span>%</span>
              </div>
              <span>{item.name}</span>
            </li>
          ))}
        </ul>
      </section>
    );
  }

  return (
    <InView
      {...OBSERVER_OPTIONS}
      as="section"
      onChange={(inView) => {
        if (inView) {
          setShouldLaunchAnimation(true);
        }
      }}
      className={wrapperClasses.join(' ')}
      skip={!isAnimated}
      triggerOnce
    >
      <p ref={titleRef} className="section-title">
        {title}
      </p>
      <div ref={chartRef} className="softSkills-chart">
        {shouldDisplayGraphic && (
          <BarChart
            width={1200}
            height={400}
            data={skillsList}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis
              dataKey="name"
              tick={{
                fontSize: xAxisFontSize,
                fill: lightGrey,
              }}
              tickLine={false}
              tickMargin={10}
              axisLine={false}
            />
            <YAxis
              tickLine={false}
              tickMargin={10}
              tick={{ fontSize: '1.1em', fill: lightGrey }}
              ticks={[0, 25, 50, 75, 100]}
              axisLine={false}
            />
            <Bar
              label={BarLabel}
              dataKey="percent"
              animationEasing="ease-out"
              animationDuration={400}
              animationBegin={100}
            >
              {skillsList.map((item, index) => (
                <Cell
                  key={item.name}
                  fill={barPalindrome[index % barPalindrome.length]}
                />
              ))}
            </Bar>
          </BarChart>
        )}
      </div>
    </InView>
  );
};

export default SoftSkills;
