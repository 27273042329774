import React, { useEffect } from 'react';

/* Hooks */
import useWrapperClasses from '../hooks/useWrapperClasses';

/* Utils */
import { setZeroScrollTop } from '../../utils';
import {
  white,
  smoothGrey,
  grey,
  orange,
  smoothBlack,
} from '../../utils/colors';
import { pageError, menuItems } from '../../utils/literals';

const { title, description } = pageError;

const PageError = () => {
  const [wrapperClasses, dispatch] = useWrapperClasses(['pageError']);

  useEffect(() => {
    setZeroScrollTop();
    dispatch({ type: 'visible' });
  }, []);

  return (
    <main className={wrapperClasses.join(' ')}>
      <div className="pageError-image">
        <svg
          className="img"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="420px"
          height="277.286px"
          viewBox="0 0 420 277.286"
          enableBackground="new 0 0 420 277.286"
          xmlSpace="preserve"
        >
          <path
            fill={orange}
            d="M229.695,8.971c0,4.958-4.014,8.971-8.972,8.971H113.066c-4.955,0-8.972-4.013-8.972-8.971l0,0
              c0-4.955,4.018-8.971,8.972-8.971h107.657C225.682,0,229.695,4.017,229.695,8.971L229.695,8.971z"
          />
          <path
            fill={orange}
            d="M184.838,25.917c0,4.955-4.013,8.972-8.971,8.972H68.21c-4.958,0-8.971-4.018-8.971-8.972l0,0
              c0-4.958,4.014-8.975,8.971-8.975h107.655C180.823,16.942,184.838,20.959,184.838,25.917L184.838,25.917z"
          />
          <path
            fill={orange}
            d="M420,208.622c0,4.956-4.018,8.975-8.971,8.975H303.37c-4.956,0-8.97-4.019-8.97-8.975l0,0
              c0-4.955,4.014-8.972,8.97-8.972h107.659C415.982,199.651,420,203.667,420,208.622L420,208.622z"
          />
          <path
            fill={orange}
            d="M378.458,196.926c0,4.957-4.017,8.974-8.972,8.974H261.829c-4.955,0-8.976-4.017-8.976-8.974l0,0
              c0-4.957,4.021-8.971,8.976-8.971h107.657C374.441,187.955,378.458,191.969,378.458,196.926L378.458,196.926z"
          />
          <path
            fill={orange}
            d="M373.239,225.568c0,4.956-4.015,8.97-8.971,8.97H256.609c-4.952,0-8.971-4.014-8.971-8.97l0,0
              c0-4.957,4.019-8.971,8.971-8.971h107.659C369.225,216.597,373.239,220.611,373.239,225.568L373.239,225.568z"
          />
          <path
            fill={orange}
            d="M163.521,133.32c0,4.957-4.017,8.972-8.975,8.972H46.892c-4.954,0-8.971-4.015-8.971-8.972l0,0
              c0-4.954,4.017-8.971,8.971-8.971h107.655C159.504,124.349,163.521,128.366,163.521,133.32L163.521,133.32z"
          />
          <path
            fill={orange}
            d="M145.684,118.501c0,4.958-4.018,8.974-8.972,8.974H29.054c-4.954,0-8.971-4.016-8.971-8.974l0,0
              c0-4.955,4.017-8.972,8.971-8.972h107.658C141.664,109.529,145.684,113.546,145.684,118.501L145.684,118.501z"
          />
          <path
            fill={orange}
            d="M125.6,149.572c0,4.953-4.016,8.97-8.971,8.97H8.971c-4.955,0-8.971-4.017-8.971-8.97l0,0
              c0-4.956,4.017-8.971,8.971-8.971h107.658C121.584,140.601,125.6,144.616,125.6,149.572L125.6,149.572z"
          />
          <path
            fill={smoothBlack}
            d="M350.43,264.808H58.58c-5.1,0-9.253-4.664-9.253-10.411V64.865c0-5.745,4.151-10.41,9.253-10.41h291.85
              c5.103,0,9.253,4.666,9.253,10.41v189.532C359.683,260.144,355.537,264.808,350.43,264.808L350.43,264.808z"
          />
          <path
            fill={white}
            d="M335.836,67.038H73.173c-3.584,0-6.494,3.268-6.494,7.311v170.568c0,4,2.855,7.24,6.392,7.303
              c-0.168,0.014-0.131,0.009,0.103,0.009h262.662c3.59,0,6.5-3.269,6.5-7.312V74.349C342.336,70.306,339.426,67.038,335.836,67.038
              z"
          />
          <path
            fill={smoothGrey}
            d="M68.662,246.902V76.33c0-4.042,2.91-7.311,6.495-7.311h262.663c1.186,0,2.281,0.381,3.236,1.005
              c-1.185-1.806-3.069-2.986-5.216-2.986H73.173c-3.584,0-6.494,3.268-6.494,7.311v170.568c0,2.706,1.322,5.035,3.262,6.301
              C69.146,250.007,68.662,248.522,68.662,246.902z"
          />
          <path
            fill={grey}
            d="M204.881,260.909H14.316v3.077v5.979c0,4.041,4.46,7.321,9.965,7.321h180.6h180.601
              c5.505,0,9.965-3.28,9.965-7.321v-5.979v-3.077H204.881z"
          />
          <path
            fill={smoothBlack}
            d="M391.15,260.909v4.769c0,4.038-4.461,7.318-9.965,7.318H200.584H19.985c-1.948,0-3.752-0.427-5.291-1.135
              c1.141,3.114,4.979,5.425,9.587,5.425h180.6h180.601c5.505,0,9.965-3.28,9.965-7.321v-5.979v-3.077H391.15L391.15,260.909z"
          />
          <rect
            x="185.518"
            y="267.354"
            fill={smoothGrey}
            width="38.56"
            height="3.994"
          />
          <path
            fill={smoothGrey}
            d="M335.836,67.038h-32.849c0.015,10.729,9.152,70.068-60.448,133.431
            c-35.558,32.373-75.948,45.757-92.533,51.762h185.83c3.59,0,6.5-3.27,6.5-7.313V74.349
            C342.336,70.306,339.426,67.038,335.836,67.038z"
          />
          <polygon
            fill={smoothBlack}
            points="300.685,127.282 288.748,125.837 278.551,110.245 266.196,87.518 270.144,111.323 270.58,126.057 
            278.912,135.968 267.432,137.53 215.83,103.703 240.102,124.02 231.639,130.184 245.848,128.828 257.799,138.834 266.96,146.908 
            261.28,148.763 264.852,165.35 273.556,187.76 252.019,193.964 267.094,207.336 261.12,197.166 279.849,189.703 271.117,161.804 
            279.618,156.989 288.561,168.492 293.758,160.044 311.266,162.816 320.953,175.531 338.021,177.901 322.516,172.044 
            315.329,157.762 298.087,152.998 295.016,157.992 312.981,128.776 328.268,100.415 		"
          />
          <polygon
            fill={smoothBlack}
            points="307.078,138.379 314.621,148.018 327.451,142.312 316.092,144.271 312.981,128.776 		"
          />
          <polygon
            fill={smoothBlack}
            points="298.037,112.817 300.448,100.24 309.208,95.355 295.295,97.487 292.165,110.185 278.551,110.245 
            283.042,117.108 		"
          />
          <polygon
            fill={smoothBlack}
            points="263.336,158.313 261.635,150.442 232.834,157.841 207.729,183.494 232.528,162.578 		"
          />
          <polygon
            fill={orange}
            points="403.048,116.045 387.648,116.025 379.453,6.821 411.439,6.852 		"
          />
          <path
            fill={orange}
            d="M406.584,136.181c-0.006,6.216-5.045,11.253-11.259,11.246c-6.22-0.005-11.253-5.052-11.247-11.263
            c0.007-6.218,5.049-11.25,11.264-11.251C401.557,124.923,406.596,129.962,406.584,136.181z"
          />
        </svg>
      </div>
      <div className="pageError-content">
        <p className="title">{title}</p>
        <p className="subtitle">{description}</p>
      </div>
    </main>
  );
};

export default PageError;
