import React from 'react';

/* Utils */
import { darkCoral } from '../../../utils/colors';

const CopyIcon = ({ isCheckShown }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    enableBackground="new 0 0 26 26"
    xmlSpace="preserve"
  >
    <path
      fill={darkCoral}
      d="M19.6711 0H14.2464C10.2642 0 8.39658 1.41314 8.00751 4.82934C7.92611 5.54404 8.51801 6.13514 9.23732 6.13514H11.9215C17.3462 6.13514 19.8649 8.65378 19.8649 14.0785V16.7626C19.8649 17.4819 20.4559 18.0739 21.1707 17.9925C24.5868 17.6033 26 15.7358 26 11.7536V6.32888C26 1.80825 24.1917 0 19.6711 0Z"
    />
    {isCheckShown ? (
      <path
        fill={darkCoral}
        d="M11.7 8H6.3C1.8 8 0 9.8 0 14.3V19.7C0 24.2 1.8 26 6.3 26H11.7C16.2 26 18 24.2 18 19.7V14.3C18 9.8 16.2 8 11.7 8ZM13.23 15.2643L8.46 20.0343C8.28 20.2143 8.04857 20.3043 7.80429 20.3043C7.56 20.3043 7.32857 20.2143 7.14857 20.0343L4.75714 17.6429C4.39714 17.2829 4.39714 16.7043 4.75714 16.3443C5.11714 15.9843 5.69571 15.9843 6.05571 16.3443L7.79143 18.08L11.9186 13.9529C12.2786 13.5929 12.8571 13.5929 13.2171 13.9529C13.5771 14.3129 13.59 14.9043 13.23 15.2643Z"
      />
    ) : (
      <path
        fill={darkCoral}
        d="M18 14.3V19.7C18 24.2 16.2 26 11.7 26H6.3C1.8 26 0 24.2 0 19.7V14.3C0 9.8 1.8 8 6.3 8H11.7C16.2 8 18 9.8 18 14.3Z"
      />
    )}
  </svg>
);

export default CopyIcon;
