import React from 'react';

/* Utils */
import { darkWhite, darkCoral } from '../../../utils/colors';

const SkypeIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="60px"
    height="60px"
    viewBox="0 0 60 60"
    enableBackground="new 0 0 60 60"
    xmlSpace="preserve"
  >
    <circle cx="30" cy="30" r="30" fill={darkCoral} />
    <path
      fill={darkWhite}
      d="M47.184,32.873c0.541,0.766,0.953,1.598,1.242,2.509c0.287,0.907,0.432,1.849,0.432,2.821
			c0,1.395-0.281,2.704-0.838,3.93c-0.561,1.223-1.328,2.303-2.311,3.238c-0.98,0.927-2.124,1.654-3.436,2.184
			c-1.313,0.528-2.694,0.789-4.162,0.789c-2.092,0-3.969-0.492-5.633-1.479c-0.463,0.063-0.928,0.11-1.397,0.146
			c-0.472,0.031-0.938,0.045-1.405,0.045c-2.443,0-4.737-0.438-6.883-1.312c-2.14-0.878-4.015-2.069-5.63-3.586
			c-1.611-1.513-2.88-3.294-3.794-5.335c-0.922-2.041-1.383-4.221-1.383-6.54c0-0.448,0.022-0.875,0.07-1.27
			c0.043-0.396,0.102-0.798,0.163-1.202c-1.147-1.591-1.717-3.299-1.717-5.134c0-1.363,0.282-2.652,0.841-3.863
			c0.558-1.211,1.322-2.264,2.303-3.157c0.981-0.896,2.129-1.6,3.434-2.116c1.308-0.516,2.695-0.768,4.164-0.768
			c1.058,0,2.05,0.124,2.993,0.377c0.943,0.257,1.826,0.613,2.64,1.081c0.46-0.066,0.932-0.112,1.397-0.148
			c0.471-0.029,0.943-0.046,1.403-0.046c2.451,0,4.753,0.427,6.905,1.276c2.146,0.842,4.025,2.01,5.643,3.492
			c1.614,1.488,2.887,3.209,3.812,5.168c0.921,1.963,1.381,4.069,1.381,6.311C47.417,31.107,47.341,31.973,47.184,32.873z
			 M38.109,37.973c0.756-0.994,1.127-2.162,1.127-3.502c0-1.057-0.209-1.943-0.625-2.664c-0.43-0.717-1.039-1.316-1.824-1.797
			c-0.721-0.464-1.66-0.877-2.809-1.247c-1.156-0.29-2.379-0.573-3.674-0.863l-1.419-0.323c-0.381-0.082-0.683-0.15-0.911-0.212
			c-0.253-0.063-0.487-0.131-0.71-0.212c-0.21-0.075-0.434-0.163-0.656-0.258c-0.434-0.182-0.797-0.428-1.084-0.718
			c-0.223-0.25-0.335-0.586-0.335-1.004c0-0.61,0.363-1.155,1.085-1.638c0.817-0.45,1.816-0.667,3.002-0.653
			c1.373,0,2.335,0.203,2.878,0.608c0.656,0.482,1.171,1.075,1.539,1.773c0.32,0.513,0.646,0.895,0.98,1.147
			c0.32,0.245,0.766,0.376,1.324,0.395c0.629,0,1.15-0.209,1.588-0.629c0.428-0.392,0.633-0.834,0.622-1.331
			c0-0.575-0.162-1.141-0.481-1.686c-0.314-0.51-0.845-1.055-1.576-1.634c-0.371-0.248-0.775-0.476-1.215-0.685
			c-0.438-0.21-0.933-0.395-1.47-0.551c-0.563-0.153-1.153-0.271-1.776-0.35c-0.625-0.078-1.307-0.114-2.062-0.114
			c-1.902,0-3.506,0.232-4.813,0.698c-1.403,0.483-2.466,1.149-3.186,2.013c-0.72,0.822-1.08,1.81-1.08,2.962
			c0,1.214,0.349,2.202,1.037,2.964c0.693,0.823,1.631,1.446,2.818,1.867c1.127,0.452,2.569,0.847,4.337,1.187
			c0.661,0.156,1.239,0.289,1.75,0.391c0.503,0.099,0.96,0.211,1.36,0.334c0.752,0.205,1.364,0.55,1.832,1.037
			c0.498,0.425,0.748,0.969,0.748,1.643c0,0.928-0.455,1.68-1.355,2.253c-0.85,0.588-2,0.878-3.448,0.856
			c-1.16,0-1.999-0.133-2.532-0.404c-0.661-0.301-1.148-0.684-1.468-1.139c-0.406-0.52-0.731-1.096-0.992-1.719
			c-0.228-0.592-0.536-1.037-0.937-1.338c-0.374-0.305-0.812-0.457-1.326-0.457c-0.657,0-1.198,0.184-1.627,0.545
			c-0.428,0.408-0.634,0.887-0.625,1.426c0,0.938,0.347,1.877,1.036,2.816c0.683,0.967,1.562,1.723,2.63,2.266
      c1.566,0.809,3.533,1.219,5.897,1.234c1.929,0,3.648-0.287,5.144-0.859C36.299,39.751,37.395,38.943,38.109,37.973z"
    />
  </svg>
);

export default SkypeIcon;
